var utfstring = require('utfstring');

if (utfstring == null && typeof window !== 'undefined' && window !== null) {
  utfstring = window.UtfString;
}

export class BreakIterator {
  constructor(suppressions) {
    this.suppressions = suppressions;
  }

  eachSentence(str, callback) {
    let ruleSet = this.ruleSetFor('sentence');
    this.eachBoundary(ruleSet, str, callback);
  }

  eachWord(str, callback) {
    let ruleSet = this.ruleSetFor('word');
    this.eachBoundary(ruleSet, str, callback);
  }

  eachGraphemeCluster(str, callback) {
    let ruleSet = this.ruleSetFor('grapheme');
    this.eachBoundary(ruleSet, str, callback);
  }

  eachLine(str, callback) {
    let ruleSet = this.ruleSetFor('line');
    this.eachBoundary(ruleSet, str, callback);
  }

  // private

  eachBoundary(ruleSet, str, callback) {
    let start = 0;
    let current, next;

    ruleSet.eachBoundary(str, (boundary) => {
      // this replicates the same behavior as an each_cons(2) enumerator in Ruby
      if (current == undefined) {
        current = boundary;
        return;
      } else if (next == undefined) {
        next = boundary;
      } else {
        current = next;
        next = boundary;
      }

      callback(str.substring(current, next), current, next);
    });
  }

  ruleSetFor(boundaryType) {
    let ruleSetCache = this.getRuleSetCache();

    if (ruleSetCache[boundaryType] === undefined) {
      ruleSetCache[boundaryType] = RuleSet.create(
        boundaryType, this.suppressions
      );
    }

    return ruleSetCache[boundaryType];
  }

  getRuleSetCache() {
    if (this.ruleSetCache === undefined) {
      this.ruleSetCache = {};
    }

    return this.ruleSetCache;
  }
}
;class CategoryTable {
  constructor(values) {
    this.values = values;
  }

  get(codepoint) {
    return this.find(codepoint)[2];
  }

  // private

  find(codepoint) {
    var lo = 0, hi = this.values.length;

    while (lo <= hi) {
      let mid = Math.floor((hi + lo) / 2);
      let current = this.values[mid];

      if (codepoint < current[0]) {
        hi = mid - 1;
      } else if (codepoint > current[1]) {
        lo = mid + 1;
      } else {
        return current;
      }
    }

    // we should realistically never get here
    return null;
  }
}
;class Cursor {
  constructor(text) {
    this.text = text;
    this.length = text.length;
    this.codepoints = utfstring.stringToCodePoints(text);
    this.reset();
  }

  advance(amount = 1) {
    for (var i = 0; i < amount; i ++) {
      let cp = this.getCodePoint();

      if (cp > 0xFFFF) {
        this.actualPosition += 2;
      } else {
        this.actualPosition ++;
      }

      this.logicalPosition ++;
    }
  }

  retreat(amount = 1) {
    for (var i = 0; i < amount; i ++) {
      this.logicalPosition --;

      let cp = this.getCodePoint();

      if (cp > 0xFFFF) {
        this.actualPosition -= 2;
      } else {
        this.actualPosition --;
      }
    }
  }

  reset() {
    this.logicalPosition = 0;
    this.actualPosition = 0;
  }

  isEos() {
    return this.logicalPosition >= this.codepoints.length;
  }

  getCodePoint(pos = this.logicalPosition) {
    return this.codepoints[pos];
  }

  slice(start, finish) {
    return utfstring.codePointsToString(
      this.codepoints.slice(start, finish)
    );
  }
}
;class Metadata {
  constructor(values) {
    this.values = values;
  }

  getCategoryCount() {
    return this.values.categoryCount;
  }
}
;export class NullSuppressions {
  static instance;

  constructor() {
    if (this.instance) {
      return this.instance;
    }

    this.instance = this;
  }

  shouldBreak(_cursor) {
    return true;
  }
}
;export class RuleSet {
  static create(boundaryType, suppressions) {
    return new RuleSet(
      StateMachine.getInstance(boundaryType), suppressions
    );
  }

  constructor(stateMachine, suppressions) {
    this.stateMachine = stateMachine;
    this.suppressions = suppressions || new NullSuppressions();
  }

  eachBoundary(str, callback) {
    let cursor = new Cursor(str);

    // Let the state machine find the first boundary for the line
    // boundary type. This helps pass nearly all the Unicode
    // segmentation tests, so it must be the right thing to do.
    // Normally the first boundary is the implicit start of text
    // boundary, but potentially not for the line rules?
    if (this.stateMachine.boundaryType !== 'line') {
      callback(0);
    }

    while (!cursor.isEos()) {
      this.stateMachine.handleNext(cursor);

      if (this.suppressions.shouldBreak(cursor)) {
        callback(cursor.actualPosition);
      }
    }
  }

  getBoundaryType() {
    return this.stateMachine.boundaryType;
  }
}
;RuleSet.grapheme = {
  forwardTable: {
    flags: 1,
    table: JSON.parse(`[
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 3, 4, 5, 4, 4, 6,
      4, 7, 4, 8, 9, 10, 4, 9, 10, 11, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0,
      0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 0, 4, 0, 4, 0, 0,
      0, 4, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 5, 0,
      4, 0, 5, 0, 0, 0, 12, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      4, 5, 4, 4, 6, 4, 7, 4, 8, 9, 10, 4, 9, 10, 11, -1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 7, 4, 0, 4, 0, 13, 0, 0, 0, 7, 0, 0, 0,
      -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 0, 4, 0, 4, 8, 9,
      0, 4, 9, 10, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 0,
      4, 0, 4, 0, 9, 10, 4, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 4, 4, 0, 4, 0, 4, 0, 0, 10, 4, 0, 0, 0, -1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 4, 4, 0, 4, 0, 4, 0, 0, 0, 4, 0, 0, 14,
      -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 4, 4, 0, 4, 0, 4, 0, 0,
      0, 4, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 13, 4, 0,
      4, 7, 13, 0, 0, 0, 13, 0, 0, 0, -1, 1, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 4, 4, 0, 4, 0, 4, 0, 0, 0, 4, 0, 0, 15, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ]`)
  },

  categoryTable: JSON.parse(`[
    [0, 9, 3], [10, 10, 4], [11, 12, 3], [13, 13, 5],
    [14, 31, 3], [32, 126, 6], [127, 159, 3], [160, 168, 6],
    [169, 169, 7], [170, 172, 6], [173, 173, 3], [174, 174, 7],
    [175, 767, 6], [768, 846, 8], [847, 847, 9], [848, 879, 8],
    [880, 1154, 6], [1155, 1159, 8], [1160, 1161, 9], [1162, 1424, 6],
    [1425, 1469, 8], [1470, 1470, 6], [1471, 1471, 8], [1472, 1472, 6],
    [1473, 1474, 8], [1475, 1475, 6], [1476, 1477, 8], [1478, 1478, 6],
    [1479, 1479, 8], [1480, 1535, 6], [1536, 1541, 10], [1542, 1551, 6],
    [1552, 1562, 8], [1563, 1563, 6], [1564, 1564, 3], [1565, 1610, 6],
    [1611, 1631, 8], [1632, 1647, 6], [1648, 1648, 8], [1649, 1749, 6],
    [1750, 1756, 8], [1757, 1757, 10], [1758, 1758, 6], [1759, 1764, 8],
    [1765, 1766, 6], [1767, 1768, 8], [1769, 1769, 6], [1770, 1773, 8],
    [1774, 1806, 6], [1807, 1807, 10], [1808, 1808, 6], [1809, 1809, 8],
    [1810, 1839, 6], [1840, 1866, 8], [1867, 1957, 6], [1958, 1968, 9],
    [1969, 2026, 6], [2027, 2035, 8], [2036, 2044, 6], [2045, 2045, 8],
    [2046, 2069, 6], [2070, 2073, 8], [2074, 2074, 6], [2075, 2083, 8],
    [2084, 2084, 6], [2085, 2087, 8], [2088, 2088, 6], [2089, 2093, 8],
    [2094, 2136, 6], [2137, 2139, 8], [2140, 2258, 6], [2259, 2273, 8],
    [2274, 2274, 10], [2275, 2303, 8], [2304, 2306, 9], [2307, 2307, 11],
    [2308, 2324, 6], [2325, 2361, 12], [2362, 2362, 9], [2363, 2363, 11],
    [2364, 2364, 8], [2365, 2365, 6], [2366, 2368, 11], [2369, 2376, 9],
    [2377, 2380, 11], [2381, 2381, 13], [2382, 2383, 11], [2384, 2384, 6],
    [2385, 2388, 8], [2389, 2391, 9], [2392, 2399, 12], [2400, 2401, 6],
    [2402, 2403, 9], [2404, 2423, 6], [2424, 2431, 12], [2432, 2432, 6],
    [2433, 2433, 9], [2434, 2435, 11], [2436, 2452, 6], [2453, 2472, 12],
    [2473, 2473, 6], [2474, 2480, 12], [2481, 2481, 6], [2482, 2482, 12],
    [2483, 2485, 6], [2486, 2489, 12], [2490, 2491, 6], [2492, 2492, 8],
    [2493, 2493, 6], [2494, 2494, 9], [2495, 2496, 11], [2497, 2500, 9],
    [2501, 2502, 6], [2503, 2504, 11], [2505, 2506, 6], [2507, 2508, 11],
    [2509, 2509, 13], [2510, 2518, 6], [2519, 2519, 9], [2520, 2523, 6],
    [2524, 2525, 12], [2526, 2526, 6], [2527, 2527, 12], [2528, 2529, 6],
    [2530, 2531, 9], [2532, 2543, 6], [2544, 2545, 12], [2546, 2557, 6],
    [2558, 2558, 8], [2559, 2560, 6], [2561, 2562, 9], [2563, 2563, 11],
    [2564, 2619, 6], [2620, 2620, 8], [2621, 2621, 6], [2622, 2624, 11],
    [2625, 2626, 9], [2627, 2630, 6], [2631, 2632, 9], [2633, 2634, 6],
    [2635, 2636, 9], [2637, 2637, 8], [2638, 2640, 6], [2641, 2641, 9],
    [2642, 2671, 6], [2672, 2673, 9], [2674, 2676, 6], [2677, 2677, 9],
    [2678, 2688, 6], [2689, 2690, 9], [2691, 2691, 11], [2692, 2708, 6],
    [2709, 2728, 12], [2729, 2729, 6], [2730, 2736, 12], [2737, 2737, 6],
    [2738, 2739, 12], [2740, 2740, 6], [2741, 2745, 12], [2746, 2747, 6],
    [2748, 2748, 8], [2749, 2749, 6], [2750, 2752, 11], [2753, 2757, 9],
    [2758, 2758, 6], [2759, 2760, 9], [2761, 2761, 11], [2762, 2762, 6],
    [2763, 2764, 11], [2765, 2765, 13], [2766, 2785, 6], [2786, 2787, 9],
    [2788, 2808, 6], [2809, 2809, 12], [2810, 2815, 9], [2816, 2816, 6],
    [2817, 2817, 9], [2818, 2819, 11], [2820, 2836, 6], [2837, 2856, 12],
    [2857, 2857, 6], [2858, 2864, 12], [2865, 2865, 6], [2866, 2867, 12],
    [2868, 2868, 6], [2869, 2873, 12], [2874, 2875, 6], [2876, 2876, 8],
    [2877, 2877, 6], [2878, 2879, 9], [2880, 2880, 11], [2881, 2884, 9],
    [2885, 2886, 6], [2887, 2888, 11], [2889, 2890, 6], [2891, 2892, 11],
    [2893, 2893, 13], [2894, 2900, 6], [2901, 2903, 9], [2904, 2907, 6],
    [2908, 2909, 12], [2910, 2910, 6], [2911, 2911, 12], [2912, 2913, 6],
    [2914, 2915, 9], [2916, 2928, 6], [2929, 2929, 12], [2930, 2945, 6],
    [2946, 2946, 9], [2947, 3005, 6], [3006, 3006, 9], [3007, 3007, 11],
    [3008, 3008, 9], [3009, 3010, 11], [3011, 3013, 6], [3014, 3016, 11],
    [3017, 3017, 6], [3018, 3020, 11], [3021, 3021, 8], [3022, 3030, 6],
    [3031, 3031, 9], [3032, 3071, 6], [3072, 3072, 9], [3073, 3075, 11],
    [3076, 3076, 9], [3077, 3092, 6], [3093, 3112, 12], [3113, 3113, 6],
    [3114, 3129, 12], [3130, 3133, 6], [3134, 3136, 9], [3137, 3140, 11],
    [3141, 3141, 6], [3142, 3144, 9], [3145, 3145, 6], [3146, 3148, 9],
    [3149, 3149, 13], [3150, 3156, 6], [3157, 3158, 8], [3159, 3159, 6],
    [3160, 3162, 12], [3163, 3169, 6], [3170, 3171, 9], [3172, 3200, 6],
    [3201, 3201, 9], [3202, 3203, 11], [3204, 3259, 6], [3260, 3260, 8],
    [3261, 3261, 6], [3262, 3262, 11], [3263, 3263, 9], [3264, 3265, 11],
    [3266, 3266, 9], [3267, 3268, 11], [3269, 3269, 6], [3270, 3270, 9],
    [3271, 3272, 11], [3273, 3273, 6], [3274, 3275, 11], [3276, 3276, 9],
    [3277, 3277, 8], [3278, 3284, 6], [3285, 3286, 9], [3287, 3297, 6],
    [3298, 3299, 9], [3300, 3327, 6], [3328, 3329, 9], [3330, 3331, 11],
    [3332, 3348, 6], [3349, 3386, 12], [3387, 3388, 8], [3389, 3389, 6],
    [3390, 3390, 9], [3391, 3392, 11], [3393, 3396, 9], [3397, 3397, 6],
    [3398, 3400, 11], [3401, 3401, 6], [3402, 3404, 11], [3405, 3405, 13],
    [3406, 3406, 10], [3407, 3414, 6], [3415, 3415, 9], [3416, 3425, 6],
    [3426, 3427, 9], [3428, 3456, 6], [3457, 3457, 9], [3458, 3459, 11],
    [3460, 3529, 6], [3530, 3530, 8], [3531, 3534, 6], [3535, 3535, 9],
    [3536, 3537, 11], [3538, 3540, 9], [3541, 3541, 6], [3542, 3542, 9],
    [3543, 3543, 6], [3544, 3550, 11], [3551, 3551, 9], [3552, 3569, 6],
    [3570, 3571, 11], [3572, 3632, 6], [3633, 3633, 9], [3634, 3634, 6],
    [3635, 3635, 11], [3636, 3639, 9], [3640, 3642, 8], [3643, 3654, 6],
    [3655, 3655, 9], [3656, 3659, 8], [3660, 3662, 9], [3663, 3760, 6],
    [3761, 3761, 9], [3762, 3762, 6], [3763, 3763, 11], [3764, 3767, 9],
    [3768, 3770, 8], [3771, 3772, 9], [3773, 3783, 6], [3784, 3787, 8],
    [3788, 3789, 9], [3790, 3863, 6], [3864, 3865, 8], [3866, 3892, 6],
    [3893, 3893, 8], [3894, 3894, 6], [3895, 3895, 8], [3896, 3896, 6],
    [3897, 3897, 8], [3898, 3901, 6], [3902, 3903, 11], [3904, 3952, 6],
    [3953, 3954, 8], [3955, 3955, 9], [3956, 3956, 8], [3957, 3961, 9],
    [3962, 3965, 8], [3966, 3966, 9], [3967, 3967, 11], [3968, 3968, 8],
    [3969, 3969, 9], [3970, 3972, 8], [3973, 3973, 6], [3974, 3975, 8],
    [3976, 3980, 6], [3981, 3991, 9], [3992, 3992, 6], [3993, 4028, 9],
    [4029, 4037, 6], [4038, 4038, 8], [4039, 4140, 6], [4141, 4144, 9],
    [4145, 4145, 11], [4146, 4150, 9], [4151, 4151, 8], [4152, 4152, 6],
    [4153, 4154, 8], [4155, 4156, 11], [4157, 4158, 9], [4159, 4181, 6],
    [4182, 4183, 11], [4184, 4185, 9], [4186, 4189, 6], [4190, 4192, 9],
    [4193, 4208, 6], [4209, 4212, 9], [4213, 4225, 6], [4226, 4226, 9],
    [4227, 4227, 6], [4228, 4228, 11], [4229, 4230, 9], [4231, 4236, 6],
    [4237, 4237, 8], [4238, 4252, 6], [4253, 4253, 9], [4254, 4351, 6],
    [4352, 4447, 14], [4448, 4519, 15], [4520, 4607, 16], [4608, 4956, 6],
    [4957, 4959, 8], [4960, 5905, 6], [5906, 5907, 9], [5908, 5908, 8],
    [5909, 5937, 6], [5938, 5939, 9], [5940, 5940, 8], [5941, 5969, 6],
    [5970, 5971, 9], [5972, 6001, 6], [6002, 6003, 9], [6004, 6067, 6],
    [6068, 6069, 9], [6070, 6070, 11], [6071, 6077, 9], [6078, 6085, 11],
    [6086, 6086, 9], [6087, 6088, 11], [6089, 6097, 9], [6098, 6098, 8],
    [6099, 6099, 9], [6100, 6108, 6], [6109, 6109, 8], [6110, 6154, 6],
    [6155, 6157, 9], [6158, 6158, 3], [6159, 6276, 6], [6277, 6278, 9],
    [6279, 6312, 6], [6313, 6313, 8], [6314, 6431, 6], [6432, 6434, 9],
    [6435, 6438, 11], [6439, 6440, 9], [6441, 6443, 11], [6444, 6447, 6],
    [6448, 6449, 11], [6450, 6450, 9], [6451, 6456, 11], [6457, 6459, 8],
    [6460, 6678, 6], [6679, 6680, 8], [6681, 6682, 11], [6683, 6683, 9],
    [6684, 6740, 6], [6741, 6741, 11], [6742, 6742, 9], [6743, 6743, 11],
    [6744, 6750, 9], [6751, 6751, 6], [6752, 6752, 8], [6753, 6753, 6],
    [6754, 6754, 9], [6755, 6756, 6], [6757, 6764, 9], [6765, 6770, 11],
    [6771, 6772, 9], [6773, 6780, 8], [6781, 6782, 6], [6783, 6783, 8],
    [6784, 6831, 6], [6832, 6845, 8], [6846, 6846, 9], [6847, 6848, 8],
    [6849, 6911, 6], [6912, 6915, 9], [6916, 6916, 11], [6917, 6963, 6],
    [6964, 6964, 8], [6965, 6970, 9], [6971, 6971, 11], [6972, 6972, 9],
    [6973, 6977, 11], [6978, 6978, 9], [6979, 6980, 11], [6981, 7018, 6],
    [7019, 7027, 8], [7028, 7039, 6], [7040, 7041, 9], [7042, 7042, 11],
    [7043, 7072, 6], [7073, 7073, 11], [7074, 7077, 9], [7078, 7079, 11],
    [7080, 7081, 9], [7082, 7082, 11], [7083, 7083, 8], [7084, 7085, 9],
    [7086, 7141, 6], [7142, 7142, 8], [7143, 7143, 11], [7144, 7145, 9],
    [7146, 7148, 11], [7149, 7149, 9], [7150, 7150, 11], [7151, 7153, 9],
    [7154, 7155, 11], [7156, 7203, 6], [7204, 7211, 11], [7212, 7219, 9],
    [7220, 7221, 11], [7222, 7222, 9], [7223, 7223, 8], [7224, 7375, 6],
    [7376, 7378, 8], [7379, 7379, 6], [7380, 7392, 8], [7393, 7393, 11],
    [7394, 7400, 8], [7401, 7404, 6], [7405, 7405, 8], [7406, 7411, 6],
    [7412, 7412, 8], [7413, 7414, 6], [7415, 7415, 11], [7416, 7417, 8],
    [7418, 7615, 6], [7616, 7673, 8], [7674, 7674, 6], [7675, 7679, 8],
    [7680, 8202, 6], [8203, 8203, 3], [8204, 8204, 9], [8205, 8205, 17],
    [8206, 8207, 3], [8208, 8231, 6], [8232, 8238, 3], [8239, 8251, 6],
    [8252, 8252, 7], [8253, 8264, 6], [8265, 8265, 7], [8266, 8287, 6],
    [8288, 8303, 3], [8304, 8399, 6], [8400, 8412, 8], [8413, 8416, 9],
    [8417, 8417, 8], [8418, 8420, 9], [8421, 8432, 8], [8433, 8481, 6],
    [8482, 8482, 7], [8483, 8504, 6], [8505, 8505, 7], [8506, 8595, 6],
    [8596, 8601, 7], [8602, 8616, 6], [8617, 8618, 7], [8619, 8985, 6],
    [8986, 8987, 7], [8988, 8999, 6], [9000, 9000, 7], [9001, 9095, 6],
    [9096, 9096, 7], [9097, 9166, 6], [9167, 9167, 7], [9168, 9192, 6],
    [9193, 9203, 7], [9204, 9207, 6], [9208, 9210, 7], [9211, 9409, 6],
    [9410, 9410, 7], [9411, 9641, 6], [9642, 9643, 7], [9644, 9653, 6],
    [9654, 9654, 7], [9655, 9663, 6], [9664, 9664, 7], [9665, 9722, 6],
    [9723, 9726, 7], [9727, 9727, 6], [9728, 9733, 7], [9734, 9734, 6],
    [9735, 9746, 7], [9747, 9747, 6], [9748, 9861, 7], [9862, 9871, 6],
    [9872, 9989, 7], [9990, 9991, 6], [9992, 10002, 7], [10003, 10003, 6],
    [10004, 10004, 7], [10005, 10005, 6], [10006, 10006, 7], [10007, 10012, 6],
    [10013, 10013, 7], [10014, 10016, 6], [10017, 10017, 7], [10018, 10023, 6],
    [10024, 10024, 7], [10025, 10034, 6], [10035, 10036, 7], [10037, 10051, 6],
    [10052, 10052, 7], [10053, 10054, 6], [10055, 10055, 7], [10056, 10059, 6],
    [10060, 10060, 7], [10061, 10061, 6], [10062, 10062, 7], [10063, 10066, 6],
    [10067, 10069, 7], [10070, 10070, 6], [10071, 10071, 7], [10072, 10082, 6],
    [10083, 10087, 7], [10088, 10132, 6], [10133, 10135, 7], [10136, 10144, 6],
    [10145, 10145, 7], [10146, 10159, 6], [10160, 10160, 7], [10161, 10174, 6],
    [10175, 10175, 7], [10176, 10547, 6], [10548, 10549, 7], [10550, 11012, 6],
    [11013, 11015, 7], [11016, 11034, 6], [11035, 11036, 7], [11037, 11087, 6],
    [11088, 11088, 7], [11089, 11092, 6], [11093, 11093, 7], [11094, 11502, 6],
    [11503, 11505, 8], [11506, 11646, 6], [11647, 11647, 8], [11648, 11743, 6],
    [11744, 11775, 8], [11776, 12329, 6], [12330, 12335, 8], [12336, 12336, 7],
    [12337, 12348, 6], [12349, 12349, 7], [12350, 12440, 6], [12441, 12442, 8],
    [12443, 12950, 6], [12951, 12951, 7], [12952, 12952, 6], [12953, 12953, 7],
    [12954, 42606, 6], [42607, 42607, 8], [42608, 42610, 9], [42611, 42611, 6],
    [42612, 42621, 8], [42622, 42653, 6], [42654, 42655, 8], [42656, 42735, 6],
    [42736, 42737, 8], [42738, 43009, 6], [43010, 43010, 9], [43011, 43013, 6],
    [43014, 43014, 8], [43015, 43018, 6], [43019, 43019, 9], [43020, 43042, 6],
    [43043, 43044, 11], [43045, 43046, 9], [43047, 43047, 11], [43048, 43051, 6],
    [43052, 43052, 8], [43053, 43135, 6], [43136, 43137, 11], [43138, 43187, 6],
    [43188, 43203, 11], [43204, 43204, 8], [43205, 43205, 9], [43206, 43231, 6],
    [43232, 43249, 8], [43250, 43262, 6], [43263, 43263, 9], [43264, 43301, 6],
    [43302, 43306, 9], [43307, 43309, 8], [43310, 43334, 6], [43335, 43345, 9],
    [43346, 43347, 11], [43348, 43359, 6], [43360, 43388, 14], [43389, 43391, 6],
    [43392, 43394, 9], [43395, 43395, 11], [43396, 43442, 6], [43443, 43443, 8],
    [43444, 43445, 11], [43446, 43449, 9], [43450, 43451, 11], [43452, 43453, 9],
    [43454, 43456, 11], [43457, 43492, 6], [43493, 43493, 9], [43494, 43560, 6],
    [43561, 43566, 9], [43567, 43568, 11], [43569, 43570, 9], [43571, 43572, 11],
    [43573, 43574, 9], [43575, 43586, 6], [43587, 43587, 9], [43588, 43595, 6],
    [43596, 43596, 9], [43597, 43597, 11], [43598, 43643, 6], [43644, 43644, 9],
    [43645, 43695, 6], [43696, 43696, 8], [43697, 43697, 6], [43698, 43700, 8],
    [43701, 43702, 6], [43703, 43704, 8], [43705, 43709, 6], [43710, 43711, 8],
    [43712, 43712, 6], [43713, 43713, 8], [43714, 43754, 6], [43755, 43755, 11],
    [43756, 43757, 9], [43758, 43759, 11], [43760, 43764, 6], [43765, 43765, 11],
    [43766, 43766, 8], [43767, 44002, 6], [44003, 44004, 11], [44005, 44005, 9],
    [44006, 44007, 11], [44008, 44008, 9], [44009, 44010, 11], [44011, 44011, 6],
    [44012, 44012, 11], [44013, 44013, 8], [44014, 44031, 6], [44032, 44032, 18],
    [44033, 44059, 19], [44060, 44060, 18], [44061, 44087, 19], [44088, 44088, 18],
    [44089, 44115, 19], [44116, 44116, 18], [44117, 44143, 19], [44144, 44144, 18],
    [44145, 44171, 19], [44172, 44172, 18], [44173, 44199, 19], [44200, 44200, 18],
    [44201, 44227, 19], [44228, 44228, 18], [44229, 44255, 19], [44256, 44256, 18],
    [44257, 44283, 19], [44284, 44284, 18], [44285, 44311, 19], [44312, 44312, 18],
    [44313, 44339, 19], [44340, 44340, 18], [44341, 44367, 19], [44368, 44368, 18],
    [44369, 44395, 19], [44396, 44396, 18], [44397, 44423, 19], [44424, 44424, 18],
    [44425, 44451, 19], [44452, 44452, 18], [44453, 44479, 19], [44480, 44480, 18],
    [44481, 44507, 19], [44508, 44508, 18], [44509, 44535, 19], [44536, 44536, 18],
    [44537, 44563, 19], [44564, 44564, 18], [44565, 44591, 19], [44592, 44592, 18],
    [44593, 44619, 19], [44620, 44620, 18], [44621, 44647, 19], [44648, 44648, 18],
    [44649, 44675, 19], [44676, 44676, 18], [44677, 44703, 19], [44704, 44704, 18],
    [44705, 44731, 19], [44732, 44732, 18], [44733, 44759, 19], [44760, 44760, 18],
    [44761, 44787, 19], [44788, 44788, 18], [44789, 44815, 19], [44816, 44816, 18],
    [44817, 44843, 19], [44844, 44844, 18], [44845, 44871, 19], [44872, 44872, 18],
    [44873, 44899, 19], [44900, 44900, 18], [44901, 44927, 19], [44928, 44928, 18],
    [44929, 44955, 19], [44956, 44956, 18], [44957, 44983, 19], [44984, 44984, 18],
    [44985, 45011, 19], [45012, 45012, 18], [45013, 45039, 19], [45040, 45040, 18],
    [45041, 45067, 19], [45068, 45068, 18], [45069, 45095, 19], [45096, 45096, 18],
    [45097, 45123, 19], [45124, 45124, 18], [45125, 45151, 19], [45152, 45152, 18],
    [45153, 45179, 19], [45180, 45180, 18], [45181, 45207, 19], [45208, 45208, 18],
    [45209, 45235, 19], [45236, 45236, 18], [45237, 45263, 19], [45264, 45264, 18],
    [45265, 45291, 19], [45292, 45292, 18], [45293, 45319, 19], [45320, 45320, 18],
    [45321, 45347, 19], [45348, 45348, 18], [45349, 45375, 19], [45376, 45376, 18],
    [45377, 45403, 19], [45404, 45404, 18], [45405, 45431, 19], [45432, 45432, 18],
    [45433, 45459, 19], [45460, 45460, 18], [45461, 45487, 19], [45488, 45488, 18],
    [45489, 45515, 19], [45516, 45516, 18], [45517, 45543, 19], [45544, 45544, 18],
    [45545, 45571, 19], [45572, 45572, 18], [45573, 45599, 19], [45600, 45600, 18],
    [45601, 45627, 19], [45628, 45628, 18], [45629, 45655, 19], [45656, 45656, 18],
    [45657, 45683, 19], [45684, 45684, 18], [45685, 45711, 19], [45712, 45712, 18],
    [45713, 45739, 19], [45740, 45740, 18], [45741, 45767, 19], [45768, 45768, 18],
    [45769, 45795, 19], [45796, 45796, 18], [45797, 45823, 19], [45824, 45824, 18],
    [45825, 45851, 19], [45852, 45852, 18], [45853, 45879, 19], [45880, 45880, 18],
    [45881, 45907, 19], [45908, 45908, 18], [45909, 45935, 19], [45936, 45936, 18],
    [45937, 45963, 19], [45964, 45964, 18], [45965, 45991, 19], [45992, 45992, 18],
    [45993, 46019, 19], [46020, 46020, 18], [46021, 46047, 19], [46048, 46048, 18],
    [46049, 46075, 19], [46076, 46076, 18], [46077, 46103, 19], [46104, 46104, 18],
    [46105, 46131, 19], [46132, 46132, 18], [46133, 46159, 19], [46160, 46160, 18],
    [46161, 46187, 19], [46188, 46188, 18], [46189, 46215, 19], [46216, 46216, 18],
    [46217, 46243, 19], [46244, 46244, 18], [46245, 46271, 19], [46272, 46272, 18],
    [46273, 46299, 19], [46300, 46300, 18], [46301, 46327, 19], [46328, 46328, 18],
    [46329, 46355, 19], [46356, 46356, 18], [46357, 46383, 19], [46384, 46384, 18],
    [46385, 46411, 19], [46412, 46412, 18], [46413, 46439, 19], [46440, 46440, 18],
    [46441, 46467, 19], [46468, 46468, 18], [46469, 46495, 19], [46496, 46496, 18],
    [46497, 46523, 19], [46524, 46524, 18], [46525, 46551, 19], [46552, 46552, 18],
    [46553, 46579, 19], [46580, 46580, 18], [46581, 46607, 19], [46608, 46608, 18],
    [46609, 46635, 19], [46636, 46636, 18], [46637, 46663, 19], [46664, 46664, 18],
    [46665, 46691, 19], [46692, 46692, 18], [46693, 46719, 19], [46720, 46720, 18],
    [46721, 46747, 19], [46748, 46748, 18], [46749, 46775, 19], [46776, 46776, 18],
    [46777, 46803, 19], [46804, 46804, 18], [46805, 46831, 19], [46832, 46832, 18],
    [46833, 46859, 19], [46860, 46860, 18], [46861, 46887, 19], [46888, 46888, 18],
    [46889, 46915, 19], [46916, 46916, 18], [46917, 46943, 19], [46944, 46944, 18],
    [46945, 46971, 19], [46972, 46972, 18], [46973, 46999, 19], [47000, 47000, 18],
    [47001, 47027, 19], [47028, 47028, 18], [47029, 47055, 19], [47056, 47056, 18],
    [47057, 47083, 19], [47084, 47084, 18], [47085, 47111, 19], [47112, 47112, 18],
    [47113, 47139, 19], [47140, 47140, 18], [47141, 47167, 19], [47168, 47168, 18],
    [47169, 47195, 19], [47196, 47196, 18], [47197, 47223, 19], [47224, 47224, 18],
    [47225, 47251, 19], [47252, 47252, 18], [47253, 47279, 19], [47280, 47280, 18],
    [47281, 47307, 19], [47308, 47308, 18], [47309, 47335, 19], [47336, 47336, 18],
    [47337, 47363, 19], [47364, 47364, 18], [47365, 47391, 19], [47392, 47392, 18],
    [47393, 47419, 19], [47420, 47420, 18], [47421, 47447, 19], [47448, 47448, 18],
    [47449, 47475, 19], [47476, 47476, 18], [47477, 47503, 19], [47504, 47504, 18],
    [47505, 47531, 19], [47532, 47532, 18], [47533, 47559, 19], [47560, 47560, 18],
    [47561, 47587, 19], [47588, 47588, 18], [47589, 47615, 19], [47616, 47616, 18],
    [47617, 47643, 19], [47644, 47644, 18], [47645, 47671, 19], [47672, 47672, 18],
    [47673, 47699, 19], [47700, 47700, 18], [47701, 47727, 19], [47728, 47728, 18],
    [47729, 47755, 19], [47756, 47756, 18], [47757, 47783, 19], [47784, 47784, 18],
    [47785, 47811, 19], [47812, 47812, 18], [47813, 47839, 19], [47840, 47840, 18],
    [47841, 47867, 19], [47868, 47868, 18], [47869, 47895, 19], [47896, 47896, 18],
    [47897, 47923, 19], [47924, 47924, 18], [47925, 47951, 19], [47952, 47952, 18],
    [47953, 47979, 19], [47980, 47980, 18], [47981, 48007, 19], [48008, 48008, 18],
    [48009, 48035, 19], [48036, 48036, 18], [48037, 48063, 19], [48064, 48064, 18],
    [48065, 48091, 19], [48092, 48092, 18], [48093, 48119, 19], [48120, 48120, 18],
    [48121, 48147, 19], [48148, 48148, 18], [48149, 48175, 19], [48176, 48176, 18],
    [48177, 48203, 19], [48204, 48204, 18], [48205, 48231, 19], [48232, 48232, 18],
    [48233, 48259, 19], [48260, 48260, 18], [48261, 48287, 19], [48288, 48288, 18],
    [48289, 48315, 19], [48316, 48316, 18], [48317, 48343, 19], [48344, 48344, 18],
    [48345, 48371, 19], [48372, 48372, 18], [48373, 48399, 19], [48400, 48400, 18],
    [48401, 48427, 19], [48428, 48428, 18], [48429, 48455, 19], [48456, 48456, 18],
    [48457, 48483, 19], [48484, 48484, 18], [48485, 48511, 19], [48512, 48512, 18],
    [48513, 48539, 19], [48540, 48540, 18], [48541, 48567, 19], [48568, 48568, 18],
    [48569, 48595, 19], [48596, 48596, 18], [48597, 48623, 19], [48624, 48624, 18],
    [48625, 48651, 19], [48652, 48652, 18], [48653, 48679, 19], [48680, 48680, 18],
    [48681, 48707, 19], [48708, 48708, 18], [48709, 48735, 19], [48736, 48736, 18],
    [48737, 48763, 19], [48764, 48764, 18], [48765, 48791, 19], [48792, 48792, 18],
    [48793, 48819, 19], [48820, 48820, 18], [48821, 48847, 19], [48848, 48848, 18],
    [48849, 48875, 19], [48876, 48876, 18], [48877, 48903, 19], [48904, 48904, 18],
    [48905, 48931, 19], [48932, 48932, 18], [48933, 48959, 19], [48960, 48960, 18],
    [48961, 48987, 19], [48988, 48988, 18], [48989, 49015, 19], [49016, 49016, 18],
    [49017, 49043, 19], [49044, 49044, 18], [49045, 49071, 19], [49072, 49072, 18],
    [49073, 49099, 19], [49100, 49100, 18], [49101, 49127, 19], [49128, 49128, 18],
    [49129, 49155, 19], [49156, 49156, 18], [49157, 49183, 19], [49184, 49184, 18],
    [49185, 49211, 19], [49212, 49212, 18], [49213, 49239, 19], [49240, 49240, 18],
    [49241, 49267, 19], [49268, 49268, 18], [49269, 49295, 19], [49296, 49296, 18],
    [49297, 49323, 19], [49324, 49324, 18], [49325, 49351, 19], [49352, 49352, 18],
    [49353, 49379, 19], [49380, 49380, 18], [49381, 49407, 19], [49408, 49408, 18],
    [49409, 49435, 19], [49436, 49436, 18], [49437, 49463, 19], [49464, 49464, 18],
    [49465, 49491, 19], [49492, 49492, 18], [49493, 49519, 19], [49520, 49520, 18],
    [49521, 49547, 19], [49548, 49548, 18], [49549, 49575, 19], [49576, 49576, 18],
    [49577, 49603, 19], [49604, 49604, 18], [49605, 49631, 19], [49632, 49632, 18],
    [49633, 49659, 19], [49660, 49660, 18], [49661, 49687, 19], [49688, 49688, 18],
    [49689, 49715, 19], [49716, 49716, 18], [49717, 49743, 19], [49744, 49744, 18],
    [49745, 49771, 19], [49772, 49772, 18], [49773, 49799, 19], [49800, 49800, 18],
    [49801, 49827, 19], [49828, 49828, 18], [49829, 49855, 19], [49856, 49856, 18],
    [49857, 49883, 19], [49884, 49884, 18], [49885, 49911, 19], [49912, 49912, 18],
    [49913, 49939, 19], [49940, 49940, 18], [49941, 49967, 19], [49968, 49968, 18],
    [49969, 49995, 19], [49996, 49996, 18], [49997, 50023, 19], [50024, 50024, 18],
    [50025, 50051, 19], [50052, 50052, 18], [50053, 50079, 19], [50080, 50080, 18],
    [50081, 50107, 19], [50108, 50108, 18], [50109, 50135, 19], [50136, 50136, 18],
    [50137, 50163, 19], [50164, 50164, 18], [50165, 50191, 19], [50192, 50192, 18],
    [50193, 50219, 19], [50220, 50220, 18], [50221, 50247, 19], [50248, 50248, 18],
    [50249, 50275, 19], [50276, 50276, 18], [50277, 50303, 19], [50304, 50304, 18],
    [50305, 50331, 19], [50332, 50332, 18], [50333, 50359, 19], [50360, 50360, 18],
    [50361, 50387, 19], [50388, 50388, 18], [50389, 50415, 19], [50416, 50416, 18],
    [50417, 50443, 19], [50444, 50444, 18], [50445, 50471, 19], [50472, 50472, 18],
    [50473, 50499, 19], [50500, 50500, 18], [50501, 50527, 19], [50528, 50528, 18],
    [50529, 50555, 19], [50556, 50556, 18], [50557, 50583, 19], [50584, 50584, 18],
    [50585, 50611, 19], [50612, 50612, 18], [50613, 50639, 19], [50640, 50640, 18],
    [50641, 50667, 19], [50668, 50668, 18], [50669, 50695, 19], [50696, 50696, 18],
    [50697, 50723, 19], [50724, 50724, 18], [50725, 50751, 19], [50752, 50752, 18],
    [50753, 50779, 19], [50780, 50780, 18], [50781, 50807, 19], [50808, 50808, 18],
    [50809, 50835, 19], [50836, 50836, 18], [50837, 50863, 19], [50864, 50864, 18],
    [50865, 50891, 19], [50892, 50892, 18], [50893, 50919, 19], [50920, 50920, 18],
    [50921, 50947, 19], [50948, 50948, 18], [50949, 50975, 19], [50976, 50976, 18],
    [50977, 51003, 19], [51004, 51004, 18], [51005, 51031, 19], [51032, 51032, 18],
    [51033, 51059, 19], [51060, 51060, 18], [51061, 51087, 19], [51088, 51088, 18],
    [51089, 51115, 19], [51116, 51116, 18], [51117, 51143, 19], [51144, 51144, 18],
    [51145, 51171, 19], [51172, 51172, 18], [51173, 51199, 19], [51200, 51200, 18],
    [51201, 51227, 19], [51228, 51228, 18], [51229, 51255, 19], [51256, 51256, 18],
    [51257, 51283, 19], [51284, 51284, 18], [51285, 51311, 19], [51312, 51312, 18],
    [51313, 51339, 19], [51340, 51340, 18], [51341, 51367, 19], [51368, 51368, 18],
    [51369, 51395, 19], [51396, 51396, 18], [51397, 51423, 19], [51424, 51424, 18],
    [51425, 51451, 19], [51452, 51452, 18], [51453, 51479, 19], [51480, 51480, 18],
    [51481, 51507, 19], [51508, 51508, 18], [51509, 51535, 19], [51536, 51536, 18],
    [51537, 51563, 19], [51564, 51564, 18], [51565, 51591, 19], [51592, 51592, 18],
    [51593, 51619, 19], [51620, 51620, 18], [51621, 51647, 19], [51648, 51648, 18],
    [51649, 51675, 19], [51676, 51676, 18], [51677, 51703, 19], [51704, 51704, 18],
    [51705, 51731, 19], [51732, 51732, 18], [51733, 51759, 19], [51760, 51760, 18],
    [51761, 51787, 19], [51788, 51788, 18], [51789, 51815, 19], [51816, 51816, 18],
    [51817, 51843, 19], [51844, 51844, 18], [51845, 51871, 19], [51872, 51872, 18],
    [51873, 51899, 19], [51900, 51900, 18], [51901, 51927, 19], [51928, 51928, 18],
    [51929, 51955, 19], [51956, 51956, 18], [51957, 51983, 19], [51984, 51984, 18],
    [51985, 52011, 19], [52012, 52012, 18], [52013, 52039, 19], [52040, 52040, 18],
    [52041, 52067, 19], [52068, 52068, 18], [52069, 52095, 19], [52096, 52096, 18],
    [52097, 52123, 19], [52124, 52124, 18], [52125, 52151, 19], [52152, 52152, 18],
    [52153, 52179, 19], [52180, 52180, 18], [52181, 52207, 19], [52208, 52208, 18],
    [52209, 52235, 19], [52236, 52236, 18], [52237, 52263, 19], [52264, 52264, 18],
    [52265, 52291, 19], [52292, 52292, 18], [52293, 52319, 19], [52320, 52320, 18],
    [52321, 52347, 19], [52348, 52348, 18], [52349, 52375, 19], [52376, 52376, 18],
    [52377, 52403, 19], [52404, 52404, 18], [52405, 52431, 19], [52432, 52432, 18],
    [52433, 52459, 19], [52460, 52460, 18], [52461, 52487, 19], [52488, 52488, 18],
    [52489, 52515, 19], [52516, 52516, 18], [52517, 52543, 19], [52544, 52544, 18],
    [52545, 52571, 19], [52572, 52572, 18], [52573, 52599, 19], [52600, 52600, 18],
    [52601, 52627, 19], [52628, 52628, 18], [52629, 52655, 19], [52656, 52656, 18],
    [52657, 52683, 19], [52684, 52684, 18], [52685, 52711, 19], [52712, 52712, 18],
    [52713, 52739, 19], [52740, 52740, 18], [52741, 52767, 19], [52768, 52768, 18],
    [52769, 52795, 19], [52796, 52796, 18], [52797, 52823, 19], [52824, 52824, 18],
    [52825, 52851, 19], [52852, 52852, 18], [52853, 52879, 19], [52880, 52880, 18],
    [52881, 52907, 19], [52908, 52908, 18], [52909, 52935, 19], [52936, 52936, 18],
    [52937, 52963, 19], [52964, 52964, 18], [52965, 52991, 19], [52992, 52992, 18],
    [52993, 53019, 19], [53020, 53020, 18], [53021, 53047, 19], [53048, 53048, 18],
    [53049, 53075, 19], [53076, 53076, 18], [53077, 53103, 19], [53104, 53104, 18],
    [53105, 53131, 19], [53132, 53132, 18], [53133, 53159, 19], [53160, 53160, 18],
    [53161, 53187, 19], [53188, 53188, 18], [53189, 53215, 19], [53216, 53216, 18],
    [53217, 53243, 19], [53244, 53244, 18], [53245, 53271, 19], [53272, 53272, 18],
    [53273, 53299, 19], [53300, 53300, 18], [53301, 53327, 19], [53328, 53328, 18],
    [53329, 53355, 19], [53356, 53356, 18], [53357, 53383, 19], [53384, 53384, 18],
    [53385, 53411, 19], [53412, 53412, 18], [53413, 53439, 19], [53440, 53440, 18],
    [53441, 53467, 19], [53468, 53468, 18], [53469, 53495, 19], [53496, 53496, 18],
    [53497, 53523, 19], [53524, 53524, 18], [53525, 53551, 19], [53552, 53552, 18],
    [53553, 53579, 19], [53580, 53580, 18], [53581, 53607, 19], [53608, 53608, 18],
    [53609, 53635, 19], [53636, 53636, 18], [53637, 53663, 19], [53664, 53664, 18],
    [53665, 53691, 19], [53692, 53692, 18], [53693, 53719, 19], [53720, 53720, 18],
    [53721, 53747, 19], [53748, 53748, 18], [53749, 53775, 19], [53776, 53776, 18],
    [53777, 53803, 19], [53804, 53804, 18], [53805, 53831, 19], [53832, 53832, 18],
    [53833, 53859, 19], [53860, 53860, 18], [53861, 53887, 19], [53888, 53888, 18],
    [53889, 53915, 19], [53916, 53916, 18], [53917, 53943, 19], [53944, 53944, 18],
    [53945, 53971, 19], [53972, 53972, 18], [53973, 53999, 19], [54000, 54000, 18],
    [54001, 54027, 19], [54028, 54028, 18], [54029, 54055, 19], [54056, 54056, 18],
    [54057, 54083, 19], [54084, 54084, 18], [54085, 54111, 19], [54112, 54112, 18],
    [54113, 54139, 19], [54140, 54140, 18], [54141, 54167, 19], [54168, 54168, 18],
    [54169, 54195, 19], [54196, 54196, 18], [54197, 54223, 19], [54224, 54224, 18],
    [54225, 54251, 19], [54252, 54252, 18], [54253, 54279, 19], [54280, 54280, 18],
    [54281, 54307, 19], [54308, 54308, 18], [54309, 54335, 19], [54336, 54336, 18],
    [54337, 54363, 19], [54364, 54364, 18], [54365, 54391, 19], [54392, 54392, 18],
    [54393, 54419, 19], [54420, 54420, 18], [54421, 54447, 19], [54448, 54448, 18],
    [54449, 54475, 19], [54476, 54476, 18], [54477, 54503, 19], [54504, 54504, 18],
    [54505, 54531, 19], [54532, 54532, 18], [54533, 54559, 19], [54560, 54560, 18],
    [54561, 54587, 19], [54588, 54588, 18], [54589, 54615, 19], [54616, 54616, 18],
    [54617, 54643, 19], [54644, 54644, 18], [54645, 54671, 19], [54672, 54672, 18],
    [54673, 54699, 19], [54700, 54700, 18], [54701, 54727, 19], [54728, 54728, 18],
    [54729, 54755, 19], [54756, 54756, 18], [54757, 54783, 19], [54784, 54784, 18],
    [54785, 54811, 19], [54812, 54812, 18], [54813, 54839, 19], [54840, 54840, 18],
    [54841, 54867, 19], [54868, 54868, 18], [54869, 54895, 19], [54896, 54896, 18],
    [54897, 54923, 19], [54924, 54924, 18], [54925, 54951, 19], [54952, 54952, 18],
    [54953, 54979, 19], [54980, 54980, 18], [54981, 55007, 19], [55008, 55008, 18],
    [55009, 55035, 19], [55036, 55036, 18], [55037, 55063, 19], [55064, 55064, 18],
    [55065, 55091, 19], [55092, 55092, 18], [55093, 55119, 19], [55120, 55120, 18],
    [55121, 55147, 19], [55148, 55148, 18], [55149, 55175, 19], [55176, 55176, 18],
    [55177, 55203, 19], [55204, 55215, 6], [55216, 55238, 15], [55239, 55242, 6],
    [55243, 55291, 16], [55292, 64285, 6], [64286, 64286, 8], [64287, 65023, 6],
    [65024, 65039, 9], [65040, 65055, 6], [65056, 65071, 8], [65072, 65278, 6],
    [65279, 65279, 3], [65280, 65437, 6], [65438, 65439, 9], [65440, 65519, 6],
    [65520, 65531, 3], [65532, 66044, 6], [66045, 66045, 8], [66046, 66271, 6],
    [66272, 66272, 8], [66273, 66421, 6], [66422, 66426, 8], [66427, 68096, 6],
    [68097, 68099, 9], [68100, 68100, 6], [68101, 68102, 9], [68103, 68107, 6],
    [68108, 68108, 9], [68109, 68109, 8], [68110, 68110, 9], [68111, 68111, 8],
    [68112, 68151, 6], [68152, 68154, 8], [68155, 68158, 6], [68159, 68159, 8],
    [68160, 68324, 6], [68325, 68326, 8], [68327, 68899, 6], [68900, 68903, 8],
    [68904, 69290, 6], [69291, 69292, 8], [69293, 69445, 6], [69446, 69456, 8],
    [69457, 69631, 6], [69632, 69632, 11], [69633, 69633, 9], [69634, 69634, 11],
    [69635, 69687, 6], [69688, 69701, 9], [69702, 69702, 8], [69703, 69758, 6],
    [69759, 69759, 8], [69760, 69761, 9], [69762, 69762, 11], [69763, 69807, 6],
    [69808, 69810, 11], [69811, 69814, 9], [69815, 69816, 11], [69817, 69818, 8],
    [69819, 69820, 6], [69821, 69821, 10], [69822, 69836, 6], [69837, 69837, 10],
    [69838, 69887, 6], [69888, 69890, 8], [69891, 69926, 6], [69927, 69931, 9],
    [69932, 69932, 11], [69933, 69938, 9], [69939, 69940, 8], [69941, 69956, 6],
    [69957, 69958, 11], [69959, 70002, 6], [70003, 70003, 8], [70004, 70015, 6],
    [70016, 70017, 9], [70018, 70018, 11], [70019, 70066, 6], [70067, 70069, 11],
    [70070, 70078, 9], [70079, 70080, 11], [70081, 70081, 6], [70082, 70083, 10],
    [70084, 70088, 6], [70089, 70089, 9], [70090, 70090, 8], [70091, 70092, 9],
    [70093, 70093, 6], [70094, 70094, 11], [70095, 70095, 9], [70096, 70187, 6],
    [70188, 70190, 11], [70191, 70193, 9], [70194, 70195, 11], [70196, 70196, 9],
    [70197, 70197, 11], [70198, 70198, 8], [70199, 70199, 9], [70200, 70205, 6],
    [70206, 70206, 9], [70207, 70366, 6], [70367, 70367, 9], [70368, 70370, 11],
    [70371, 70376, 9], [70377, 70378, 8], [70379, 70399, 6], [70400, 70401, 9],
    [70402, 70403, 11], [70404, 70458, 6], [70459, 70460, 8], [70461, 70461, 6],
    [70462, 70462, 9], [70463, 70463, 11], [70464, 70464, 9], [70465, 70468, 11],
    [70469, 70470, 6], [70471, 70472, 11], [70473, 70474, 6], [70475, 70477, 11],
    [70478, 70486, 6], [70487, 70487, 9], [70488, 70497, 6], [70498, 70499, 11],
    [70500, 70501, 6], [70502, 70508, 8], [70509, 70511, 6], [70512, 70516, 8],
    [70517, 70708, 6], [70709, 70711, 11], [70712, 70719, 9], [70720, 70721, 11],
    [70722, 70722, 8], [70723, 70724, 9], [70725, 70725, 11], [70726, 70726, 8],
    [70727, 70749, 6], [70750, 70750, 8], [70751, 70831, 6], [70832, 70832, 9],
    [70833, 70834, 11], [70835, 70840, 9], [70841, 70841, 11], [70842, 70842, 9],
    [70843, 70844, 11], [70845, 70845, 9], [70846, 70846, 11], [70847, 70848, 9],
    [70849, 70849, 11], [70850, 70851, 8], [70852, 71086, 6], [71087, 71087, 9],
    [71088, 71089, 11], [71090, 71093, 9], [71094, 71095, 6], [71096, 71099, 11],
    [71100, 71101, 9], [71102, 71102, 11], [71103, 71104, 8], [71105, 71131, 6],
    [71132, 71133, 9], [71134, 71215, 6], [71216, 71218, 11], [71219, 71226, 9],
    [71227, 71228, 11], [71229, 71229, 9], [71230, 71230, 11], [71231, 71231, 8],
    [71232, 71232, 9], [71233, 71338, 6], [71339, 71339, 9], [71340, 71340, 11],
    [71341, 71341, 9], [71342, 71343, 11], [71344, 71349, 9], [71350, 71350, 11],
    [71351, 71351, 8], [71352, 71452, 6], [71453, 71455, 9], [71456, 71457, 11],
    [71458, 71461, 9], [71462, 71462, 11], [71463, 71466, 9], [71467, 71467, 8],
    [71468, 71723, 6], [71724, 71726, 11], [71727, 71735, 9], [71736, 71736, 11],
    [71737, 71738, 8], [71739, 71983, 6], [71984, 71984, 9], [71985, 71989, 11],
    [71990, 71990, 6], [71991, 71992, 11], [71993, 71994, 6], [71995, 71996, 9],
    [71997, 71997, 11], [71998, 71998, 8], [71999, 71999, 10], [72000, 72000, 11],
    [72001, 72001, 10], [72002, 72002, 11], [72003, 72003, 8], [72004, 72144, 6],
    [72145, 72147, 11], [72148, 72151, 9], [72152, 72153, 6], [72154, 72155, 9],
    [72156, 72159, 11], [72160, 72160, 8], [72161, 72163, 6], [72164, 72164, 11],
    [72165, 72192, 6], [72193, 72202, 9], [72203, 72242, 6], [72243, 72243, 9],
    [72244, 72244, 8], [72245, 72248, 9], [72249, 72249, 11], [72250, 72250, 10],
    [72251, 72254, 9], [72255, 72262, 6], [72263, 72263, 8], [72264, 72272, 6],
    [72273, 72278, 9], [72279, 72280, 11], [72281, 72283, 9], [72284, 72323, 6],
    [72324, 72329, 10], [72330, 72342, 9], [72343, 72343, 11], [72344, 72344, 9],
    [72345, 72345, 8], [72346, 72750, 6], [72751, 72751, 11], [72752, 72758, 9],
    [72759, 72759, 6], [72760, 72765, 9], [72766, 72766, 11], [72767, 72767, 8],
    [72768, 72849, 6], [72850, 72871, 9], [72872, 72872, 6], [72873, 72873, 11],
    [72874, 72880, 9], [72881, 72881, 11], [72882, 72883, 9], [72884, 72884, 11],
    [72885, 72886, 9], [72887, 73008, 6], [73009, 73014, 9], [73015, 73017, 6],
    [73018, 73018, 9], [73019, 73019, 6], [73020, 73021, 9], [73022, 73022, 6],
    [73023, 73025, 9], [73026, 73026, 8], [73027, 73027, 9], [73028, 73029, 8],
    [73030, 73030, 10], [73031, 73031, 9], [73032, 73097, 6], [73098, 73102, 11],
    [73103, 73103, 6], [73104, 73105, 9], [73106, 73106, 6], [73107, 73108, 11],
    [73109, 73109, 9], [73110, 73110, 11], [73111, 73111, 8], [73112, 73458, 6],
    [73459, 73460, 9], [73461, 73462, 11], [73463, 78895, 6], [78896, 78904, 3],
    [78905, 92911, 6], [92912, 92916, 8], [92917, 92975, 6], [92976, 92982, 8],
    [92983, 94030, 6], [94031, 94031, 9], [94032, 94032, 6], [94033, 94087, 11],
    [94088, 94094, 6], [94095, 94098, 9], [94099, 94179, 6], [94180, 94180, 9],
    [94181, 94191, 6], [94192, 94193, 11], [94194, 113820, 6], [113821, 113821, 9],
    [113822, 113822, 8], [113823, 113823, 6], [113824, 113827, 3], [113828, 119140, 6],
    [119141, 119141, 8], [119142, 119142, 11], [119143, 119145, 8], [119146, 119148, 6],
    [119149, 119149, 11], [119150, 119154, 8], [119155, 119162, 3], [119163, 119170, 8],
    [119171, 119172, 6], [119173, 119179, 8], [119180, 119209, 6], [119210, 119213, 8],
    [119214, 119361, 6], [119362, 119364, 8], [119365, 121343, 6], [121344, 121398, 9],
    [121399, 121402, 6], [121403, 121452, 9], [121453, 121460, 6], [121461, 121461, 9],
    [121462, 121475, 6], [121476, 121476, 9], [121477, 121498, 6], [121499, 121503, 9],
    [121504, 121504, 6], [121505, 121519, 9], [121520, 122879, 6], [122880, 122886, 8],
    [122887, 122887, 6], [122888, 122904, 8], [122905, 122906, 6], [122907, 122913, 8],
    [122914, 122914, 6], [122915, 122916, 8], [122917, 122917, 6], [122918, 122922, 8],
    [122923, 123183, 6], [123184, 123190, 8], [123191, 123627, 6], [123628, 123631, 8],
    [123632, 125135, 6], [125136, 125142, 8], [125143, 125251, 6], [125252, 125258, 8],
    [125259, 126975, 6], [126976, 127231, 7], [127232, 127244, 6], [127245, 127247, 7],
    [127248, 127278, 6], [127279, 127279, 7], [127280, 127339, 6], [127340, 127345, 7],
    [127346, 127357, 6], [127358, 127359, 7], [127360, 127373, 6], [127374, 127374, 7],
    [127375, 127376, 6], [127377, 127386, 7], [127387, 127404, 6], [127405, 127461, 7],
    [127462, 127487, 20], [127488, 127488, 6], [127489, 127503, 7], [127504, 127513, 6],
    [127514, 127514, 7], [127515, 127534, 6], [127535, 127535, 7], [127536, 127537, 6],
    [127538, 127546, 7], [127547, 127547, 6], [127548, 127551, 7], [127552, 127560, 6],
    [127561, 127994, 7], [127995, 127999, 9], [128000, 128317, 7], [128318, 128325, 6],
    [128326, 128591, 7], [128592, 128639, 6], [128640, 128767, 7], [128768, 128883, 6],
    [128884, 128895, 7], [128896, 128980, 6], [128981, 129023, 7], [129024, 129035, 6],
    [129036, 129039, 7], [129040, 129095, 6], [129096, 129103, 7], [129104, 129113, 6],
    [129114, 129119, 7], [129120, 129159, 6], [129160, 129167, 7], [129168, 129197, 6],
    [129198, 129279, 7], [129280, 129291, 6], [129292, 129338, 7], [129339, 129339, 6],
    [129340, 129349, 7], [129350, 129350, 6], [129351, 129791, 7], [129792, 130047, 6],
    [130048, 131069, 7], [131070, 917503, 6], [917504, 917535, 3], [917536, 917631, 9],
    [917632, 917759, 3], [917760, 917999, 9], [918000, 921599, 3], [921600, 1114111, 6]
  ]`),

  metadata: {
    categoryCount: 21
  }
};
;RuleSet.line = {
  forwardTable: {
    flags: 0,
    table: JSON.parse(`[
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 4, 4, 5, 6, 7,
      8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 26,
      27, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 2,
      3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 0, 21,
      0, 0, 0, 7, 25, 26, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0,
      0, 0, 0, 0, 0, 3, 3, 4, 4, 5, 6, 7, 8, 0, 0, 0, 0, 13, 14, 33,
      16, 0, 18, 0, 0, 0, 0, 0, 0, 7, 25, 34, 3, 0, 7, 19, 0, 0, 0, 0,
      0, 0, 0, 0, -1, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 2, 0, 0, 0, 0, 0, 0, 4,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0,
      0, 0, 0, 0, 0, 4, 4, 5, 6, 7, 0, 0, 0, 0, 0, 13, 35, 0, 16, 0,
      18, 0, 0, 0, 0, 0, 0, 0, 25, 0, 0, 0, 0, 19, 0, 0, 0, 0, 0, 0,
      0, 0, -1, 0, 0, 0, 0, 0, 0, 7, 3, 4, 4, 5, 6, 7, 8, 0, 0, 0,
      0, 13, 14, 33, 16, 0, 18, 19, 0, 0, 0, 0, 0, 7, 25, 36, 3, 0, 7, 19,
      0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 8, 3, 4, 4, 5,
      37, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7,
      25, 8, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0,
      0, 9, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19,
      0, 21, 0, 0, 0, 7, 25, 38, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0,
      -1, 0, 0, 0, 0, 0, 0, 10, 3, 4, 4, 5, 6, 7, 8, 9, 0, 0, 39, 13,
      14, 33, 16, 17, 18, 19, 0, 21, 22, 23, 24, 7, 25, 40, 3, 0, 7, 19, 29, 39,
      30, 23, 24, 0, 0, 29, -1, 0, 0, 0, 0, 0, 0, 11, 3, 4, 4, 5, 6, 7,
      8, 9, 0, 0, 39, 13, 14, 33, 16, 17, 18, 19, 0, 21, 0, 0, 0, 7, 25, 41,
      3, 0, 7, 19, 0, 39, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 12,
      3, 4, 4, 5, 42, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 7, 25, 12, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0,
      0, 0, 0, 0, 0, 13, 3, 4, 4, 5, 43, 7, 8, 9, 0, 0, 0, 13, 14, 33,
      16, 17, 18, 19, 0, 21, 0, 0, 0, 7, 25, 44, 3, 0, 7, 19, 0, 0, 0, 0,
      0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 14, 3, 4, 4, 5, 6, 7, 8, 9,
      0, 0, 0, 13, 14, 33, 16, 17, 18, 19, 0, 21, 0, 0, 0, 7, 25, 45, 3, 0,
      7, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 15, 3, 4,
      4, 5, 6, 7, 8, 9, 0, 0, 0, 13, 14, 33, 16, 17, 18, 0, 0, 0, 0, 0,
      0, 7, 25, 46, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0,
      0, 0, 0, 16, 3, 4, 4, 5, 6, 7, 8, 0, 0, 0, 0, 13, 14, 33, 16, 0,
      18, 19, 0, 21, 0, 0, 0, 7, 25, 47, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0,
      0, 0, -1, 0, 0, 0, 0, 0, 0, 17, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 48, 49, 33, 50, 17, 51, 19, 0, 21, 0, 0, 0, 7, 25, 52, 3, 0, 7, 19,
      0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 18, 3, 4, 4, 5,
      43, 7, 8, 0, 0, 0, 0, 13, 14, 33, 16, 0, 18, 19, 0, 0, 0, 0, 0, 7,
      25, 53, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0,
      0, 19, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19,
      20, 21, 22, 23, 24, 7, 25, 19, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29,
      -1, 0, 0, 0, 0, 0, 0, 20, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
      14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 54, 3, 28, 7, 19, 29, 12,
      30, 23, 24, 0, 32, 29, -1, 0, 0, 0, 0, 0, 0, 21, 55, 4, 4, 5, 6, 7,
      8, 9, 10, 11, 12, 13, 14, 56, 16, 17, 18, 19, 0, 21, 0, 0, 0, 7, 25, 57,
      55, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 22,
      3, 4, 4, 5, 6, 7, 8, 0, 0, 11, 0, 13, 14, 33, 16, 0, 18, 19, 0, 0,
      22, 23, 0, 7, 25, 58, 3, 0, 7, 19, 0, 0, 0, 23, 24, 0, 0, 0, -1, 0,
      0, 0, 0, 0, 0, 23, 3, 4, 4, 5, 6, 7, 8, 0, 0, 11, 0, 13, 14, 33,
      16, 0, 18, 19, 0, 0, 0, 23, 24, 7, 25, 59, 3, 0, 7, 19, 0, 0, 0, 0,
      0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 24, 3, 4, 4, 5, 6, 7, 8, 0,
      0, 11, 0, 13, 14, 33, 16, 0, 18, 19, 0, 0, 0, 0, 24, 7, 25, 60, 3, 0,
      7, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 1, 0, 0, 0, 0, 0, 61, 61, 4,
      4, 5, 62, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61,
      61, 61, 25, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, 61, -1, 0, 0, 0,
      0, 0, 0, 2, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17,
      18, 19, 20, 21, 22, 23, 24, 7, 25, 26, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31,
      32, 29, -1, 0, 0, 0, 0, 0, 0, 27, 3, 4, 4, 5, 6, 7, 8, 9, 0, 0,
      0, 13, 14, 33, 16, 0, 18, 0, 0, 0, 0, 0, 0, 7, 25, 63, 3, 0, 7, 19,
      0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 28, 3, 4, 4, 5,
      64, 7, 8, 0, 0, 0, 0, 13, 14, 33, 16, 0, 18, 19, 0, 0, 0, 0, 0, 7,
      25, 65, 3, 28, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0,
      0, 29, 3, 4, 4, 5, 6, 7, 8, 0, 0, 11, 0, 13, 14, 33, 16, 0, 18, 19,
      0, 0, 0, 0, 0, 7, 25, 66, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0,
      -1, 0, 0, 0, 0, 0, 0, 30, 3, 4, 4, 5, 6, 7, 8, 0, 0, 11, 0, 13,
      14, 33, 16, 0, 18, 19, 0, 0, 0, 0, 0, 7, 25, 67, 3, 0, 7, 19, 0, 0,
      0, 0, 0, 0, 0, 29, -1, 0, 0, 0, 0, 0, 0, 31, 0, 4, 4, 5, 6, 7,
      8, 0, 0, 0, 0, 13, 14, 0, 16, 0, 18, 19, 0, 0, 0, 0, 0, 0, 25, 68,
      0, 0, 0, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 32,
      3, 4, 4, 5, 6, 7, 8, 0, 0, 0, 0, 13, 14, 33, 16, 0, 18, 19, 0, 0,
      0, 0, 0, 7, 25, 69, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 70, 0, -1, 0,
      0, 0, 0, 0, 0, 33, 3, 4, 4, 5, 6, 7, 8, 0, 0, 0, 0, 13, 14, 33,
      16, 17, 18, 0, 0, 0, 0, 0, 0, 7, 25, 71, 3, 0, 7, 19, 0, 0, 0, 0,
      0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 3, 3, 4, 4, 5, 6, 7, 8, 9,
      10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 34, 3, 28,
      7, 19, 29, 12, 30, 23, 24, 31, 32, 29, 0, 2, 0, 0, 0, 72, 0, 73, 3, 4,
      4, 5, 6, 7, 8, 9, 74, 74, 74, 13, 14, 33, 16, 0, 18, 19, 74, 21, 74, 74,
      74, 7, 25, 75, 3, 74, 7, 19, 74, 74, 74, 74, 74, 74, 74, 74, -1, 0, 0, 0,
      0, 0, 0, 7, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17,
      18, 19, 20, 21, 22, 23, 24, 7, 25, 36, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31,
      32, 29, -1, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 5, 37, 7, 0, 0, 0, 0,
      12, 13, 35, 0, 16, 0, 18, 0, 0, 0, 0, 0, 0, 0, 25, 0, 0, 0, 0, 19,
      0, 12, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 9, 3, 4, 4, 5,
      6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7,
      25, 38, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, 0, 0, 0, 0, 0, 0,
      0, 39, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 76, 0, 0, 17, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 39, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      -1, 0, 0, 0, 0, 0, 0, 10, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
      14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 40, 3, 28, 7, 19, 29, 12,
      30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 11, 3, 4, 4, 5, 6, 7,
      8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 41,
      3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 77,
      3, 4, 4, 5, 42, 7, 8, 9, 10, 11, 12, 13, 78, 33, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 7, 25, 79, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0,
      0, 0, 0, 0, 0, 0, 0, 4, 4, 5, 43, 7, 0, 0, 0, 0, 0, 13, 35, 0,
      16, 0, 18, 0, 0, 0, 0, 0, 0, 7, 25, 0, 0, 0, 0, 19, 0, 0, 0, 0,
      0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 13, 3, 4, 4, 5, 43, 7, 8, 9,
      10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 44, 3, 28,
      7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 14, 3, 4,
      4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23,
      24, 7, 25, 45, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0,
      0, 0, 0, 15, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17,
      18, 19, 20, 21, 22, 23, 24, 7, 25, 46, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31,
      32, 29, -1, 0, 0, 0, 0, 0, 0, 16, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 47, 3, 28, 7, 19,
      29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 48, 3, 4, 4, 5,
      43, 7, 8, 9, 10, 11, 0, 13, 14, 33, 16, 17, 18, 19, 0, 21, 0, 0, 0, 7,
      25, 80, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0,
      0, 49, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 0, 48, 49, 33, 50, 17, 51, 19,
      0, 21, 0, 0, 0, 7, 25, 81, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0,
      -1, 0, 0, 0, 0, 0, 0, 50, 3, 4, 4, 5, 6, 7, 8, 0, 10, 11, 0, 48,
      49, 33, 50, 17, 51, 19, 0, 21, 0, 0, 0, 7, 25, 82, 3, 0, 7, 19, 0, 0,
      0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 51, 3, 4, 4, 5, 43, 7,
      8, 0, 10, 11, 0, 13, 14, 33, 16, 0, 18, 19, 0, 0, 0, 0, 0, 7, 25, 83,
      3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 17,
      3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 48, 49, 33, 50, 17, 51, 19, 20, 21,
      22, 23, 24, 7, 25, 52, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0,
      0, 0, 0, 0, 0, 18, 3, 4, 4, 5, 43, 7, 8, 9, 10, 11, 12, 13, 14, 33,
      16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 53, 3, 28, 7, 19, 29, 12, 30, 23,
      24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 20, 3, 4, 4, 5, 6, 7, 8, 9,
      10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 54, 3, 28,
      7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 55, 3, 4,
      4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23,
      24, 7, 25, 84, 3, 28, 7, 19, 29, 12, 30, 23, 24, 0, 32, 29, -1, 0, 0, 0,
      0, 0, 0, 56, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17,
      18, 19, 20, 21, 22, 23, 24, 7, 25, 85, 3, 28, 7, 19, 29, 12, 30, 23, 24, 0,
      32, 29, -1, 0, 0, 0, 0, 0, 0, 21, 55, 4, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 13, 14, 56, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 57, 55, 28, 7, 19,
      29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 22, 3, 4, 4, 5,
      6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7,
      25, 58, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0,
      0, 23, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19,
      20, 21, 22, 23, 24, 7, 25, 59, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29,
      -1, 0, 0, 0, 0, 0, 0, 24, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
      14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 60, 3, 28, 7, 19, 29, 12,
      30, 23, 24, 31, 32, 29, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 1, 0, 0, 0, 0, 0, 61,
      61, 4, 4, 5, 62, 86, 61, 61, 61, 61, 61, 87, 88, 61, 89, 61, 90, 61, 61, 61,
      61, 61, 61, 61, 25, 61, 61, 61, 61, 91, 61, 61, 61, 61, 61, 61, 61, 61, -1, 0,
      0, 0, 0, 0, 0, 27, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33,
      16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 63, 3, 28, 7, 19, 29, 12, 30, 23,
      24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 5, 64, 7, 0, 0,
      0, 0, 0, 13, 35, 0, 16, 0, 18, 0, 0, 0, 0, 0, 0, 0, 25, 0, 0, 28,
      0, 19, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 28, 3, 4,
      4, 5, 64, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23,
      24, 7, 25, 65, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0,
      0, 0, 0, 29, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17,
      18, 19, 20, 21, 22, 23, 24, 7, 25, 66, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31,
      32, 29, -1, 0, 0, 0, 0, 0, 0, 30, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 67, 3, 28, 7, 19,
      29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 31, 3, 4, 4, 5,
      6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7,
      25, 68, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0,
      0, 32, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19,
      20, 21, 22, 23, 24, 7, 25, 69, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 92, 29,
      0, 3, 0, 0, 0, 72, 0, 93, 3, 4, 4, 5, 6, 7, 8, 94, 94, 94, 94, 13,
      14, 33, 16, 94, 18, 19, 94, 94, 94, 94, 94, 7, 25, 95, 3, 94, 7, 19, 94, 94,
      94, 94, 94, 94, 94, 94, -1, 0, 0, 0, 0, 0, 0, 33, 3, 4, 4, 5, 6, 7,
      8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 71,
      3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4,
      0, 0, 0, 72, 0, 73, 3, 4, 4, 5, 6, 7, 8, 9, 96, 96, 96, 13, 14, 33,
      16, 0, 18, 19, 96, 21, 96, 96, 96, 7, 25, 75, 3, 96, 7, 19, 96, 96, 96, 96,
      96, 96, 96, 96, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 72, 0, 73, 3, 4,
      4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 0, 18, 19, 20, 21, 22, 23,
      24, 7, 25, 75, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, 0, 0, 0, 0,
      0, 0, 0, 76, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 17,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 76, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, -1, 0, 0, 0, 0, 0, 0, 77, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 13, 14, 33, 16, 17, 18, 19, 0, 21, 0, 0, 0, 7, 25, 79, 3, 0, 7, 19,
      0, 0, 0, 0, 0, 0, 0, 0, -1, 2, 0, 0, 0, 72, 0, 97, 3, 4, 4, 5,
      6, 7, 8, 9, 74, 74, 74, 13, 14, 33, 16, 17, 18, 19, 74, 21, 74, 74, 74, 7,
      25, 98, 3, 74, 7, 19, 74, 74, 74, 74, 74, 74, 74, 74, -1, 0, 0, 0, 0, 0,
      0, 77, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19,
      20, 21, 22, 23, 24, 7, 25, 79, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29,
      -1, 0, 0, 0, 0, 0, 0, 48, 3, 4, 4, 5, 43, 7, 8, 9, 10, 11, 12, 13,
      14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 80, 3, 28, 7, 19, 29, 12,
      30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 49, 3, 4, 4, 5, 6, 7,
      8, 9, 10, 11, 12, 48, 49, 33, 50, 17, 51, 19, 20, 21, 22, 23, 24, 7, 25, 81,
      3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 50,
      3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 48, 49, 33, 50, 17, 51, 19, 20, 21,
      22, 23, 24, 7, 25, 82, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0,
      0, 0, 0, 0, 0, 51, 3, 4, 4, 5, 43, 7, 8, 9, 10, 11, 12, 13, 14, 33,
      16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 83, 3, 28, 7, 19, 29, 12, 30, 23,
      24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 55, 3, 4, 4, 5, 6, 7, 8, 9,
      10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 84, 3, 28,
      7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 0, 0, 0, 0, 0, 0, 56, 3, 4,
      4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23,
      24, 7, 25, 85, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, 1, 0, 0, 0,
      0, 0, 0, 7, 3, 4, 4, 5, 6, 7, 8, 0, 0, 0, 0, 13, 14, 33, 16, 0,
      18, 19, 0, 0, 0, 0, 0, 7, 25, 36, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0,
      0, 0, 1, 0, 0, 0, 0, 0, 0, 13, 3, 4, 4, 5, 43, 7, 8, 9, 0, 0,
      0, 13, 14, 33, 16, 17, 18, 19, 0, 21, 0, 0, 0, 7, 25, 44, 3, 0, 7, 19,
      0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 0, 72, 0, 73, 3, 4, 4, 5,
      6, 7, 8, 9, 74, 74, 74, 13, 14, 33, 16, 0, 18, 19, 74, 21, 74, 74, 74, 7,
      25, 75, 3, 74, 7, 19, 74, 74, 74, 74, 74, 74, 74, 74, 1, 0, 0, 0, 0, 0,
      0, 16, 3, 4, 4, 5, 6, 7, 8, 0, 0, 0, 0, 13, 14, 33, 16, 0, 18, 19,
      0, 21, 0, 0, 0, 7, 25, 47, 3, 0, 7, 19, 0, 0, 0, 0, 0, 0, 0, 0,
      1, 0, 0, 0, 0, 0, 0, 18, 3, 4, 4, 5, 43, 7, 8, 0, 0, 0, 0, 13,
      14, 33, 16, 0, 18, 19, 0, 0, 0, 0, 0, 7, 25, 53, 3, 0, 7, 19, 0, 0,
      0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 19, 3, 4, 4, 5, 6, 7,
      8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 19,
      3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 3, 0, 0, 0, 72, 0, 99,
      3, 4, 4, 5, 6, 7, 8, 94, 94, 94, 94, 13, 14, 33, 16, 94, 18, 19, 94, 94,
      94, 94, 94, 7, 25, 100, 3, 94, 7, 19, 94, 94, 94, 94, 94, 94, 101, 94, 0, 5,
      0, 0, 0, 72, 0, 93, 3, 4, 4, 5, 6, 7, 8, 102, 102, 102, 102, 13, 14, 33,
      16, 102, 18, 19, 102, 102, 102, 102, 102, 7, 25, 95, 3, 102, 7, 19, 102, 102, 102, 102,
      102, 102, 102, 102, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 72, 0, 93, 3, 4,
      4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23,
      24, 7, 25, 95, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, 4, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, -1, 4, 0, 0, 0, 72, 0, 97, 3, 4, 4, 5, 6, 7, 8, 9, 96, 96,
      96, 13, 14, 33, 16, 17, 18, 19, 96, 21, 96, 96, 96, 7, 25, 98, 3, 96, 7, 19,
      96, 96, 96, 96, 96, 96, 96, 96, -1, 0, 0, 0, 0, 72, 0, 97, 3, 4, 4, 5,
      6, 7, 8, 9, 10, 11, 12, 13, 14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7,
      25, 98, 3, 28, 7, 19, 29, 12, 30, 23, 24, 31, 32, 29, -1, 5, 0, 0, 0, 72,
      0, 99, 3, 4, 4, 5, 6, 7, 8, 102, 102, 102, 102, 13, 14, 33, 16, 102, 18, 19,
      102, 102, 102, 102, 102, 7, 25, 100, 3, 102, 7, 19, 102, 102, 102, 102, 102, 102, 103, 102,
      -1, 0, 0, 0, 0, 72, 0, 99, 3, 4, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
      14, 33, 16, 17, 18, 19, 20, 21, 22, 23, 24, 7, 25, 100, 3, 28, 7, 19, 29, 12,
      30, 23, 24, 31, 92, 29, 3, 3, 0, 0, 0, 72, 0, 93, 3, 4, 4, 5, 6, 7,
      8, 94, 94, 94, 94, 13, 14, 33, 16, 94, 18, 19, 94, 94, 94, 94, 94, 7, 25, 95,
      3, 94, 7, 19, 94, 94, 94, 94, 94, 94, 94, 94, 5, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 3,
      0, 0, 0, 72, 0, 93, 3, 4, 4, 5, 6, 7, 8, 94, 94, 94, 94, 13, 14, 33,
      16, 94, 18, 19, 94, 94, 94, 94, 94, 7, 25, 95, 3, 94, 7, 19, 94, 94, 94, 94,
      94, 94, 94, 94
    ]`)
  },

  categoryTable: JSON.parse(`[
    [0, 8, 3], [9, 9, 4], [10, 10, 5], [11, 12, 6],
    [13, 13, 7], [14, 31, 3], [32, 32, 8], [33, 33, 9],
    [34, 34, 10], [35, 35, 11], [36, 36, 12], [37, 37, 13],
    [38, 38, 11], [39, 39, 10], [40, 40, 14], [41, 41, 15],
    [42, 42, 11], [43, 43, 12], [44, 44, 16], [45, 45, 17],
    [46, 46, 16], [47, 47, 18], [48, 57, 19], [58, 59, 16],
    [60, 62, 11], [63, 63, 9], [64, 90, 11], [91, 91, 14],
    [92, 92, 12], [93, 93, 15], [94, 122, 11], [123, 123, 14],
    [124, 124, 4], [125, 125, 20], [126, 126, 11], [127, 132, 3],
    [133, 133, 6], [134, 159, 3], [160, 160, 21], [161, 161, 14],
    [162, 162, 13], [163, 165, 12], [166, 170, 11], [171, 171, 10],
    [172, 172, 11], [173, 173, 4], [174, 175, 11], [176, 176, 13],
    [177, 177, 12], [178, 179, 11], [180, 180, 22], [181, 186, 11],
    [187, 187, 10], [188, 190, 11], [191, 191, 14], [192, 711, 11],
    [712, 712, 22], [713, 715, 11], [716, 716, 22], [717, 734, 11],
    [735, 735, 22], [736, 767, 11], [768, 846, 3], [847, 847, 21],
    [848, 859, 3], [860, 866, 21], [867, 879, 3], [880, 893, 11],
    [894, 894, 16], [895, 1154, 11], [1155, 1161, 3], [1162, 1416, 11],
    [1417, 1417, 16], [1418, 1418, 4], [1419, 1422, 11], [1423, 1423, 12],
    [1424, 1424, 11], [1425, 1469, 3], [1470, 1470, 4], [1471, 1471, 3],
    [1472, 1472, 11], [1473, 1474, 3], [1475, 1475, 11], [1476, 1477, 3],
    [1478, 1478, 9], [1479, 1479, 3], [1480, 1487, 11], [1488, 1514, 23],
    [1515, 1518, 11], [1519, 1522, 23], [1523, 1544, 11], [1545, 1547, 13],
    [1548, 1549, 16], [1550, 1551, 11], [1552, 1562, 3], [1563, 1563, 9],
    [1564, 1564, 3], [1565, 1565, 11], [1566, 1567, 9], [1568, 1610, 11],
    [1611, 1631, 3], [1632, 1641, 19], [1642, 1642, 13], [1643, 1644, 19],
    [1645, 1647, 11], [1648, 1648, 3], [1649, 1747, 11], [1748, 1748, 9],
    [1749, 1749, 11], [1750, 1756, 3], [1757, 1758, 11], [1759, 1764, 3],
    [1765, 1766, 11], [1767, 1768, 3], [1769, 1769, 11], [1770, 1773, 3],
    [1774, 1775, 11], [1776, 1785, 19], [1786, 1808, 11], [1809, 1809, 3],
    [1810, 1839, 11], [1840, 1866, 3], [1867, 1957, 11], [1958, 1968, 3],
    [1969, 1983, 11], [1984, 1993, 19], [1994, 2026, 11], [2027, 2035, 3],
    [2036, 2039, 11], [2040, 2040, 16], [2041, 2041, 9], [2042, 2044, 11],
    [2045, 2045, 3], [2046, 2047, 12], [2048, 2069, 11], [2070, 2073, 3],
    [2074, 2074, 11], [2075, 2083, 3], [2084, 2084, 11], [2085, 2087, 3],
    [2088, 2088, 11], [2089, 2093, 3], [2094, 2136, 11], [2137, 2139, 3],
    [2140, 2258, 11], [2259, 2273, 3], [2274, 2274, 11], [2275, 2307, 3],
    [2308, 2361, 11], [2362, 2364, 3], [2365, 2365, 11], [2366, 2383, 3],
    [2384, 2384, 11], [2385, 2391, 3], [2392, 2401, 11], [2402, 2403, 3],
    [2404, 2405, 4], [2406, 2415, 19], [2416, 2432, 11], [2433, 2435, 3],
    [2436, 2491, 11], [2492, 2492, 3], [2493, 2493, 11], [2494, 2500, 3],
    [2501, 2502, 11], [2503, 2504, 3], [2505, 2506, 11], [2507, 2509, 3],
    [2510, 2518, 11], [2519, 2519, 3], [2520, 2529, 11], [2530, 2531, 3],
    [2532, 2533, 11], [2534, 2543, 19], [2544, 2545, 11], [2546, 2547, 13],
    [2548, 2552, 11], [2553, 2553, 13], [2554, 2554, 11], [2555, 2555, 12],
    [2556, 2557, 11], [2558, 2558, 3], [2559, 2560, 11], [2561, 2563, 3],
    [2564, 2619, 11], [2620, 2620, 3], [2621, 2621, 11], [2622, 2626, 3],
    [2627, 2630, 11], [2631, 2632, 3], [2633, 2634, 11], [2635, 2637, 3],
    [2638, 2640, 11], [2641, 2641, 3], [2642, 2661, 11], [2662, 2671, 19],
    [2672, 2673, 3], [2674, 2676, 11], [2677, 2677, 3], [2678, 2688, 11],
    [2689, 2691, 3], [2692, 2747, 11], [2748, 2748, 3], [2749, 2749, 11],
    [2750, 2757, 3], [2758, 2758, 11], [2759, 2761, 3], [2762, 2762, 11],
    [2763, 2765, 3], [2766, 2785, 11], [2786, 2787, 3], [2788, 2789, 11],
    [2790, 2799, 19], [2800, 2800, 11], [2801, 2801, 12], [2802, 2809, 11],
    [2810, 2815, 3], [2816, 2816, 11], [2817, 2819, 3], [2820, 2875, 11],
    [2876, 2876, 3], [2877, 2877, 11], [2878, 2884, 3], [2885, 2886, 11],
    [2887, 2888, 3], [2889, 2890, 11], [2891, 2893, 3], [2894, 2900, 11],
    [2901, 2903, 3], [2904, 2913, 11], [2914, 2915, 3], [2916, 2917, 11],
    [2918, 2927, 19], [2928, 2945, 11], [2946, 2946, 3], [2947, 3005, 11],
    [3006, 3010, 3], [3011, 3013, 11], [3014, 3016, 3], [3017, 3017, 11],
    [3018, 3021, 3], [3022, 3030, 11], [3031, 3031, 3], [3032, 3045, 11],
    [3046, 3055, 19], [3056, 3064, 11], [3065, 3065, 12], [3066, 3071, 11],
    [3072, 3076, 3], [3077, 3133, 11], [3134, 3140, 3], [3141, 3141, 11],
    [3142, 3144, 3], [3145, 3145, 11], [3146, 3149, 3], [3150, 3156, 11],
    [3157, 3158, 3], [3159, 3169, 11], [3170, 3171, 3], [3172, 3173, 11],
    [3174, 3183, 19], [3184, 3190, 11], [3191, 3191, 22], [3192, 3200, 11],
    [3201, 3203, 3], [3204, 3204, 22], [3205, 3259, 11], [3260, 3260, 3],
    [3261, 3261, 11], [3262, 3268, 3], [3269, 3269, 11], [3270, 3272, 3],
    [3273, 3273, 11], [3274, 3277, 3], [3278, 3284, 11], [3285, 3286, 3],
    [3287, 3297, 11], [3298, 3299, 3], [3300, 3301, 11], [3302, 3311, 19],
    [3312, 3327, 11], [3328, 3331, 3], [3332, 3386, 11], [3387, 3388, 3],
    [3389, 3389, 11], [3390, 3396, 3], [3397, 3397, 11], [3398, 3400, 3],
    [3401, 3401, 11], [3402, 3405, 3], [3406, 3414, 11], [3415, 3415, 3],
    [3416, 3425, 11], [3426, 3427, 3], [3428, 3429, 11], [3430, 3439, 19],
    [3440, 3448, 11], [3449, 3449, 13], [3450, 3456, 11], [3457, 3459, 3],
    [3460, 3529, 11], [3530, 3530, 3], [3531, 3534, 11], [3535, 3540, 3],
    [3541, 3541, 11], [3542, 3542, 3], [3543, 3543, 11], [3544, 3551, 3],
    [3552, 3557, 11], [3558, 3567, 19], [3568, 3569, 11], [3570, 3571, 3],
    [3572, 3584, 11], [3585, 3632, 16395], [3633, 3633, 16387], [3634, 3635, 16395],
    [3636, 3642, 16387], [3643, 3646, 11], [3647, 3647, 12], [3648, 3654, 16395],
    [3655, 3662, 16387], [3663, 3663, 11], [3664, 3673, 19], [3674, 3675, 4],
    [3676, 3712, 11], [3713, 3714, 16395], [3715, 3715, 11], [3716, 3716, 16395],
    [3717, 3717, 11], [3718, 3722, 16395], [3723, 3723, 11], [3724, 3747, 16395],
    [3748, 3748, 11], [3749, 3749, 16395], [3750, 3750, 11], [3751, 3760, 16395],
    [3761, 3761, 16387], [3762, 3763, 16395], [3764, 3772, 16387], [3773, 3773, 16395],
    [3774, 3775, 11], [3776, 3780, 16395], [3781, 3781, 11], [3782, 3782, 16395],
    [3783, 3783, 11], [3784, 3789, 16387], [3790, 3791, 11], [3792, 3801, 19],
    [3802, 3803, 11], [3804, 3807, 16395], [3808, 3840, 11], [3841, 3844, 22],
    [3845, 3845, 11], [3846, 3847, 22], [3848, 3848, 21], [3849, 3850, 22],
    [3851, 3851, 4], [3852, 3852, 21], [3853, 3857, 9], [3858, 3858, 21],
    [3859, 3859, 11], [3860, 3860, 9], [3861, 3863, 11], [3864, 3865, 3],
    [3866, 3871, 11], [3872, 3881, 19], [3882, 3891, 11], [3892, 3892, 4],
    [3893, 3893, 3], [3894, 3894, 11], [3895, 3895, 3], [3896, 3896, 11],
    [3897, 3897, 3], [3898, 3898, 14], [3899, 3899, 20], [3900, 3900, 14],
    [3901, 3901, 20], [3902, 3903, 3], [3904, 3952, 11], [3953, 3966, 3],
    [3967, 3967, 4], [3968, 3972, 3], [3973, 3973, 4], [3974, 3975, 3],
    [3976, 3980, 11], [3981, 3991, 3], [3992, 3992, 11], [3993, 4028, 3],
    [4029, 4029, 11], [4030, 4031, 4], [4032, 4037, 11], [4038, 4038, 3],
    [4039, 4047, 11], [4048, 4049, 22], [4050, 4050, 4], [4051, 4051, 22],
    [4052, 4056, 11], [4057, 4058, 21], [4059, 4095, 11], [4096, 4138, 16395],
    [4139, 4158, 16387], [4159, 4159, 16395], [4160, 4169, 19], [4170, 4171, 4],
    [4172, 4175, 11], [4176, 4181, 16395], [4182, 4185, 16387], [4186, 4189, 16395],
    [4190, 4192, 16387], [4193, 4193, 16395], [4194, 4196, 16387], [4197, 4198, 16395],
    [4199, 4205, 16387], [4206, 4208, 16395], [4209, 4212, 16387], [4213, 4225, 16395],
    [4226, 4237, 16387], [4238, 4238, 16395], [4239, 4239, 16387], [4240, 4249, 19],
    [4250, 4253, 16387], [4254, 4255, 16395], [4256, 4351, 11], [4352, 4447, 24],
    [4448, 4519, 25], [4520, 4607, 26], [4608, 4956, 11], [4957, 4959, 3],
    [4960, 4960, 11], [4961, 4961, 4], [4962, 5119, 11], [5120, 5120, 4],
    [5121, 5759, 11], [5760, 5760, 4], [5761, 5786, 11], [5787, 5787, 14],
    [5788, 5788, 20], [5789, 5866, 11], [5867, 5869, 4], [5870, 5905, 11],
    [5906, 5908, 3], [5909, 5937, 11], [5938, 5940, 3], [5941, 5942, 4],
    [5943, 5969, 11], [5970, 5971, 3], [5972, 6001, 11], [6002, 6003, 3],
    [6004, 6015, 11], [6016, 6067, 16395], [6068, 6099, 16387], [6100, 6101, 4],
    [6102, 6102, 27], [6103, 6103, 16395], [6104, 6104, 4], [6105, 6105, 11],
    [6106, 6106, 4], [6107, 6107, 12], [6108, 6108, 16395], [6109, 6109, 16387],
    [6110, 6111, 11], [6112, 6121, 19], [6122, 6145, 11], [6146, 6147, 9],
    [6148, 6149, 4], [6150, 6150, 22], [6151, 6151, 11], [6152, 6153, 9],
    [6154, 6154, 11], [6155, 6157, 3], [6158, 6158, 21], [6159, 6159, 11],
    [6160, 6169, 19], [6170, 6276, 11], [6277, 6278, 3], [6279, 6312, 11],
    [6313, 6313, 3], [6314, 6431, 11], [6432, 6443, 3], [6444, 6447, 11],
    [6448, 6459, 3], [6460, 6467, 11], [6468, 6469, 9], [6470, 6479, 19],
    [6480, 6509, 16395], [6510, 6511, 11], [6512, 6516, 16395], [6517, 6527, 11],
    [6528, 6571, 16395], [6572, 6575, 11], [6576, 6601, 16395], [6602, 6607, 11],
    [6608, 6617, 19], [6618, 6618, 16395], [6619, 6621, 11], [6622, 6623, 16395],
    [6624, 6678, 11], [6679, 6683, 3], [6684, 6687, 11], [6688, 6740, 16395],
    [6741, 6750, 16387], [6751, 6751, 11], [6752, 6780, 16387], [6781, 6782, 11],
    [6783, 6783, 3], [6784, 6793, 19], [6794, 6799, 11], [6800, 6809, 19],
    [6810, 6815, 11], [6816, 6829, 16395], [6830, 6831, 11], [6832, 6848, 3],
    [6849, 6911, 11], [6912, 6916, 3], [6917, 6963, 11], [6964, 6980, 3],
    [6981, 6991, 11], [6992, 7001, 19], [7002, 7003, 4], [7004, 7004, 11],
    [7005, 7008, 4], [7009, 7018, 11], [7019, 7027, 3], [7028, 7039, 11],
    [7040, 7042, 3], [7043, 7072, 11], [7073, 7085, 3], [7086, 7087, 11],
    [7088, 7097, 19], [7098, 7141, 11], [7142, 7155, 3], [7156, 7203, 11],
    [7204, 7223, 3], [7224, 7226, 11], [7227, 7231, 4], [7232, 7241, 19],
    [7242, 7247, 11], [7248, 7257, 19], [7258, 7293, 11], [7294, 7295, 4],
    [7296, 7375, 11], [7376, 7378, 3], [7379, 7379, 11], [7380, 7400, 3],
    [7401, 7404, 11], [7405, 7405, 3], [7406, 7411, 11], [7412, 7412, 3],
    [7413, 7414, 11], [7415, 7417, 3], [7418, 7615, 11], [7616, 7673, 3],
    [7674, 7674, 11], [7675, 7679, 3], [7680, 8188, 11], [8189, 8189, 22],
    [8190, 8191, 11], [8192, 8198, 4], [8199, 8199, 21], [8200, 8202, 4],
    [8203, 8203, 28], [8204, 8204, 3], [8205, 8205, 29], [8206, 8207, 3],
    [8208, 8208, 30], [8209, 8209, 21], [8210, 8211, 4], [8212, 8212, 31],
    [8213, 8215, 11], [8216, 8217, 10], [8218, 8218, 14], [8219, 8219, 10],
    [8220, 8220, 14], [8221, 8221, 20], [8222, 8222, 14], [8223, 8223, 10],
    [8224, 8227, 11], [8228, 8230, 32], [8231, 8231, 4], [8232, 8233, 6],
    [8234, 8238, 3], [8239, 8239, 21], [8240, 8247, 13], [8248, 8248, 11],
    [8249, 8250, 10], [8251, 8251, 11], [8252, 8253, 27], [8254, 8259, 11],
    [8260, 8260, 16], [8261, 8261, 14], [8262, 8262, 20], [8263, 8265, 27],
    [8266, 8277, 11], [8278, 8278, 4], [8279, 8279, 11], [8280, 8283, 4],
    [8284, 8284, 11], [8285, 8287, 4], [8288, 8288, 33], [8289, 8293, 11],
    [8294, 8303, 3], [8304, 8316, 11], [8317, 8317, 14], [8318, 8318, 20],
    [8319, 8332, 11], [8333, 8333, 14], [8334, 8334, 20], [8335, 8351, 11],
    [8352, 8358, 12], [8359, 8359, 13], [8360, 8373, 12], [8374, 8374, 13],
    [8375, 8378, 12], [8379, 8379, 13], [8380, 8381, 12], [8382, 8382, 13],
    [8383, 8399, 12], [8400, 8432, 3], [8433, 8450, 11], [8451, 8451, 13],
    [8452, 8456, 11], [8457, 8457, 13], [8458, 8469, 11], [8470, 8470, 12],
    [8471, 8721, 11], [8722, 8723, 12], [8724, 8942, 11], [8943, 8943, 32],
    [8944, 8967, 11], [8968, 8968, 14], [8969, 8969, 20], [8970, 8970, 14],
    [8971, 8971, 20], [8972, 8985, 11], [8986, 8987, 34], [8988, 9000, 11],
    [9001, 9001, 35], [9002, 9002, 20], [9003, 9199, 11], [9200, 9203, 34],
    [9204, 9727, 11], [9728, 9731, 34], [9732, 9747, 11], [9748, 9749, 34],
    [9750, 9751, 11], [9752, 9752, 34], [9753, 9753, 11], [9754, 9756, 34],
    [9757, 9757, 36], [9758, 9759, 34], [9760, 9784, 11], [9785, 9787, 34],
    [9788, 9831, 11], [9832, 9832, 34], [9833, 9854, 11], [9855, 9855, 34],
    [9856, 9916, 11], [9917, 9928, 34], [9929, 9932, 11], [9933, 9933, 34],
    [9934, 9934, 11], [9935, 9937, 34], [9938, 9938, 11], [9939, 9940, 34],
    [9941, 9943, 11], [9944, 9945, 34], [9946, 9947, 11], [9948, 9948, 34],
    [9949, 9950, 11], [9951, 9953, 34], [9954, 9961, 11], [9962, 9962, 34],
    [9963, 9968, 11], [9969, 9973, 34], [9974, 9974, 11], [9975, 9976, 34],
    [9977, 9977, 36], [9978, 9978, 34], [9979, 9980, 11], [9981, 9988, 34],
    [9989, 9991, 11], [9992, 9993, 34], [9994, 9997, 36], [9998, 10074, 11],
    [10075, 10080, 10], [10081, 10081, 11], [10082, 10083, 9], [10084, 10084, 34],
    [10085, 10087, 11], [10088, 10088, 14], [10089, 10089, 20], [10090, 10090, 14],
    [10091, 10091, 20], [10092, 10092, 14], [10093, 10093, 20], [10094, 10094, 14],
    [10095, 10095, 20], [10096, 10096, 14], [10097, 10097, 20], [10098, 10098, 14],
    [10099, 10099, 20], [10100, 10100, 14], [10101, 10101, 20], [10102, 10180, 11],
    [10181, 10181, 14], [10182, 10182, 20], [10183, 10213, 11], [10214, 10214, 14],
    [10215, 10215, 20], [10216, 10216, 14], [10217, 10217, 20], [10218, 10218, 14],
    [10219, 10219, 20], [10220, 10220, 14], [10221, 10221, 20], [10222, 10222, 14],
    [10223, 10223, 20], [10224, 10626, 11], [10627, 10627, 14], [10628, 10628, 20],
    [10629, 10629, 14], [10630, 10630, 20], [10631, 10631, 14], [10632, 10632, 20],
    [10633, 10633, 14], [10634, 10634, 20], [10635, 10635, 14], [10636, 10636, 20],
    [10637, 10637, 14], [10638, 10638, 20], [10639, 10639, 14], [10640, 10640, 20],
    [10641, 10641, 14], [10642, 10642, 20], [10643, 10643, 14], [10644, 10644, 20],
    [10645, 10645, 14], [10646, 10646, 20], [10647, 10647, 14], [10648, 10648, 20],
    [10649, 10711, 11], [10712, 10712, 14], [10713, 10713, 20], [10714, 10714, 14],
    [10715, 10715, 20], [10716, 10747, 11], [10748, 10748, 14], [10749, 10749, 20],
    [10750, 11502, 11], [11503, 11505, 3], [11506, 11512, 11], [11513, 11513, 9],
    [11514, 11516, 4], [11517, 11517, 11], [11518, 11518, 9], [11519, 11519, 4],
    [11520, 11631, 11], [11632, 11632, 4], [11633, 11646, 11], [11647, 11647, 3],
    [11648, 11743, 11], [11744, 11775, 3], [11776, 11789, 10], [11790, 11797, 4],
    [11798, 11798, 11], [11799, 11799, 4], [11800, 11800, 14], [11801, 11801, 4],
    [11802, 11803, 11], [11804, 11805, 10], [11806, 11807, 11], [11808, 11809, 10],
    [11810, 11810, 14], [11811, 11811, 20], [11812, 11812, 14], [11813, 11813, 20],
    [11814, 11814, 14], [11815, 11815, 20], [11816, 11816, 14], [11817, 11817, 20],
    [11818, 11821, 4], [11822, 11822, 9], [11823, 11823, 11], [11824, 11825, 4],
    [11826, 11826, 11], [11827, 11828, 4], [11829, 11833, 11], [11834, 11835, 31],
    [11836, 11838, 4], [11839, 11839, 11], [11840, 11841, 4], [11842, 11842, 14],
    [11843, 11850, 4], [11851, 11851, 11], [11852, 11852, 4], [11853, 11853, 11],
    [11854, 11855, 4], [11856, 11903, 11], [11904, 11929, 34], [11930, 11930, 11],
    [11931, 12019, 34], [12020, 12031, 11], [12032, 12245, 34], [12246, 12271, 11],
    [12272, 12283, 34], [12284, 12287, 11], [12288, 12288, 4], [12289, 12290, 20],
    [12291, 12292, 34], [12293, 12293, 27], [12294, 12295, 34], [12296, 12296, 35],
    [12297, 12297, 20], [12298, 12298, 35], [12299, 12299, 20], [12300, 12300, 35],
    [12301, 12301, 20], [12302, 12302, 35], [12303, 12303, 20], [12304, 12304, 35],
    [12305, 12305, 20], [12306, 12307, 34], [12308, 12308, 35], [12309, 12309, 20],
    [12310, 12310, 35], [12311, 12311, 20], [12312, 12312, 35], [12313, 12313, 20],
    [12314, 12314, 35], [12315, 12315, 20], [12316, 12316, 27], [12317, 12317, 35],
    [12318, 12319, 20], [12320, 12329, 34], [12330, 12335, 3], [12336, 12340, 34],
    [12341, 12341, 3], [12342, 12346, 34], [12347, 12348, 27], [12349, 12351, 34],
    [12352, 12352, 11], [12353, 12353, 27], [12354, 12354, 34], [12355, 12355, 27],
    [12356, 12356, 34], [12357, 12357, 27], [12358, 12358, 34], [12359, 12359, 27],
    [12360, 12360, 34], [12361, 12361, 27], [12362, 12386, 34], [12387, 12387, 27],
    [12388, 12418, 34], [12419, 12419, 27], [12420, 12420, 34], [12421, 12421, 27],
    [12422, 12422, 34], [12423, 12423, 27], [12424, 12429, 34], [12430, 12430, 27],
    [12431, 12436, 34], [12437, 12438, 27], [12439, 12440, 11], [12441, 12442, 3],
    [12443, 12446, 27], [12447, 12447, 34], [12448, 12449, 27], [12450, 12450, 34],
    [12451, 12451, 27], [12452, 12452, 34], [12453, 12453, 27], [12454, 12454, 34],
    [12455, 12455, 27], [12456, 12456, 34], [12457, 12457, 27], [12458, 12482, 34],
    [12483, 12483, 27], [12484, 12514, 34], [12515, 12515, 27], [12516, 12516, 34],
    [12517, 12517, 27], [12518, 12518, 34], [12519, 12519, 27], [12520, 12525, 34],
    [12526, 12526, 27], [12527, 12532, 34], [12533, 12534, 27], [12535, 12538, 34],
    [12539, 12542, 27], [12543, 12543, 34], [12544, 12548, 11], [12549, 12591, 34],
    [12592, 12592, 11], [12593, 12686, 34], [12687, 12687, 11], [12688, 12771, 34],
    [12772, 12783, 11], [12784, 12799, 27], [12800, 12830, 34], [12831, 12831, 11],
    [12832, 12871, 34], [12872, 12879, 11], [12880, 19903, 34], [19904, 19967, 11],
    [19968, 40980, 34], [40981, 40981, 27], [40982, 42124, 34], [42125, 42127, 11],
    [42128, 42182, 34], [42183, 42237, 11], [42238, 42239, 4], [42240, 42508, 11],
    [42509, 42509, 4], [42510, 42510, 9], [42511, 42511, 4], [42512, 42527, 11],
    [42528, 42537, 19], [42538, 42606, 11], [42607, 42610, 3], [42611, 42611, 11],
    [42612, 42621, 3], [42622, 42653, 11], [42654, 42655, 3], [42656, 42735, 11],
    [42736, 42737, 3], [42738, 42738, 11], [42739, 42743, 4], [42744, 43009, 11],
    [43010, 43010, 3], [43011, 43013, 11], [43014, 43014, 3], [43015, 43018, 11],
    [43019, 43019, 3], [43020, 43042, 11], [43043, 43047, 3], [43048, 43051, 11],
    [43052, 43052, 3], [43053, 43063, 11], [43064, 43064, 13], [43065, 43123, 11],
    [43124, 43125, 22], [43126, 43127, 9], [43128, 43135, 11], [43136, 43137, 3],
    [43138, 43187, 11], [43188, 43205, 3], [43206, 43213, 11], [43214, 43215, 4],
    [43216, 43225, 19], [43226, 43231, 11], [43232, 43249, 3], [43250, 43259, 11],
    [43260, 43260, 22], [43261, 43262, 11], [43263, 43263, 3], [43264, 43273, 19],
    [43274, 43301, 11], [43302, 43309, 3], [43310, 43311, 4], [43312, 43334, 11],
    [43335, 43347, 3], [43348, 43359, 11], [43360, 43388, 24], [43389, 43391, 11],
    [43392, 43395, 3], [43396, 43442, 11], [43443, 43456, 3], [43457, 43462, 11],
    [43463, 43465, 4], [43466, 43471, 11], [43472, 43481, 19], [43482, 43487, 11],
    [43488, 43492, 16395], [43493, 43493, 16387], [43494, 43503, 16395], [43504, 43513, 19],
    [43514, 43518, 16395], [43519, 43560, 11], [43561, 43574, 3], [43575, 43586, 11],
    [43587, 43587, 3], [43588, 43595, 11], [43596, 43597, 3], [43598, 43599, 11],
    [43600, 43609, 19], [43610, 43612, 11], [43613, 43615, 4], [43616, 43642, 16395],
    [43643, 43645, 16387], [43646, 43695, 16395], [43696, 43696, 16387], [43697, 43697, 16395],
    [43698, 43700, 16387], [43701, 43702, 16395], [43703, 43704, 16387], [43705, 43709, 16395],
    [43710, 43711, 16387], [43712, 43712, 16395], [43713, 43713, 16387], [43714, 43714, 16395],
    [43715, 43738, 11], [43739, 43743, 16395], [43744, 43754, 11], [43755, 43759, 3],
    [43760, 43761, 4], [43762, 43764, 11], [43765, 43766, 3], [43767, 44002, 11],
    [44003, 44010, 3], [44011, 44011, 4], [44012, 44013, 3], [44014, 44015, 11],
    [44016, 44025, 19], [44026, 44031, 11], [44032, 44032, 37], [44033, 44059, 38],
    [44060, 44060, 37], [44061, 44087, 38], [44088, 44088, 37], [44089, 44115, 38],
    [44116, 44116, 37], [44117, 44143, 38], [44144, 44144, 37], [44145, 44171, 38],
    [44172, 44172, 37], [44173, 44199, 38], [44200, 44200, 37], [44201, 44227, 38],
    [44228, 44228, 37], [44229, 44255, 38], [44256, 44256, 37], [44257, 44283, 38],
    [44284, 44284, 37], [44285, 44311, 38], [44312, 44312, 37], [44313, 44339, 38],
    [44340, 44340, 37], [44341, 44367, 38], [44368, 44368, 37], [44369, 44395, 38],
    [44396, 44396, 37], [44397, 44423, 38], [44424, 44424, 37], [44425, 44451, 38],
    [44452, 44452, 37], [44453, 44479, 38], [44480, 44480, 37], [44481, 44507, 38],
    [44508, 44508, 37], [44509, 44535, 38], [44536, 44536, 37], [44537, 44563, 38],
    [44564, 44564, 37], [44565, 44591, 38], [44592, 44592, 37], [44593, 44619, 38],
    [44620, 44620, 37], [44621, 44647, 38], [44648, 44648, 37], [44649, 44675, 38],
    [44676, 44676, 37], [44677, 44703, 38], [44704, 44704, 37], [44705, 44731, 38],
    [44732, 44732, 37], [44733, 44759, 38], [44760, 44760, 37], [44761, 44787, 38],
    [44788, 44788, 37], [44789, 44815, 38], [44816, 44816, 37], [44817, 44843, 38],
    [44844, 44844, 37], [44845, 44871, 38], [44872, 44872, 37], [44873, 44899, 38],
    [44900, 44900, 37], [44901, 44927, 38], [44928, 44928, 37], [44929, 44955, 38],
    [44956, 44956, 37], [44957, 44983, 38], [44984, 44984, 37], [44985, 45011, 38],
    [45012, 45012, 37], [45013, 45039, 38], [45040, 45040, 37], [45041, 45067, 38],
    [45068, 45068, 37], [45069, 45095, 38], [45096, 45096, 37], [45097, 45123, 38],
    [45124, 45124, 37], [45125, 45151, 38], [45152, 45152, 37], [45153, 45179, 38],
    [45180, 45180, 37], [45181, 45207, 38], [45208, 45208, 37], [45209, 45235, 38],
    [45236, 45236, 37], [45237, 45263, 38], [45264, 45264, 37], [45265, 45291, 38],
    [45292, 45292, 37], [45293, 45319, 38], [45320, 45320, 37], [45321, 45347, 38],
    [45348, 45348, 37], [45349, 45375, 38], [45376, 45376, 37], [45377, 45403, 38],
    [45404, 45404, 37], [45405, 45431, 38], [45432, 45432, 37], [45433, 45459, 38],
    [45460, 45460, 37], [45461, 45487, 38], [45488, 45488, 37], [45489, 45515, 38],
    [45516, 45516, 37], [45517, 45543, 38], [45544, 45544, 37], [45545, 45571, 38],
    [45572, 45572, 37], [45573, 45599, 38], [45600, 45600, 37], [45601, 45627, 38],
    [45628, 45628, 37], [45629, 45655, 38], [45656, 45656, 37], [45657, 45683, 38],
    [45684, 45684, 37], [45685, 45711, 38], [45712, 45712, 37], [45713, 45739, 38],
    [45740, 45740, 37], [45741, 45767, 38], [45768, 45768, 37], [45769, 45795, 38],
    [45796, 45796, 37], [45797, 45823, 38], [45824, 45824, 37], [45825, 45851, 38],
    [45852, 45852, 37], [45853, 45879, 38], [45880, 45880, 37], [45881, 45907, 38],
    [45908, 45908, 37], [45909, 45935, 38], [45936, 45936, 37], [45937, 45963, 38],
    [45964, 45964, 37], [45965, 45991, 38], [45992, 45992, 37], [45993, 46019, 38],
    [46020, 46020, 37], [46021, 46047, 38], [46048, 46048, 37], [46049, 46075, 38],
    [46076, 46076, 37], [46077, 46103, 38], [46104, 46104, 37], [46105, 46131, 38],
    [46132, 46132, 37], [46133, 46159, 38], [46160, 46160, 37], [46161, 46187, 38],
    [46188, 46188, 37], [46189, 46215, 38], [46216, 46216, 37], [46217, 46243, 38],
    [46244, 46244, 37], [46245, 46271, 38], [46272, 46272, 37], [46273, 46299, 38],
    [46300, 46300, 37], [46301, 46327, 38], [46328, 46328, 37], [46329, 46355, 38],
    [46356, 46356, 37], [46357, 46383, 38], [46384, 46384, 37], [46385, 46411, 38],
    [46412, 46412, 37], [46413, 46439, 38], [46440, 46440, 37], [46441, 46467, 38],
    [46468, 46468, 37], [46469, 46495, 38], [46496, 46496, 37], [46497, 46523, 38],
    [46524, 46524, 37], [46525, 46551, 38], [46552, 46552, 37], [46553, 46579, 38],
    [46580, 46580, 37], [46581, 46607, 38], [46608, 46608, 37], [46609, 46635, 38],
    [46636, 46636, 37], [46637, 46663, 38], [46664, 46664, 37], [46665, 46691, 38],
    [46692, 46692, 37], [46693, 46719, 38], [46720, 46720, 37], [46721, 46747, 38],
    [46748, 46748, 37], [46749, 46775, 38], [46776, 46776, 37], [46777, 46803, 38],
    [46804, 46804, 37], [46805, 46831, 38], [46832, 46832, 37], [46833, 46859, 38],
    [46860, 46860, 37], [46861, 46887, 38], [46888, 46888, 37], [46889, 46915, 38],
    [46916, 46916, 37], [46917, 46943, 38], [46944, 46944, 37], [46945, 46971, 38],
    [46972, 46972, 37], [46973, 46999, 38], [47000, 47000, 37], [47001, 47027, 38],
    [47028, 47028, 37], [47029, 47055, 38], [47056, 47056, 37], [47057, 47083, 38],
    [47084, 47084, 37], [47085, 47111, 38], [47112, 47112, 37], [47113, 47139, 38],
    [47140, 47140, 37], [47141, 47167, 38], [47168, 47168, 37], [47169, 47195, 38],
    [47196, 47196, 37], [47197, 47223, 38], [47224, 47224, 37], [47225, 47251, 38],
    [47252, 47252, 37], [47253, 47279, 38], [47280, 47280, 37], [47281, 47307, 38],
    [47308, 47308, 37], [47309, 47335, 38], [47336, 47336, 37], [47337, 47363, 38],
    [47364, 47364, 37], [47365, 47391, 38], [47392, 47392, 37], [47393, 47419, 38],
    [47420, 47420, 37], [47421, 47447, 38], [47448, 47448, 37], [47449, 47475, 38],
    [47476, 47476, 37], [47477, 47503, 38], [47504, 47504, 37], [47505, 47531, 38],
    [47532, 47532, 37], [47533, 47559, 38], [47560, 47560, 37], [47561, 47587, 38],
    [47588, 47588, 37], [47589, 47615, 38], [47616, 47616, 37], [47617, 47643, 38],
    [47644, 47644, 37], [47645, 47671, 38], [47672, 47672, 37], [47673, 47699, 38],
    [47700, 47700, 37], [47701, 47727, 38], [47728, 47728, 37], [47729, 47755, 38],
    [47756, 47756, 37], [47757, 47783, 38], [47784, 47784, 37], [47785, 47811, 38],
    [47812, 47812, 37], [47813, 47839, 38], [47840, 47840, 37], [47841, 47867, 38],
    [47868, 47868, 37], [47869, 47895, 38], [47896, 47896, 37], [47897, 47923, 38],
    [47924, 47924, 37], [47925, 47951, 38], [47952, 47952, 37], [47953, 47979, 38],
    [47980, 47980, 37], [47981, 48007, 38], [48008, 48008, 37], [48009, 48035, 38],
    [48036, 48036, 37], [48037, 48063, 38], [48064, 48064, 37], [48065, 48091, 38],
    [48092, 48092, 37], [48093, 48119, 38], [48120, 48120, 37], [48121, 48147, 38],
    [48148, 48148, 37], [48149, 48175, 38], [48176, 48176, 37], [48177, 48203, 38],
    [48204, 48204, 37], [48205, 48231, 38], [48232, 48232, 37], [48233, 48259, 38],
    [48260, 48260, 37], [48261, 48287, 38], [48288, 48288, 37], [48289, 48315, 38],
    [48316, 48316, 37], [48317, 48343, 38], [48344, 48344, 37], [48345, 48371, 38],
    [48372, 48372, 37], [48373, 48399, 38], [48400, 48400, 37], [48401, 48427, 38],
    [48428, 48428, 37], [48429, 48455, 38], [48456, 48456, 37], [48457, 48483, 38],
    [48484, 48484, 37], [48485, 48511, 38], [48512, 48512, 37], [48513, 48539, 38],
    [48540, 48540, 37], [48541, 48567, 38], [48568, 48568, 37], [48569, 48595, 38],
    [48596, 48596, 37], [48597, 48623, 38], [48624, 48624, 37], [48625, 48651, 38],
    [48652, 48652, 37], [48653, 48679, 38], [48680, 48680, 37], [48681, 48707, 38],
    [48708, 48708, 37], [48709, 48735, 38], [48736, 48736, 37], [48737, 48763, 38],
    [48764, 48764, 37], [48765, 48791, 38], [48792, 48792, 37], [48793, 48819, 38],
    [48820, 48820, 37], [48821, 48847, 38], [48848, 48848, 37], [48849, 48875, 38],
    [48876, 48876, 37], [48877, 48903, 38], [48904, 48904, 37], [48905, 48931, 38],
    [48932, 48932, 37], [48933, 48959, 38], [48960, 48960, 37], [48961, 48987, 38],
    [48988, 48988, 37], [48989, 49015, 38], [49016, 49016, 37], [49017, 49043, 38],
    [49044, 49044, 37], [49045, 49071, 38], [49072, 49072, 37], [49073, 49099, 38],
    [49100, 49100, 37], [49101, 49127, 38], [49128, 49128, 37], [49129, 49155, 38],
    [49156, 49156, 37], [49157, 49183, 38], [49184, 49184, 37], [49185, 49211, 38],
    [49212, 49212, 37], [49213, 49239, 38], [49240, 49240, 37], [49241, 49267, 38],
    [49268, 49268, 37], [49269, 49295, 38], [49296, 49296, 37], [49297, 49323, 38],
    [49324, 49324, 37], [49325, 49351, 38], [49352, 49352, 37], [49353, 49379, 38],
    [49380, 49380, 37], [49381, 49407, 38], [49408, 49408, 37], [49409, 49435, 38],
    [49436, 49436, 37], [49437, 49463, 38], [49464, 49464, 37], [49465, 49491, 38],
    [49492, 49492, 37], [49493, 49519, 38], [49520, 49520, 37], [49521, 49547, 38],
    [49548, 49548, 37], [49549, 49575, 38], [49576, 49576, 37], [49577, 49603, 38],
    [49604, 49604, 37], [49605, 49631, 38], [49632, 49632, 37], [49633, 49659, 38],
    [49660, 49660, 37], [49661, 49687, 38], [49688, 49688, 37], [49689, 49715, 38],
    [49716, 49716, 37], [49717, 49743, 38], [49744, 49744, 37], [49745, 49771, 38],
    [49772, 49772, 37], [49773, 49799, 38], [49800, 49800, 37], [49801, 49827, 38],
    [49828, 49828, 37], [49829, 49855, 38], [49856, 49856, 37], [49857, 49883, 38],
    [49884, 49884, 37], [49885, 49911, 38], [49912, 49912, 37], [49913, 49939, 38],
    [49940, 49940, 37], [49941, 49967, 38], [49968, 49968, 37], [49969, 49995, 38],
    [49996, 49996, 37], [49997, 50023, 38], [50024, 50024, 37], [50025, 50051, 38],
    [50052, 50052, 37], [50053, 50079, 38], [50080, 50080, 37], [50081, 50107, 38],
    [50108, 50108, 37], [50109, 50135, 38], [50136, 50136, 37], [50137, 50163, 38],
    [50164, 50164, 37], [50165, 50191, 38], [50192, 50192, 37], [50193, 50219, 38],
    [50220, 50220, 37], [50221, 50247, 38], [50248, 50248, 37], [50249, 50275, 38],
    [50276, 50276, 37], [50277, 50303, 38], [50304, 50304, 37], [50305, 50331, 38],
    [50332, 50332, 37], [50333, 50359, 38], [50360, 50360, 37], [50361, 50387, 38],
    [50388, 50388, 37], [50389, 50415, 38], [50416, 50416, 37], [50417, 50443, 38],
    [50444, 50444, 37], [50445, 50471, 38], [50472, 50472, 37], [50473, 50499, 38],
    [50500, 50500, 37], [50501, 50527, 38], [50528, 50528, 37], [50529, 50555, 38],
    [50556, 50556, 37], [50557, 50583, 38], [50584, 50584, 37], [50585, 50611, 38],
    [50612, 50612, 37], [50613, 50639, 38], [50640, 50640, 37], [50641, 50667, 38],
    [50668, 50668, 37], [50669, 50695, 38], [50696, 50696, 37], [50697, 50723, 38],
    [50724, 50724, 37], [50725, 50751, 38], [50752, 50752, 37], [50753, 50779, 38],
    [50780, 50780, 37], [50781, 50807, 38], [50808, 50808, 37], [50809, 50835, 38],
    [50836, 50836, 37], [50837, 50863, 38], [50864, 50864, 37], [50865, 50891, 38],
    [50892, 50892, 37], [50893, 50919, 38], [50920, 50920, 37], [50921, 50947, 38],
    [50948, 50948, 37], [50949, 50975, 38], [50976, 50976, 37], [50977, 51003, 38],
    [51004, 51004, 37], [51005, 51031, 38], [51032, 51032, 37], [51033, 51059, 38],
    [51060, 51060, 37], [51061, 51087, 38], [51088, 51088, 37], [51089, 51115, 38],
    [51116, 51116, 37], [51117, 51143, 38], [51144, 51144, 37], [51145, 51171, 38],
    [51172, 51172, 37], [51173, 51199, 38], [51200, 51200, 37], [51201, 51227, 38],
    [51228, 51228, 37], [51229, 51255, 38], [51256, 51256, 37], [51257, 51283, 38],
    [51284, 51284, 37], [51285, 51311, 38], [51312, 51312, 37], [51313, 51339, 38],
    [51340, 51340, 37], [51341, 51367, 38], [51368, 51368, 37], [51369, 51395, 38],
    [51396, 51396, 37], [51397, 51423, 38], [51424, 51424, 37], [51425, 51451, 38],
    [51452, 51452, 37], [51453, 51479, 38], [51480, 51480, 37], [51481, 51507, 38],
    [51508, 51508, 37], [51509, 51535, 38], [51536, 51536, 37], [51537, 51563, 38],
    [51564, 51564, 37], [51565, 51591, 38], [51592, 51592, 37], [51593, 51619, 38],
    [51620, 51620, 37], [51621, 51647, 38], [51648, 51648, 37], [51649, 51675, 38],
    [51676, 51676, 37], [51677, 51703, 38], [51704, 51704, 37], [51705, 51731, 38],
    [51732, 51732, 37], [51733, 51759, 38], [51760, 51760, 37], [51761, 51787, 38],
    [51788, 51788, 37], [51789, 51815, 38], [51816, 51816, 37], [51817, 51843, 38],
    [51844, 51844, 37], [51845, 51871, 38], [51872, 51872, 37], [51873, 51899, 38],
    [51900, 51900, 37], [51901, 51927, 38], [51928, 51928, 37], [51929, 51955, 38],
    [51956, 51956, 37], [51957, 51983, 38], [51984, 51984, 37], [51985, 52011, 38],
    [52012, 52012, 37], [52013, 52039, 38], [52040, 52040, 37], [52041, 52067, 38],
    [52068, 52068, 37], [52069, 52095, 38], [52096, 52096, 37], [52097, 52123, 38],
    [52124, 52124, 37], [52125, 52151, 38], [52152, 52152, 37], [52153, 52179, 38],
    [52180, 52180, 37], [52181, 52207, 38], [52208, 52208, 37], [52209, 52235, 38],
    [52236, 52236, 37], [52237, 52263, 38], [52264, 52264, 37], [52265, 52291, 38],
    [52292, 52292, 37], [52293, 52319, 38], [52320, 52320, 37], [52321, 52347, 38],
    [52348, 52348, 37], [52349, 52375, 38], [52376, 52376, 37], [52377, 52403, 38],
    [52404, 52404, 37], [52405, 52431, 38], [52432, 52432, 37], [52433, 52459, 38],
    [52460, 52460, 37], [52461, 52487, 38], [52488, 52488, 37], [52489, 52515, 38],
    [52516, 52516, 37], [52517, 52543, 38], [52544, 52544, 37], [52545, 52571, 38],
    [52572, 52572, 37], [52573, 52599, 38], [52600, 52600, 37], [52601, 52627, 38],
    [52628, 52628, 37], [52629, 52655, 38], [52656, 52656, 37], [52657, 52683, 38],
    [52684, 52684, 37], [52685, 52711, 38], [52712, 52712, 37], [52713, 52739, 38],
    [52740, 52740, 37], [52741, 52767, 38], [52768, 52768, 37], [52769, 52795, 38],
    [52796, 52796, 37], [52797, 52823, 38], [52824, 52824, 37], [52825, 52851, 38],
    [52852, 52852, 37], [52853, 52879, 38], [52880, 52880, 37], [52881, 52907, 38],
    [52908, 52908, 37], [52909, 52935, 38], [52936, 52936, 37], [52937, 52963, 38],
    [52964, 52964, 37], [52965, 52991, 38], [52992, 52992, 37], [52993, 53019, 38],
    [53020, 53020, 37], [53021, 53047, 38], [53048, 53048, 37], [53049, 53075, 38],
    [53076, 53076, 37], [53077, 53103, 38], [53104, 53104, 37], [53105, 53131, 38],
    [53132, 53132, 37], [53133, 53159, 38], [53160, 53160, 37], [53161, 53187, 38],
    [53188, 53188, 37], [53189, 53215, 38], [53216, 53216, 37], [53217, 53243, 38],
    [53244, 53244, 37], [53245, 53271, 38], [53272, 53272, 37], [53273, 53299, 38],
    [53300, 53300, 37], [53301, 53327, 38], [53328, 53328, 37], [53329, 53355, 38],
    [53356, 53356, 37], [53357, 53383, 38], [53384, 53384, 37], [53385, 53411, 38],
    [53412, 53412, 37], [53413, 53439, 38], [53440, 53440, 37], [53441, 53467, 38],
    [53468, 53468, 37], [53469, 53495, 38], [53496, 53496, 37], [53497, 53523, 38],
    [53524, 53524, 37], [53525, 53551, 38], [53552, 53552, 37], [53553, 53579, 38],
    [53580, 53580, 37], [53581, 53607, 38], [53608, 53608, 37], [53609, 53635, 38],
    [53636, 53636, 37], [53637, 53663, 38], [53664, 53664, 37], [53665, 53691, 38],
    [53692, 53692, 37], [53693, 53719, 38], [53720, 53720, 37], [53721, 53747, 38],
    [53748, 53748, 37], [53749, 53775, 38], [53776, 53776, 37], [53777, 53803, 38],
    [53804, 53804, 37], [53805, 53831, 38], [53832, 53832, 37], [53833, 53859, 38],
    [53860, 53860, 37], [53861, 53887, 38], [53888, 53888, 37], [53889, 53915, 38],
    [53916, 53916, 37], [53917, 53943, 38], [53944, 53944, 37], [53945, 53971, 38],
    [53972, 53972, 37], [53973, 53999, 38], [54000, 54000, 37], [54001, 54027, 38],
    [54028, 54028, 37], [54029, 54055, 38], [54056, 54056, 37], [54057, 54083, 38],
    [54084, 54084, 37], [54085, 54111, 38], [54112, 54112, 37], [54113, 54139, 38],
    [54140, 54140, 37], [54141, 54167, 38], [54168, 54168, 37], [54169, 54195, 38],
    [54196, 54196, 37], [54197, 54223, 38], [54224, 54224, 37], [54225, 54251, 38],
    [54252, 54252, 37], [54253, 54279, 38], [54280, 54280, 37], [54281, 54307, 38],
    [54308, 54308, 37], [54309, 54335, 38], [54336, 54336, 37], [54337, 54363, 38],
    [54364, 54364, 37], [54365, 54391, 38], [54392, 54392, 37], [54393, 54419, 38],
    [54420, 54420, 37], [54421, 54447, 38], [54448, 54448, 37], [54449, 54475, 38],
    [54476, 54476, 37], [54477, 54503, 38], [54504, 54504, 37], [54505, 54531, 38],
    [54532, 54532, 37], [54533, 54559, 38], [54560, 54560, 37], [54561, 54587, 38],
    [54588, 54588, 37], [54589, 54615, 38], [54616, 54616, 37], [54617, 54643, 38],
    [54644, 54644, 37], [54645, 54671, 38], [54672, 54672, 37], [54673, 54699, 38],
    [54700, 54700, 37], [54701, 54727, 38], [54728, 54728, 37], [54729, 54755, 38],
    [54756, 54756, 37], [54757, 54783, 38], [54784, 54784, 37], [54785, 54811, 38],
    [54812, 54812, 37], [54813, 54839, 38], [54840, 54840, 37], [54841, 54867, 38],
    [54868, 54868, 37], [54869, 54895, 38], [54896, 54896, 37], [54897, 54923, 38],
    [54924, 54924, 37], [54925, 54951, 38], [54952, 54952, 37], [54953, 54979, 38],
    [54980, 54980, 37], [54981, 55007, 38], [55008, 55008, 37], [55009, 55035, 38],
    [55036, 55036, 37], [55037, 55063, 38], [55064, 55064, 37], [55065, 55091, 38],
    [55092, 55092, 37], [55093, 55119, 38], [55120, 55120, 37], [55121, 55147, 38],
    [55148, 55148, 37], [55149, 55175, 38], [55176, 55176, 37], [55177, 55203, 38],
    [55204, 55215, 11], [55216, 55238, 25], [55239, 55242, 11], [55243, 55291, 26],
    [55292, 63743, 11], [63744, 64255, 34], [64256, 64284, 11], [64285, 64285, 23],
    [64286, 64286, 3], [64287, 64296, 23], [64297, 64297, 11], [64298, 64310, 23],
    [64311, 64311, 11], [64312, 64316, 23], [64317, 64317, 11], [64318, 64318, 23],
    [64319, 64319, 11], [64320, 64321, 23], [64322, 64322, 11], [64323, 64324, 23],
    [64325, 64325, 11], [64326, 64335, 23], [64336, 64829, 11], [64830, 64830, 20],
    [64831, 64831, 14], [64832, 65019, 11], [65020, 65020, 13], [65021, 65023, 11],
    [65024, 65039, 3], [65040, 65040, 16], [65041, 65042, 20], [65043, 65044, 16],
    [65045, 65046, 9], [65047, 65047, 35], [65048, 65048, 20], [65049, 65049, 32],
    [65050, 65055, 11], [65056, 65071, 3], [65072, 65076, 34], [65077, 65077, 35],
    [65078, 65078, 20], [65079, 65079, 35], [65080, 65080, 20], [65081, 65081, 35],
    [65082, 65082, 20], [65083, 65083, 35], [65084, 65084, 20], [65085, 65085, 35],
    [65086, 65086, 20], [65087, 65087, 35], [65088, 65088, 20], [65089, 65089, 35],
    [65090, 65090, 20], [65091, 65091, 35], [65092, 65092, 20], [65093, 65094, 34],
    [65095, 65095, 35], [65096, 65096, 20], [65097, 65103, 34], [65104, 65104, 20],
    [65105, 65105, 34], [65106, 65106, 20], [65107, 65107, 11], [65108, 65109, 27],
    [65110, 65111, 9], [65112, 65112, 34], [65113, 65113, 35], [65114, 65114, 20],
    [65115, 65115, 35], [65116, 65116, 20], [65117, 65117, 35], [65118, 65118, 20],
    [65119, 65126, 34], [65127, 65127, 11], [65128, 65128, 34], [65129, 65129, 12],
    [65130, 65130, 13], [65131, 65131, 34], [65132, 65278, 11], [65279, 65279, 33],
    [65280, 65280, 11], [65281, 65281, 9], [65282, 65283, 34], [65284, 65284, 12],
    [65285, 65285, 13], [65286, 65287, 34], [65288, 65288, 35], [65289, 65289, 20],
    [65290, 65291, 34], [65292, 65292, 20], [65293, 65293, 34], [65294, 65294, 20],
    [65295, 65305, 34], [65306, 65307, 27], [65308, 65310, 34], [65311, 65311, 9],
    [65312, 65338, 34], [65339, 65339, 35], [65340, 65340, 34], [65341, 65341, 20],
    [65342, 65370, 34], [65371, 65371, 35], [65372, 65372, 34], [65373, 65373, 20],
    [65374, 65374, 34], [65375, 65375, 35], [65376, 65377, 20], [65378, 65378, 35],
    [65379, 65380, 20], [65381, 65381, 27], [65382, 65382, 34], [65383, 65392, 27],
    [65393, 65437, 34], [65438, 65439, 27], [65440, 65470, 34], [65471, 65473, 11],
    [65474, 65479, 34], [65480, 65481, 11], [65482, 65487, 34], [65488, 65489, 11],
    [65490, 65495, 34], [65496, 65497, 11], [65498, 65500, 34], [65501, 65503, 11],
    [65504, 65504, 13], [65505, 65505, 12], [65506, 65508, 34], [65509, 65510, 12],
    [65511, 65528, 11], [65529, 65531, 3], [65532, 65532, 39], [65533, 65791, 11],
    [65792, 65794, 4], [65795, 66044, 11], [66045, 66045, 3], [66046, 66271, 11],
    [66272, 66272, 3], [66273, 66421, 11], [66422, 66426, 3], [66427, 66462, 11],
    [66463, 66463, 4], [66464, 66511, 11], [66512, 66512, 4], [66513, 66719, 11],
    [66720, 66729, 19], [66730, 67670, 11], [67671, 67671, 4], [67672, 67870, 11],
    [67871, 67871, 4], [67872, 68096, 11], [68097, 68099, 3], [68100, 68100, 11],
    [68101, 68102, 3], [68103, 68107, 11], [68108, 68111, 3], [68112, 68151, 11],
    [68152, 68154, 3], [68155, 68158, 11], [68159, 68159, 3], [68160, 68175, 11],
    [68176, 68183, 4], [68184, 68324, 11], [68325, 68326, 3], [68327, 68335, 11],
    [68336, 68341, 4], [68342, 68342, 32], [68343, 68408, 11], [68409, 68415, 4],
    [68416, 68899, 11], [68900, 68903, 3], [68904, 68911, 11], [68912, 68921, 19],
    [68922, 69290, 11], [69291, 69292, 3], [69293, 69293, 4], [69294, 69445, 11],
    [69446, 69456, 3], [69457, 69631, 11], [69632, 69634, 3], [69635, 69687, 11],
    [69688, 69702, 3], [69703, 69704, 4], [69705, 69733, 11], [69734, 69743, 19],
    [69744, 69758, 11], [69759, 69762, 3], [69763, 69807, 11], [69808, 69818, 3],
    [69819, 69821, 11], [69822, 69825, 4], [69826, 69871, 11], [69872, 69881, 19],
    [69882, 69887, 11], [69888, 69890, 3], [69891, 69926, 11], [69927, 69940, 3],
    [69941, 69941, 11], [69942, 69951, 19], [69952, 69955, 4], [69956, 69956, 11],
    [69957, 69958, 3], [69959, 70002, 11], [70003, 70003, 3], [70004, 70004, 11],
    [70005, 70005, 22], [70006, 70015, 11], [70016, 70018, 3], [70019, 70066, 11],
    [70067, 70080, 3], [70081, 70084, 11], [70085, 70086, 4], [70087, 70087, 11],
    [70088, 70088, 4], [70089, 70092, 3], [70093, 70093, 11], [70094, 70095, 3],
    [70096, 70105, 19], [70106, 70106, 11], [70107, 70107, 22], [70108, 70108, 11],
    [70109, 70111, 4], [70112, 70187, 11], [70188, 70199, 3], [70200, 70201, 4],
    [70202, 70202, 11], [70203, 70204, 4], [70205, 70205, 11], [70206, 70206, 3],
    [70207, 70312, 11], [70313, 70313, 4], [70314, 70366, 11], [70367, 70378, 3],
    [70379, 70383, 11], [70384, 70393, 19], [70394, 70399, 11], [70400, 70403, 3],
    [70404, 70458, 11], [70459, 70460, 3], [70461, 70461, 11], [70462, 70468, 3],
    [70469, 70470, 11], [70471, 70472, 3], [70473, 70474, 11], [70475, 70477, 3],
    [70478, 70486, 11], [70487, 70487, 3], [70488, 70497, 11], [70498, 70499, 3],
    [70500, 70501, 11], [70502, 70508, 3], [70509, 70511, 11], [70512, 70516, 3],
    [70517, 70708, 11], [70709, 70726, 3], [70727, 70730, 11], [70731, 70734, 4],
    [70735, 70735, 11], [70736, 70745, 19], [70746, 70747, 4], [70748, 70749, 11],
    [70750, 70750, 3], [70751, 70831, 11], [70832, 70851, 3], [70852, 70863, 11],
    [70864, 70873, 19], [70874, 71086, 11], [71087, 71093, 3], [71094, 71095, 11],
    [71096, 71104, 3], [71105, 71105, 22], [71106, 71107, 4], [71108, 71109, 9],
    [71110, 71112, 11], [71113, 71127, 4], [71128, 71131, 11], [71132, 71133, 3],
    [71134, 71215, 11], [71216, 71232, 3], [71233, 71234, 4], [71235, 71247, 11],
    [71248, 71257, 19], [71258, 71263, 11], [71264, 71276, 22], [71277, 71338, 11],
    [71339, 71351, 3], [71352, 71359, 11], [71360, 71369, 19], [71370, 71423, 11],
    [71424, 71450, 16395], [71451, 71452, 11], [71453, 71467, 16387], [71468, 71471, 11],
    [71472, 71481, 19], [71482, 71483, 16395], [71484, 71486, 4], [71487, 71487, 16395],
    [71488, 71723, 11], [71724, 71738, 3], [71739, 71903, 11], [71904, 71913, 19],
    [71914, 71983, 11], [71984, 71989, 3], [71990, 71990, 11], [71991, 71992, 3],
    [71993, 71994, 11], [71995, 71998, 3], [71999, 71999, 11], [72000, 72000, 3],
    [72001, 72001, 11], [72002, 72003, 3], [72004, 72006, 4], [72007, 72015, 11],
    [72016, 72025, 19], [72026, 72144, 11], [72145, 72151, 3], [72152, 72153, 11],
    [72154, 72160, 3], [72161, 72161, 11], [72162, 72162, 22], [72163, 72163, 11],
    [72164, 72164, 3], [72165, 72192, 11], [72193, 72202, 3], [72203, 72242, 11],
    [72243, 72249, 3], [72250, 72250, 11], [72251, 72254, 3], [72255, 72255, 22],
    [72256, 72256, 11], [72257, 72260, 4], [72261, 72261, 22], [72262, 72262, 11],
    [72263, 72263, 3], [72264, 72272, 11], [72273, 72283, 3], [72284, 72329, 11],
    [72330, 72345, 3], [72346, 72348, 4], [72349, 72349, 11], [72350, 72352, 22],
    [72353, 72354, 4], [72355, 72750, 11], [72751, 72758, 3], [72759, 72759, 11],
    [72760, 72767, 3], [72768, 72768, 11], [72769, 72773, 4], [72774, 72783, 11],
    [72784, 72793, 19], [72794, 72815, 11], [72816, 72816, 22], [72817, 72817, 9],
    [72818, 72849, 11], [72850, 72871, 3], [72872, 72872, 11], [72873, 72886, 3],
    [72887, 73008, 11], [73009, 73014, 3], [73015, 73017, 11], [73018, 73018, 3],
    [73019, 73019, 11], [73020, 73021, 3], [73022, 73022, 11], [73023, 73029, 3],
    [73030, 73030, 11], [73031, 73031, 3], [73032, 73039, 11], [73040, 73049, 19],
    [73050, 73097, 11], [73098, 73102, 3], [73103, 73103, 11], [73104, 73105, 3],
    [73106, 73106, 11], [73107, 73111, 3], [73112, 73119, 11], [73120, 73129, 19],
    [73130, 73458, 11], [73459, 73462, 3], [73463, 73692, 11], [73693, 73696, 13],
    [73697, 73726, 11], [73727, 73727, 4], [73728, 74863, 11], [74864, 74868, 4],
    [74869, 78423, 11], [78424, 78426, 14], [78427, 78429, 20], [78430, 78465, 11],
    [78466, 78466, 20], [78467, 78469, 11], [78470, 78470, 14], [78471, 78471, 20],
    [78472, 78472, 14], [78473, 78473, 20], [78474, 78712, 11], [78713, 78713, 14],
    [78714, 78715, 20], [78716, 78895, 11], [78896, 78902, 21], [78903, 78903, 14],
    [78904, 78904, 20], [78905, 83405, 11], [83406, 83406, 14], [83407, 83407, 20],
    [83408, 92767, 11], [92768, 92777, 19], [92778, 92781, 11], [92782, 92783, 4],
    [92784, 92911, 11], [92912, 92916, 3], [92917, 92917, 4], [92918, 92975, 11],
    [92976, 92982, 3], [92983, 92985, 4], [92986, 92995, 11], [92996, 92996, 4],
    [92997, 93007, 11], [93008, 93017, 19], [93018, 93846, 11], [93847, 93848, 4],
    [93849, 94030, 11], [94031, 94031, 3], [94032, 94032, 11], [94033, 94087, 3],
    [94088, 94094, 11], [94095, 94098, 3], [94099, 94175, 11], [94176, 94179, 27],
    [94180, 94180, 21], [94181, 94191, 11], [94192, 94193, 3], [94194, 94207, 11],
    [94208, 100343, 34], [100344, 100351, 11], [100352, 101119, 34], [101120, 101631, 11],
    [101632, 101640, 34], [101641, 110591, 11], [110592, 110878, 34], [110879, 110927, 11],
    [110928, 110930, 27], [110931, 110947, 11], [110948, 110951, 27], [110952, 110959, 11],
    [110960, 111355, 34], [111356, 113820, 11], [113821, 113822, 3], [113823, 113823, 4],
    [113824, 113827, 3], [113828, 119140, 11], [119141, 119145, 3], [119146, 119148, 11],
    [119149, 119170, 3], [119171, 119172, 11], [119173, 119179, 3], [119180, 119209, 11],
    [119210, 119213, 3], [119214, 119361, 11], [119362, 119364, 3], [119365, 120781, 11],
    [120782, 120831, 19], [120832, 121343, 11], [121344, 121398, 3], [121399, 121402, 11],
    [121403, 121452, 3], [121453, 121460, 11], [121461, 121461, 3], [121462, 121475, 11],
    [121476, 121476, 3], [121477, 121478, 11], [121479, 121482, 4], [121483, 121498, 11],
    [121499, 121503, 3], [121504, 121504, 11], [121505, 121519, 3], [121520, 122879, 11],
    [122880, 122886, 3], [122887, 122887, 11], [122888, 122904, 3], [122905, 122906, 11],
    [122907, 122913, 3], [122914, 122914, 11], [122915, 122916, 3], [122917, 122917, 11],
    [122918, 122922, 3], [122923, 123183, 11], [123184, 123190, 3], [123191, 123199, 11],
    [123200, 123209, 19], [123210, 123627, 11], [123628, 123631, 3], [123632, 123641, 19],
    [123642, 123646, 11], [123647, 123647, 12], [123648, 125135, 11], [125136, 125142, 3],
    [125143, 125251, 11], [125252, 125258, 3], [125259, 125263, 11], [125264, 125273, 19],
    [125274, 125277, 11], [125278, 125279, 14], [125280, 126123, 11], [126124, 126124, 13],
    [126125, 126127, 11], [126128, 126128, 13], [126129, 126975, 11], [126976, 127231, 34],
    [127232, 127244, 11], [127245, 127247, 34], [127248, 127340, 11], [127341, 127343, 34],
    [127344, 127404, 11], [127405, 127461, 34], [127462, 127487, 40], [127488, 127876, 34],
    [127877, 127877, 36], [127878, 127899, 34], [127900, 127901, 11], [127902, 127924, 34],
    [127925, 127926, 11], [127927, 127931, 34], [127932, 127932, 11], [127933, 127937, 34],
    [127938, 127940, 36], [127941, 127942, 34], [127943, 127943, 36], [127944, 127945, 34],
    [127946, 127948, 36], [127949, 127994, 34], [127995, 127999, 41], [128000, 128065, 34],
    [128066, 128067, 36], [128068, 128069, 34], [128070, 128080, 36], [128081, 128101, 34],
    [128102, 128120, 36], [128121, 128123, 34], [128124, 128124, 36], [128125, 128128, 34],
    [128129, 128131, 36], [128132, 128132, 34], [128133, 128135, 36], [128136, 128142, 34],
    [128143, 128143, 36], [128144, 128144, 34], [128145, 128145, 36], [128146, 128159, 34],
    [128160, 128160, 11], [128161, 128161, 34], [128162, 128162, 11], [128163, 128163, 34],
    [128164, 128164, 11], [128165, 128169, 34], [128170, 128170, 36], [128171, 128174, 34],
    [128175, 128175, 11], [128176, 128176, 34], [128177, 128178, 11], [128179, 128255, 34],
    [128256, 128262, 11], [128263, 128278, 34], [128279, 128292, 11], [128293, 128305, 34],
    [128306, 128329, 11], [128330, 128371, 34], [128372, 128373, 36], [128374, 128377, 34],
    [128378, 128378, 36], [128379, 128399, 34], [128400, 128400, 36], [128401, 128404, 34],
    [128405, 128406, 36], [128407, 128467, 34], [128468, 128475, 11], [128476, 128499, 34],
    [128500, 128505, 11], [128506, 128580, 34], [128581, 128583, 36], [128584, 128586, 34],
    [128587, 128591, 36], [128592, 128629, 11], [128630, 128632, 10], [128633, 128635, 27],
    [128636, 128639, 11], [128640, 128674, 34], [128675, 128675, 36], [128676, 128691, 34],
    [128692, 128694, 36], [128695, 128703, 34], [128704, 128704, 36], [128705, 128715, 34],
    [128716, 128716, 36], [128717, 128767, 34], [128768, 128883, 11], [128884, 128895, 34],
    [128896, 128980, 11], [128981, 129023, 34], [129024, 129035, 11], [129036, 129039, 34],
    [129040, 129095, 11], [129096, 129103, 34], [129104, 129113, 11], [129114, 129119, 34],
    [129120, 129159, 11], [129160, 129167, 34], [129168, 129197, 11], [129198, 129279, 34],
    [129280, 129291, 11], [129292, 129292, 36], [129293, 129294, 34], [129295, 129295, 36],
    [129296, 129303, 34], [129304, 129311, 36], [129312, 129317, 34], [129318, 129318, 36],
    [129319, 129327, 34], [129328, 129337, 36], [129338, 129339, 34], [129340, 129342, 36],
    [129343, 129398, 34], [129399, 129399, 36], [129400, 129460, 34], [129461, 129462, 36],
    [129463, 129463, 34], [129464, 129465, 36], [129466, 129466, 34], [129467, 129467, 36],
    [129468, 129484, 34], [129485, 129487, 36], [129488, 129488, 34], [129489, 129501, 36],
    [129502, 129535, 34], [129536, 129619, 11], [129620, 129791, 34], [129792, 130031, 11],
    [130032, 130041, 19], [130042, 130047, 11], [130048, 131069, 34], [131070, 131071, 11],
    [131072, 196605, 34], [196606, 196607, 11], [196608, 262141, 34], [262142, 917504, 11],
    [917505, 917505, 3], [917506, 917535, 11], [917536, 917631, 3], [917632, 917759, 11],
    [917760, 917999, 3], [918000, 1114111, 11]
  ]`),

  metadata: {
    categoryCount: 42
  }
};
;RuleSet.sentence = {
  forwardTable: {
    flags: 2,
    table: JSON.parse(`[
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, -1, 0, 0, 0, 0, 3, 4, 5, 5, 3, 6, 7, 5, 5, 8, 5, 9, 9,
      3, 5, 5, -1, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 5, 5, 3, 6, 7, 5, 5, 8, 5,
      9, 9, 3, 5, 5, -1, 0, 0, 0, 0, 3, 0, 5, 5, 3, 6, 7, 5, 5, 8,
      5, 9, 9, 3, 5, 5, -1, 0, 2, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 10, 11, 12, 7, 7,
      5, 8, 0, 0, 0, 11, 7, 0, -1, 0, 0, 0, 0, 0, 0, 13, 14, 11, 12, 7,
      15, 5, 8, 5, 0, 9, 11, 8, 0, -1, 0, 0, 0, 0, 3, 0, 5, 5, 3, 6,
      7, 5, 5, 16, 5, 9, 9, 3, 9, 5, -1, 0, 0, 0, 0, 0, 0, 0, 10, 11,
      12, 7, 0, 5, 8, 0, 0, 0, 11, 10, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0,
      0, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      13, 13, 0, 0, 0, 13, 13, 0, 13, 0, 9, 0, 13, 0, -1, 0, 0, 0, 0, 0,
      0, 13, 14, 11, 12, 7, 13, 5, 8, 13, 0, 9, 11, 14, 0, -1, 0, 0, 0, 0,
      0, 0, 13, 14, 11, 12, 7, 15, 5, 8, 13, 0, 9, 11, 15, 0, -1, 0, 0, 0,
      0, 0, 0, 13, 14, 11, 12, 7, 15, 5, 8, 5, 9, 9, 11, 16, 0, 0, 0, 0
    ]`)
  },

  categoryTable: JSON.parse(`[
    [0, 8, 3], [9, 9, 4], [10, 10, 5], [11, 12, 4],
    [13, 13, 6], [14, 31, 3], [32, 32, 4], [33, 33, 7],
    [34, 34, 8], [35, 38, 3], [39, 41, 8], [42, 43, 3],
    [44, 45, 9], [46, 46, 10], [47, 47, 3], [48, 57, 11],
    [58, 58, 9], [59, 62, 3], [63, 63, 7], [64, 64, 3],
    [65, 90, 12], [91, 91, 8], [92, 92, 3], [93, 93, 8],
    [94, 96, 3], [97, 122, 13], [123, 123, 8], [124, 124, 3],
    [125, 125, 8], [126, 132, 3], [133, 133, 14], [134, 159, 3],
    [160, 160, 4], [161, 169, 3], [170, 170, 13], [171, 171, 8],
    [172, 172, 3], [173, 173, 15], [174, 180, 3], [181, 181, 13],
    [182, 185, 3], [186, 186, 13], [187, 187, 8], [188, 191, 3],
    [192, 214, 12], [215, 215, 3], [216, 222, 12], [223, 246, 13],
    [247, 247, 3], [248, 255, 13], [256, 256, 12], [257, 257, 13],
    [258, 258, 12], [259, 259, 13], [260, 260, 12], [261, 261, 13],
    [262, 262, 12], [263, 263, 13], [264, 264, 12], [265, 265, 13],
    [266, 266, 12], [267, 267, 13], [268, 268, 12], [269, 269, 13],
    [270, 270, 12], [271, 271, 13], [272, 272, 12], [273, 273, 13],
    [274, 274, 12], [275, 275, 13], [276, 276, 12], [277, 277, 13],
    [278, 278, 12], [279, 279, 13], [280, 280, 12], [281, 281, 13],
    [282, 282, 12], [283, 283, 13], [284, 284, 12], [285, 285, 13],
    [286, 286, 12], [287, 287, 13], [288, 288, 12], [289, 289, 13],
    [290, 290, 12], [291, 291, 13], [292, 292, 12], [293, 293, 13],
    [294, 294, 12], [295, 295, 13], [296, 296, 12], [297, 297, 13],
    [298, 298, 12], [299, 299, 13], [300, 300, 12], [301, 301, 13],
    [302, 302, 12], [303, 303, 13], [304, 304, 12], [305, 305, 13],
    [306, 306, 12], [307, 307, 13], [308, 308, 12], [309, 309, 13],
    [310, 310, 12], [311, 312, 13], [313, 313, 12], [314, 314, 13],
    [315, 315, 12], [316, 316, 13], [317, 317, 12], [318, 318, 13],
    [319, 319, 12], [320, 320, 13], [321, 321, 12], [322, 322, 13],
    [323, 323, 12], [324, 324, 13], [325, 325, 12], [326, 326, 13],
    [327, 327, 12], [328, 329, 13], [330, 330, 12], [331, 331, 13],
    [332, 332, 12], [333, 333, 13], [334, 334, 12], [335, 335, 13],
    [336, 336, 12], [337, 337, 13], [338, 338, 12], [339, 339, 13],
    [340, 340, 12], [341, 341, 13], [342, 342, 12], [343, 343, 13],
    [344, 344, 12], [345, 345, 13], [346, 346, 12], [347, 347, 13],
    [348, 348, 12], [349, 349, 13], [350, 350, 12], [351, 351, 13],
    [352, 352, 12], [353, 353, 13], [354, 354, 12], [355, 355, 13],
    [356, 356, 12], [357, 357, 13], [358, 358, 12], [359, 359, 13],
    [360, 360, 12], [361, 361, 13], [362, 362, 12], [363, 363, 13],
    [364, 364, 12], [365, 365, 13], [366, 366, 12], [367, 367, 13],
    [368, 368, 12], [369, 369, 13], [370, 370, 12], [371, 371, 13],
    [372, 372, 12], [373, 373, 13], [374, 374, 12], [375, 375, 13],
    [376, 377, 12], [378, 378, 13], [379, 379, 12], [380, 380, 13],
    [381, 381, 12], [382, 384, 13], [385, 386, 12], [387, 387, 13],
    [388, 388, 12], [389, 389, 13], [390, 391, 12], [392, 392, 13],
    [393, 395, 12], [396, 397, 13], [398, 401, 12], [402, 402, 13],
    [403, 404, 12], [405, 405, 13], [406, 408, 12], [409, 411, 13],
    [412, 413, 12], [414, 414, 13], [415, 416, 12], [417, 417, 13],
    [418, 418, 12], [419, 419, 13], [420, 420, 12], [421, 421, 13],
    [422, 423, 12], [424, 424, 13], [425, 425, 12], [426, 427, 13],
    [428, 428, 12], [429, 429, 13], [430, 431, 12], [432, 432, 13],
    [433, 435, 12], [436, 436, 13], [437, 437, 12], [438, 438, 13],
    [439, 440, 12], [441, 442, 13], [443, 443, 16], [444, 444, 12],
    [445, 447, 13], [448, 451, 16], [452, 453, 12], [454, 454, 13],
    [455, 456, 12], [457, 457, 13], [458, 459, 12], [460, 460, 13],
    [461, 461, 12], [462, 462, 13], [463, 463, 12], [464, 464, 13],
    [465, 465, 12], [466, 466, 13], [467, 467, 12], [468, 468, 13],
    [469, 469, 12], [470, 470, 13], [471, 471, 12], [472, 472, 13],
    [473, 473, 12], [474, 474, 13], [475, 475, 12], [476, 477, 13],
    [478, 478, 12], [479, 479, 13], [480, 480, 12], [481, 481, 13],
    [482, 482, 12], [483, 483, 13], [484, 484, 12], [485, 485, 13],
    [486, 486, 12], [487, 487, 13], [488, 488, 12], [489, 489, 13],
    [490, 490, 12], [491, 491, 13], [492, 492, 12], [493, 493, 13],
    [494, 494, 12], [495, 496, 13], [497, 498, 12], [499, 499, 13],
    [500, 500, 12], [501, 501, 13], [502, 504, 12], [505, 505, 13],
    [506, 506, 12], [507, 507, 13], [508, 508, 12], [509, 509, 13],
    [510, 510, 12], [511, 511, 13], [512, 512, 12], [513, 513, 13],
    [514, 514, 12], [515, 515, 13], [516, 516, 12], [517, 517, 13],
    [518, 518, 12], [519, 519, 13], [520, 520, 12], [521, 521, 13],
    [522, 522, 12], [523, 523, 13], [524, 524, 12], [525, 525, 13],
    [526, 526, 12], [527, 527, 13], [528, 528, 12], [529, 529, 13],
    [530, 530, 12], [531, 531, 13], [532, 532, 12], [533, 533, 13],
    [534, 534, 12], [535, 535, 13], [536, 536, 12], [537, 537, 13],
    [538, 538, 12], [539, 539, 13], [540, 540, 12], [541, 541, 13],
    [542, 542, 12], [543, 543, 13], [544, 544, 12], [545, 545, 13],
    [546, 546, 12], [547, 547, 13], [548, 548, 12], [549, 549, 13],
    [550, 550, 12], [551, 551, 13], [552, 552, 12], [553, 553, 13],
    [554, 554, 12], [555, 555, 13], [556, 556, 12], [557, 557, 13],
    [558, 558, 12], [559, 559, 13], [560, 560, 12], [561, 561, 13],
    [562, 562, 12], [563, 569, 13], [570, 571, 12], [572, 572, 13],
    [573, 574, 12], [575, 576, 13], [577, 577, 12], [578, 578, 13],
    [579, 582, 12], [583, 583, 13], [584, 584, 12], [585, 585, 13],
    [586, 586, 12], [587, 587, 13], [588, 588, 12], [589, 589, 13],
    [590, 590, 12], [591, 659, 13], [660, 660, 16], [661, 696, 13],
    [697, 703, 16], [704, 705, 13], [706, 709, 3], [710, 721, 16],
    [722, 735, 3], [736, 740, 13], [741, 747, 3], [748, 748, 16],
    [749, 749, 3], [750, 750, 16], [751, 767, 3], [768, 879, 15],
    [880, 880, 12], [881, 881, 13], [882, 882, 12], [883, 883, 13],
    [884, 884, 16], [885, 885, 3], [886, 886, 12], [887, 887, 13],
    [888, 889, 3], [890, 893, 13], [894, 894, 3], [895, 895, 12],
    [896, 901, 3], [902, 902, 12], [903, 903, 3], [904, 906, 12],
    [907, 907, 3], [908, 908, 12], [909, 909, 3], [910, 911, 12],
    [912, 912, 13], [913, 929, 12], [930, 930, 3], [931, 939, 12],
    [940, 974, 13], [975, 975, 12], [976, 977, 13], [978, 980, 12],
    [981, 983, 13], [984, 984, 12], [985, 985, 13], [986, 986, 12],
    [987, 987, 13], [988, 988, 12], [989, 989, 13], [990, 990, 12],
    [991, 991, 13], [992, 992, 12], [993, 993, 13], [994, 994, 12],
    [995, 995, 13], [996, 996, 12], [997, 997, 13], [998, 998, 12],
    [999, 999, 13], [1000, 1000, 12], [1001, 1001, 13], [1002, 1002, 12],
    [1003, 1003, 13], [1004, 1004, 12], [1005, 1005, 13], [1006, 1006, 12],
    [1007, 1011, 13], [1012, 1012, 12], [1013, 1013, 13], [1014, 1014, 3],
    [1015, 1015, 12], [1016, 1016, 13], [1017, 1018, 12], [1019, 1020, 13],
    [1021, 1071, 12], [1072, 1119, 13], [1120, 1120, 12], [1121, 1121, 13],
    [1122, 1122, 12], [1123, 1123, 13], [1124, 1124, 12], [1125, 1125, 13],
    [1126, 1126, 12], [1127, 1127, 13], [1128, 1128, 12], [1129, 1129, 13],
    [1130, 1130, 12], [1131, 1131, 13], [1132, 1132, 12], [1133, 1133, 13],
    [1134, 1134, 12], [1135, 1135, 13], [1136, 1136, 12], [1137, 1137, 13],
    [1138, 1138, 12], [1139, 1139, 13], [1140, 1140, 12], [1141, 1141, 13],
    [1142, 1142, 12], [1143, 1143, 13], [1144, 1144, 12], [1145, 1145, 13],
    [1146, 1146, 12], [1147, 1147, 13], [1148, 1148, 12], [1149, 1149, 13],
    [1150, 1150, 12], [1151, 1151, 13], [1152, 1152, 12], [1153, 1153, 13],
    [1154, 1154, 3], [1155, 1161, 15], [1162, 1162, 12], [1163, 1163, 13],
    [1164, 1164, 12], [1165, 1165, 13], [1166, 1166, 12], [1167, 1167, 13],
    [1168, 1168, 12], [1169, 1169, 13], [1170, 1170, 12], [1171, 1171, 13],
    [1172, 1172, 12], [1173, 1173, 13], [1174, 1174, 12], [1175, 1175, 13],
    [1176, 1176, 12], [1177, 1177, 13], [1178, 1178, 12], [1179, 1179, 13],
    [1180, 1180, 12], [1181, 1181, 13], [1182, 1182, 12], [1183, 1183, 13],
    [1184, 1184, 12], [1185, 1185, 13], [1186, 1186, 12], [1187, 1187, 13],
    [1188, 1188, 12], [1189, 1189, 13], [1190, 1190, 12], [1191, 1191, 13],
    [1192, 1192, 12], [1193, 1193, 13], [1194, 1194, 12], [1195, 1195, 13],
    [1196, 1196, 12], [1197, 1197, 13], [1198, 1198, 12], [1199, 1199, 13],
    [1200, 1200, 12], [1201, 1201, 13], [1202, 1202, 12], [1203, 1203, 13],
    [1204, 1204, 12], [1205, 1205, 13], [1206, 1206, 12], [1207, 1207, 13],
    [1208, 1208, 12], [1209, 1209, 13], [1210, 1210, 12], [1211, 1211, 13],
    [1212, 1212, 12], [1213, 1213, 13], [1214, 1214, 12], [1215, 1215, 13],
    [1216, 1217, 12], [1218, 1218, 13], [1219, 1219, 12], [1220, 1220, 13],
    [1221, 1221, 12], [1222, 1222, 13], [1223, 1223, 12], [1224, 1224, 13],
    [1225, 1225, 12], [1226, 1226, 13], [1227, 1227, 12], [1228, 1228, 13],
    [1229, 1229, 12], [1230, 1231, 13], [1232, 1232, 12], [1233, 1233, 13],
    [1234, 1234, 12], [1235, 1235, 13], [1236, 1236, 12], [1237, 1237, 13],
    [1238, 1238, 12], [1239, 1239, 13], [1240, 1240, 12], [1241, 1241, 13],
    [1242, 1242, 12], [1243, 1243, 13], [1244, 1244, 12], [1245, 1245, 13],
    [1246, 1246, 12], [1247, 1247, 13], [1248, 1248, 12], [1249, 1249, 13],
    [1250, 1250, 12], [1251, 1251, 13], [1252, 1252, 12], [1253, 1253, 13],
    [1254, 1254, 12], [1255, 1255, 13], [1256, 1256, 12], [1257, 1257, 13],
    [1258, 1258, 12], [1259, 1259, 13], [1260, 1260, 12], [1261, 1261, 13],
    [1262, 1262, 12], [1263, 1263, 13], [1264, 1264, 12], [1265, 1265, 13],
    [1266, 1266, 12], [1267, 1267, 13], [1268, 1268, 12], [1269, 1269, 13],
    [1270, 1270, 12], [1271, 1271, 13], [1272, 1272, 12], [1273, 1273, 13],
    [1274, 1274, 12], [1275, 1275, 13], [1276, 1276, 12], [1277, 1277, 13],
    [1278, 1278, 12], [1279, 1279, 13], [1280, 1280, 12], [1281, 1281, 13],
    [1282, 1282, 12], [1283, 1283, 13], [1284, 1284, 12], [1285, 1285, 13],
    [1286, 1286, 12], [1287, 1287, 13], [1288, 1288, 12], [1289, 1289, 13],
    [1290, 1290, 12], [1291, 1291, 13], [1292, 1292, 12], [1293, 1293, 13],
    [1294, 1294, 12], [1295, 1295, 13], [1296, 1296, 12], [1297, 1297, 13],
    [1298, 1298, 12], [1299, 1299, 13], [1300, 1300, 12], [1301, 1301, 13],
    [1302, 1302, 12], [1303, 1303, 13], [1304, 1304, 12], [1305, 1305, 13],
    [1306, 1306, 12], [1307, 1307, 13], [1308, 1308, 12], [1309, 1309, 13],
    [1310, 1310, 12], [1311, 1311, 13], [1312, 1312, 12], [1313, 1313, 13],
    [1314, 1314, 12], [1315, 1315, 13], [1316, 1316, 12], [1317, 1317, 13],
    [1318, 1318, 12], [1319, 1319, 13], [1320, 1320, 12], [1321, 1321, 13],
    [1322, 1322, 12], [1323, 1323, 13], [1324, 1324, 12], [1325, 1325, 13],
    [1326, 1326, 12], [1327, 1327, 13], [1328, 1328, 3], [1329, 1366, 12],
    [1367, 1368, 3], [1369, 1369, 16], [1370, 1372, 3], [1373, 1373, 9],
    [1374, 1375, 3], [1376, 1416, 13], [1417, 1417, 7], [1418, 1424, 3],
    [1425, 1469, 15], [1470, 1470, 3], [1471, 1471, 15], [1472, 1472, 3],
    [1473, 1474, 15], [1475, 1475, 3], [1476, 1477, 15], [1478, 1478, 3],
    [1479, 1479, 15], [1480, 1487, 3], [1488, 1514, 16], [1515, 1518, 3],
    [1519, 1523, 16], [1524, 1535, 3], [1536, 1541, 15], [1542, 1547, 3],
    [1548, 1549, 9], [1550, 1551, 3], [1552, 1562, 15], [1563, 1563, 3],
    [1564, 1564, 15], [1565, 1565, 3], [1566, 1567, 7], [1568, 1610, 16],
    [1611, 1631, 15], [1632, 1641, 11], [1642, 1642, 3], [1643, 1644, 11],
    [1645, 1645, 3], [1646, 1647, 16], [1648, 1648, 15], [1649, 1747, 16],
    [1748, 1748, 7], [1749, 1749, 16], [1750, 1757, 15], [1758, 1758, 3],
    [1759, 1764, 15], [1765, 1766, 16], [1767, 1768, 15], [1769, 1769, 3],
    [1770, 1773, 15], [1774, 1775, 16], [1776, 1785, 11], [1786, 1788, 16],
    [1789, 1790, 3], [1791, 1791, 16], [1792, 1794, 7], [1795, 1806, 3],
    [1807, 1807, 15], [1808, 1808, 16], [1809, 1809, 15], [1810, 1839, 16],
    [1840, 1866, 15], [1867, 1868, 3], [1869, 1957, 16], [1958, 1968, 15],
    [1969, 1969, 16], [1970, 1983, 3], [1984, 1993, 11], [1994, 2026, 16],
    [2027, 2035, 15], [2036, 2037, 16], [2038, 2039, 3], [2040, 2040, 9],
    [2041, 2041, 7], [2042, 2042, 16], [2043, 2044, 3], [2045, 2045, 15],
    [2046, 2047, 3], [2048, 2069, 16], [2070, 2073, 15], [2074, 2074, 16],
    [2075, 2083, 15], [2084, 2084, 16], [2085, 2087, 15], [2088, 2088, 16],
    [2089, 2093, 15], [2094, 2102, 3], [2103, 2103, 7], [2104, 2104, 3],
    [2105, 2105, 7], [2106, 2108, 3], [2109, 2110, 7], [2111, 2111, 3],
    [2112, 2136, 16], [2137, 2139, 15], [2140, 2143, 3], [2144, 2154, 16],
    [2155, 2207, 3], [2208, 2228, 16], [2229, 2229, 3], [2230, 2247, 16],
    [2248, 2258, 3], [2259, 2307, 15], [2308, 2361, 16], [2362, 2364, 15],
    [2365, 2365, 16], [2366, 2383, 15], [2384, 2384, 16], [2385, 2391, 15],
    [2392, 2401, 16], [2402, 2403, 15], [2404, 2405, 7], [2406, 2415, 11],
    [2416, 2416, 3], [2417, 2432, 16], [2433, 2435, 15], [2436, 2436, 3],
    [2437, 2444, 16], [2445, 2446, 3], [2447, 2448, 16], [2449, 2450, 3],
    [2451, 2472, 16], [2473, 2473, 3], [2474, 2480, 16], [2481, 2481, 3],
    [2482, 2482, 16], [2483, 2485, 3], [2486, 2489, 16], [2490, 2491, 3],
    [2492, 2492, 15], [2493, 2493, 16], [2494, 2500, 15], [2501, 2502, 3],
    [2503, 2504, 15], [2505, 2506, 3], [2507, 2509, 15], [2510, 2510, 16],
    [2511, 2518, 3], [2519, 2519, 15], [2520, 2523, 3], [2524, 2525, 16],
    [2526, 2526, 3], [2527, 2529, 16], [2530, 2531, 15], [2532, 2533, 3],
    [2534, 2543, 11], [2544, 2545, 16], [2546, 2555, 3], [2556, 2556, 16],
    [2557, 2557, 3], [2558, 2558, 15], [2559, 2560, 3], [2561, 2563, 15],
    [2564, 2564, 3], [2565, 2570, 16], [2571, 2574, 3], [2575, 2576, 16],
    [2577, 2578, 3], [2579, 2600, 16], [2601, 2601, 3], [2602, 2608, 16],
    [2609, 2609, 3], [2610, 2611, 16], [2612, 2612, 3], [2613, 2614, 16],
    [2615, 2615, 3], [2616, 2617, 16], [2618, 2619, 3], [2620, 2620, 15],
    [2621, 2621, 3], [2622, 2626, 15], [2627, 2630, 3], [2631, 2632, 15],
    [2633, 2634, 3], [2635, 2637, 15], [2638, 2640, 3], [2641, 2641, 15],
    [2642, 2648, 3], [2649, 2652, 16], [2653, 2653, 3], [2654, 2654, 16],
    [2655, 2661, 3], [2662, 2671, 11], [2672, 2673, 15], [2674, 2676, 16],
    [2677, 2677, 15], [2678, 2688, 3], [2689, 2691, 15], [2692, 2692, 3],
    [2693, 2701, 16], [2702, 2702, 3], [2703, 2705, 16], [2706, 2706, 3],
    [2707, 2728, 16], [2729, 2729, 3], [2730, 2736, 16], [2737, 2737, 3],
    [2738, 2739, 16], [2740, 2740, 3], [2741, 2745, 16], [2746, 2747, 3],
    [2748, 2748, 15], [2749, 2749, 16], [2750, 2757, 15], [2758, 2758, 3],
    [2759, 2761, 15], [2762, 2762, 3], [2763, 2765, 15], [2766, 2767, 3],
    [2768, 2768, 16], [2769, 2783, 3], [2784, 2785, 16], [2786, 2787, 15],
    [2788, 2789, 3], [2790, 2799, 11], [2800, 2808, 3], [2809, 2809, 16],
    [2810, 2815, 15], [2816, 2816, 3], [2817, 2819, 15], [2820, 2820, 3],
    [2821, 2828, 16], [2829, 2830, 3], [2831, 2832, 16], [2833, 2834, 3],
    [2835, 2856, 16], [2857, 2857, 3], [2858, 2864, 16], [2865, 2865, 3],
    [2866, 2867, 16], [2868, 2868, 3], [2869, 2873, 16], [2874, 2875, 3],
    [2876, 2876, 15], [2877, 2877, 16], [2878, 2884, 15], [2885, 2886, 3],
    [2887, 2888, 15], [2889, 2890, 3], [2891, 2893, 15], [2894, 2900, 3],
    [2901, 2903, 15], [2904, 2907, 3], [2908, 2909, 16], [2910, 2910, 3],
    [2911, 2913, 16], [2914, 2915, 15], [2916, 2917, 3], [2918, 2927, 11],
    [2928, 2928, 3], [2929, 2929, 16], [2930, 2945, 3], [2946, 2946, 15],
    [2947, 2947, 16], [2948, 2948, 3], [2949, 2954, 16], [2955, 2957, 3],
    [2958, 2960, 16], [2961, 2961, 3], [2962, 2965, 16], [2966, 2968, 3],
    [2969, 2970, 16], [2971, 2971, 3], [2972, 2972, 16], [2973, 2973, 3],
    [2974, 2975, 16], [2976, 2978, 3], [2979, 2980, 16], [2981, 2983, 3],
    [2984, 2986, 16], [2987, 2989, 3], [2990, 3001, 16], [3002, 3005, 3],
    [3006, 3010, 15], [3011, 3013, 3], [3014, 3016, 15], [3017, 3017, 3],
    [3018, 3021, 15], [3022, 3023, 3], [3024, 3024, 16], [3025, 3030, 3],
    [3031, 3031, 15], [3032, 3045, 3], [3046, 3055, 11], [3056, 3071, 3],
    [3072, 3076, 15], [3077, 3084, 16], [3085, 3085, 3], [3086, 3088, 16],
    [3089, 3089, 3], [3090, 3112, 16], [3113, 3113, 3], [3114, 3129, 16],
    [3130, 3132, 3], [3133, 3133, 16], [3134, 3140, 15], [3141, 3141, 3],
    [3142, 3144, 15], [3145, 3145, 3], [3146, 3149, 15], [3150, 3156, 3],
    [3157, 3158, 15], [3159, 3159, 3], [3160, 3162, 16], [3163, 3167, 3],
    [3168, 3169, 16], [3170, 3171, 15], [3172, 3173, 3], [3174, 3183, 11],
    [3184, 3199, 3], [3200, 3200, 16], [3201, 3203, 15], [3204, 3204, 3],
    [3205, 3212, 16], [3213, 3213, 3], [3214, 3216, 16], [3217, 3217, 3],
    [3218, 3240, 16], [3241, 3241, 3], [3242, 3251, 16], [3252, 3252, 3],
    [3253, 3257, 16], [3258, 3259, 3], [3260, 3260, 15], [3261, 3261, 16],
    [3262, 3268, 15], [3269, 3269, 3], [3270, 3272, 15], [3273, 3273, 3],
    [3274, 3277, 15], [3278, 3284, 3], [3285, 3286, 15], [3287, 3293, 3],
    [3294, 3294, 16], [3295, 3295, 3], [3296, 3297, 16], [3298, 3299, 15],
    [3300, 3301, 3], [3302, 3311, 11], [3312, 3312, 3], [3313, 3314, 16],
    [3315, 3327, 3], [3328, 3331, 15], [3332, 3340, 16], [3341, 3341, 3],
    [3342, 3344, 16], [3345, 3345, 3], [3346, 3386, 16], [3387, 3388, 15],
    [3389, 3389, 16], [3390, 3396, 15], [3397, 3397, 3], [3398, 3400, 15],
    [3401, 3401, 3], [3402, 3405, 15], [3406, 3406, 16], [3407, 3411, 3],
    [3412, 3414, 16], [3415, 3415, 15], [3416, 3422, 3], [3423, 3425, 16],
    [3426, 3427, 15], [3428, 3429, 3], [3430, 3439, 11], [3440, 3449, 3],
    [3450, 3455, 16], [3456, 3456, 3], [3457, 3459, 15], [3460, 3460, 3],
    [3461, 3478, 16], [3479, 3481, 3], [3482, 3505, 16], [3506, 3506, 3],
    [3507, 3515, 16], [3516, 3516, 3], [3517, 3517, 16], [3518, 3519, 3],
    [3520, 3526, 16], [3527, 3529, 3], [3530, 3530, 15], [3531, 3534, 3],
    [3535, 3540, 15], [3541, 3541, 3], [3542, 3542, 15], [3543, 3543, 3],
    [3544, 3551, 15], [3552, 3557, 3], [3558, 3567, 11], [3568, 3569, 3],
    [3570, 3571, 15], [3572, 3584, 3], [3585, 3632, 16], [3633, 3633, 15],
    [3634, 3635, 16], [3636, 3642, 15], [3643, 3647, 3], [3648, 3654, 16],
    [3655, 3662, 15], [3663, 3663, 3], [3664, 3673, 11], [3674, 3712, 3],
    [3713, 3714, 16], [3715, 3715, 3], [3716, 3716, 16], [3717, 3717, 3],
    [3718, 3722, 16], [3723, 3723, 3], [3724, 3747, 16], [3748, 3748, 3],
    [3749, 3749, 16], [3750, 3750, 3], [3751, 3760, 16], [3761, 3761, 15],
    [3762, 3763, 16], [3764, 3772, 15], [3773, 3773, 16], [3774, 3775, 3],
    [3776, 3780, 16], [3781, 3781, 3], [3782, 3782, 16], [3783, 3783, 3],
    [3784, 3789, 15], [3790, 3791, 3], [3792, 3801, 11], [3802, 3803, 3],
    [3804, 3807, 16], [3808, 3839, 3], [3840, 3840, 16], [3841, 3863, 3],
    [3864, 3865, 15], [3866, 3871, 3], [3872, 3881, 11], [3882, 3892, 3],
    [3893, 3893, 15], [3894, 3894, 3], [3895, 3895, 15], [3896, 3896, 3],
    [3897, 3897, 15], [3898, 3901, 8], [3902, 3903, 15], [3904, 3911, 16],
    [3912, 3912, 3], [3913, 3948, 16], [3949, 3952, 3], [3953, 3972, 15],
    [3973, 3973, 3], [3974, 3975, 15], [3976, 3980, 16], [3981, 3991, 15],
    [3992, 3992, 3], [3993, 4028, 15], [4029, 4037, 3], [4038, 4038, 15],
    [4039, 4095, 3], [4096, 4138, 16], [4139, 4158, 15], [4159, 4159, 16],
    [4160, 4169, 11], [4170, 4171, 7], [4172, 4175, 3], [4176, 4181, 16],
    [4182, 4185, 15], [4186, 4189, 16], [4190, 4192, 15], [4193, 4193, 16],
    [4194, 4196, 15], [4197, 4198, 16], [4199, 4205, 15], [4206, 4208, 16],
    [4209, 4212, 15], [4213, 4225, 16], [4226, 4237, 15], [4238, 4238, 16],
    [4239, 4239, 15], [4240, 4249, 11], [4250, 4253, 15], [4254, 4255, 3],
    [4256, 4293, 12], [4294, 4294, 3], [4295, 4295, 12], [4296, 4300, 3],
    [4301, 4301, 12], [4302, 4303, 3], [4304, 4346, 16], [4347, 4347, 3],
    [4348, 4680, 16], [4681, 4681, 3], [4682, 4685, 16], [4686, 4687, 3],
    [4688, 4694, 16], [4695, 4695, 3], [4696, 4696, 16], [4697, 4697, 3],
    [4698, 4701, 16], [4702, 4703, 3], [4704, 4744, 16], [4745, 4745, 3],
    [4746, 4749, 16], [4750, 4751, 3], [4752, 4784, 16], [4785, 4785, 3],
    [4786, 4789, 16], [4790, 4791, 3], [4792, 4798, 16], [4799, 4799, 3],
    [4800, 4800, 16], [4801, 4801, 3], [4802, 4805, 16], [4806, 4807, 3],
    [4808, 4822, 16], [4823, 4823, 3], [4824, 4880, 16], [4881, 4881, 3],
    [4882, 4885, 16], [4886, 4887, 3], [4888, 4954, 16], [4955, 4956, 3],
    [4957, 4959, 15], [4960, 4961, 3], [4962, 4962, 7], [4963, 4966, 3],
    [4967, 4968, 7], [4969, 4991, 3], [4992, 5007, 16], [5008, 5023, 3],
    [5024, 5109, 12], [5110, 5111, 3], [5112, 5117, 13], [5118, 5120, 3],
    [5121, 5740, 16], [5741, 5741, 3], [5742, 5742, 7], [5743, 5759, 16],
    [5760, 5760, 4], [5761, 5786, 16], [5787, 5788, 8], [5789, 5791, 3],
    [5792, 5866, 16], [5867, 5869, 3], [5870, 5880, 16], [5881, 5887, 3],
    [5888, 5900, 16], [5901, 5901, 3], [5902, 5905, 16], [5906, 5908, 15],
    [5909, 5919, 3], [5920, 5937, 16], [5938, 5940, 15], [5941, 5942, 7],
    [5943, 5951, 3], [5952, 5969, 16], [5970, 5971, 15], [5972, 5983, 3],
    [5984, 5996, 16], [5997, 5997, 3], [5998, 6000, 16], [6001, 6001, 3],
    [6002, 6003, 15], [6004, 6015, 3], [6016, 6067, 16], [6068, 6099, 15],
    [6100, 6102, 3], [6103, 6103, 16], [6104, 6107, 3], [6108, 6108, 16],
    [6109, 6109, 15], [6110, 6111, 3], [6112, 6121, 11], [6122, 6145, 3],
    [6146, 6146, 9], [6147, 6147, 7], [6148, 6151, 3], [6152, 6152, 9],
    [6153, 6153, 7], [6154, 6154, 3], [6155, 6158, 15], [6159, 6159, 3],
    [6160, 6169, 11], [6170, 6175, 3], [6176, 6264, 16], [6265, 6271, 3],
    [6272, 6276, 16], [6277, 6278, 15], [6279, 6312, 16], [6313, 6313, 15],
    [6314, 6314, 16], [6315, 6319, 3], [6320, 6389, 16], [6390, 6399, 3],
    [6400, 6430, 16], [6431, 6431, 3], [6432, 6443, 15], [6444, 6447, 3],
    [6448, 6459, 15], [6460, 6467, 3], [6468, 6469, 7], [6470, 6479, 11],
    [6480, 6509, 16], [6510, 6511, 3], [6512, 6516, 16], [6517, 6527, 3],
    [6528, 6571, 16], [6572, 6575, 3], [6576, 6601, 16], [6602, 6607, 3],
    [6608, 6617, 11], [6618, 6655, 3], [6656, 6678, 16], [6679, 6683, 15],
    [6684, 6687, 3], [6688, 6740, 16], [6741, 6750, 15], [6751, 6751, 3],
    [6752, 6780, 15], [6781, 6782, 3], [6783, 6783, 15], [6784, 6793, 11],
    [6794, 6799, 3], [6800, 6809, 11], [6810, 6822, 3], [6823, 6823, 16],
    [6824, 6827, 7], [6828, 6831, 3], [6832, 6848, 15], [6849, 6911, 3],
    [6912, 6916, 15], [6917, 6963, 16], [6964, 6980, 15], [6981, 6987, 16],
    [6988, 6991, 3], [6992, 7001, 11], [7002, 7003, 7], [7004, 7005, 3],
    [7006, 7007, 7], [7008, 7018, 3], [7019, 7027, 15], [7028, 7039, 3],
    [7040, 7042, 15], [7043, 7072, 16], [7073, 7085, 15], [7086, 7087, 16],
    [7088, 7097, 11], [7098, 7141, 16], [7142, 7155, 15], [7156, 7167, 3],
    [7168, 7203, 16], [7204, 7223, 15], [7224, 7226, 3], [7227, 7228, 7],
    [7229, 7231, 3], [7232, 7241, 11], [7242, 7244, 3], [7245, 7247, 16],
    [7248, 7257, 11], [7258, 7293, 16], [7294, 7295, 7], [7296, 7304, 13],
    [7305, 7311, 3], [7312, 7354, 16], [7355, 7356, 3], [7357, 7359, 16],
    [7360, 7375, 3], [7376, 7378, 15], [7379, 7379, 3], [7380, 7400, 15],
    [7401, 7404, 16], [7405, 7405, 15], [7406, 7411, 16], [7412, 7412, 15],
    [7413, 7414, 16], [7415, 7417, 15], [7418, 7418, 16], [7419, 7423, 3],
    [7424, 7615, 13], [7616, 7673, 15], [7674, 7674, 3], [7675, 7679, 15],
    [7680, 7680, 12], [7681, 7681, 13], [7682, 7682, 12], [7683, 7683, 13],
    [7684, 7684, 12], [7685, 7685, 13], [7686, 7686, 12], [7687, 7687, 13],
    [7688, 7688, 12], [7689, 7689, 13], [7690, 7690, 12], [7691, 7691, 13],
    [7692, 7692, 12], [7693, 7693, 13], [7694, 7694, 12], [7695, 7695, 13],
    [7696, 7696, 12], [7697, 7697, 13], [7698, 7698, 12], [7699, 7699, 13],
    [7700, 7700, 12], [7701, 7701, 13], [7702, 7702, 12], [7703, 7703, 13],
    [7704, 7704, 12], [7705, 7705, 13], [7706, 7706, 12], [7707, 7707, 13],
    [7708, 7708, 12], [7709, 7709, 13], [7710, 7710, 12], [7711, 7711, 13],
    [7712, 7712, 12], [7713, 7713, 13], [7714, 7714, 12], [7715, 7715, 13],
    [7716, 7716, 12], [7717, 7717, 13], [7718, 7718, 12], [7719, 7719, 13],
    [7720, 7720, 12], [7721, 7721, 13], [7722, 7722, 12], [7723, 7723, 13],
    [7724, 7724, 12], [7725, 7725, 13], [7726, 7726, 12], [7727, 7727, 13],
    [7728, 7728, 12], [7729, 7729, 13], [7730, 7730, 12], [7731, 7731, 13],
    [7732, 7732, 12], [7733, 7733, 13], [7734, 7734, 12], [7735, 7735, 13],
    [7736, 7736, 12], [7737, 7737, 13], [7738, 7738, 12], [7739, 7739, 13],
    [7740, 7740, 12], [7741, 7741, 13], [7742, 7742, 12], [7743, 7743, 13],
    [7744, 7744, 12], [7745, 7745, 13], [7746, 7746, 12], [7747, 7747, 13],
    [7748, 7748, 12], [7749, 7749, 13], [7750, 7750, 12], [7751, 7751, 13],
    [7752, 7752, 12], [7753, 7753, 13], [7754, 7754, 12], [7755, 7755, 13],
    [7756, 7756, 12], [7757, 7757, 13], [7758, 7758, 12], [7759, 7759, 13],
    [7760, 7760, 12], [7761, 7761, 13], [7762, 7762, 12], [7763, 7763, 13],
    [7764, 7764, 12], [7765, 7765, 13], [7766, 7766, 12], [7767, 7767, 13],
    [7768, 7768, 12], [7769, 7769, 13], [7770, 7770, 12], [7771, 7771, 13],
    [7772, 7772, 12], [7773, 7773, 13], [7774, 7774, 12], [7775, 7775, 13],
    [7776, 7776, 12], [7777, 7777, 13], [7778, 7778, 12], [7779, 7779, 13],
    [7780, 7780, 12], [7781, 7781, 13], [7782, 7782, 12], [7783, 7783, 13],
    [7784, 7784, 12], [7785, 7785, 13], [7786, 7786, 12], [7787, 7787, 13],
    [7788, 7788, 12], [7789, 7789, 13], [7790, 7790, 12], [7791, 7791, 13],
    [7792, 7792, 12], [7793, 7793, 13], [7794, 7794, 12], [7795, 7795, 13],
    [7796, 7796, 12], [7797, 7797, 13], [7798, 7798, 12], [7799, 7799, 13],
    [7800, 7800, 12], [7801, 7801, 13], [7802, 7802, 12], [7803, 7803, 13],
    [7804, 7804, 12], [7805, 7805, 13], [7806, 7806, 12], [7807, 7807, 13],
    [7808, 7808, 12], [7809, 7809, 13], [7810, 7810, 12], [7811, 7811, 13],
    [7812, 7812, 12], [7813, 7813, 13], [7814, 7814, 12], [7815, 7815, 13],
    [7816, 7816, 12], [7817, 7817, 13], [7818, 7818, 12], [7819, 7819, 13],
    [7820, 7820, 12], [7821, 7821, 13], [7822, 7822, 12], [7823, 7823, 13],
    [7824, 7824, 12], [7825, 7825, 13], [7826, 7826, 12], [7827, 7827, 13],
    [7828, 7828, 12], [7829, 7837, 13], [7838, 7838, 12], [7839, 7839, 13],
    [7840, 7840, 12], [7841, 7841, 13], [7842, 7842, 12], [7843, 7843, 13],
    [7844, 7844, 12], [7845, 7845, 13], [7846, 7846, 12], [7847, 7847, 13],
    [7848, 7848, 12], [7849, 7849, 13], [7850, 7850, 12], [7851, 7851, 13],
    [7852, 7852, 12], [7853, 7853, 13], [7854, 7854, 12], [7855, 7855, 13],
    [7856, 7856, 12], [7857, 7857, 13], [7858, 7858, 12], [7859, 7859, 13],
    [7860, 7860, 12], [7861, 7861, 13], [7862, 7862, 12], [7863, 7863, 13],
    [7864, 7864, 12], [7865, 7865, 13], [7866, 7866, 12], [7867, 7867, 13],
    [7868, 7868, 12], [7869, 7869, 13], [7870, 7870, 12], [7871, 7871, 13],
    [7872, 7872, 12], [7873, 7873, 13], [7874, 7874, 12], [7875, 7875, 13],
    [7876, 7876, 12], [7877, 7877, 13], [7878, 7878, 12], [7879, 7879, 13],
    [7880, 7880, 12], [7881, 7881, 13], [7882, 7882, 12], [7883, 7883, 13],
    [7884, 7884, 12], [7885, 7885, 13], [7886, 7886, 12], [7887, 7887, 13],
    [7888, 7888, 12], [7889, 7889, 13], [7890, 7890, 12], [7891, 7891, 13],
    [7892, 7892, 12], [7893, 7893, 13], [7894, 7894, 12], [7895, 7895, 13],
    [7896, 7896, 12], [7897, 7897, 13], [7898, 7898, 12], [7899, 7899, 13],
    [7900, 7900, 12], [7901, 7901, 13], [7902, 7902, 12], [7903, 7903, 13],
    [7904, 7904, 12], [7905, 7905, 13], [7906, 7906, 12], [7907, 7907, 13],
    [7908, 7908, 12], [7909, 7909, 13], [7910, 7910, 12], [7911, 7911, 13],
    [7912, 7912, 12], [7913, 7913, 13], [7914, 7914, 12], [7915, 7915, 13],
    [7916, 7916, 12], [7917, 7917, 13], [7918, 7918, 12], [7919, 7919, 13],
    [7920, 7920, 12], [7921, 7921, 13], [7922, 7922, 12], [7923, 7923, 13],
    [7924, 7924, 12], [7925, 7925, 13], [7926, 7926, 12], [7927, 7927, 13],
    [7928, 7928, 12], [7929, 7929, 13], [7930, 7930, 12], [7931, 7931, 13],
    [7932, 7932, 12], [7933, 7933, 13], [7934, 7934, 12], [7935, 7943, 13],
    [7944, 7951, 12], [7952, 7957, 13], [7958, 7959, 3], [7960, 7965, 12],
    [7966, 7967, 3], [7968, 7975, 13], [7976, 7983, 12], [7984, 7991, 13],
    [7992, 7999, 12], [8000, 8005, 13], [8006, 8007, 3], [8008, 8013, 12],
    [8014, 8015, 3], [8016, 8023, 13], [8024, 8024, 3], [8025, 8025, 12],
    [8026, 8026, 3], [8027, 8027, 12], [8028, 8028, 3], [8029, 8029, 12],
    [8030, 8030, 3], [8031, 8031, 12], [8032, 8039, 13], [8040, 8047, 12],
    [8048, 8061, 13], [8062, 8063, 3], [8064, 8071, 13], [8072, 8079, 12],
    [8080, 8087, 13], [8088, 8095, 12], [8096, 8103, 13], [8104, 8111, 12],
    [8112, 8116, 13], [8117, 8117, 3], [8118, 8119, 13], [8120, 8124, 12],
    [8125, 8125, 3], [8126, 8126, 13], [8127, 8129, 3], [8130, 8132, 13],
    [8133, 8133, 3], [8134, 8135, 13], [8136, 8140, 12], [8141, 8143, 3],
    [8144, 8147, 13], [8148, 8149, 3], [8150, 8151, 13], [8152, 8155, 12],
    [8156, 8159, 3], [8160, 8167, 13], [8168, 8172, 12], [8173, 8177, 3],
    [8178, 8180, 13], [8181, 8181, 3], [8182, 8183, 13], [8184, 8188, 12],
    [8189, 8191, 3], [8192, 8202, 4], [8203, 8207, 15], [8208, 8210, 3],
    [8211, 8212, 9], [8213, 8215, 3], [8216, 8223, 8], [8224, 8227, 3],
    [8228, 8228, 10], [8229, 8231, 3], [8232, 8233, 14], [8234, 8238, 15],
    [8239, 8239, 4], [8240, 8248, 3], [8249, 8250, 8], [8251, 8251, 3],
    [8252, 8253, 7], [8254, 8260, 3], [8261, 8262, 8], [8263, 8265, 7],
    [8266, 8286, 3], [8287, 8287, 4], [8288, 8292, 15], [8293, 8293, 3],
    [8294, 8303, 15], [8304, 8304, 3], [8305, 8305, 13], [8306, 8316, 3],
    [8317, 8318, 8], [8319, 8319, 13], [8320, 8332, 3], [8333, 8334, 8],
    [8335, 8335, 3], [8336, 8348, 13], [8349, 8399, 3], [8400, 8432, 15],
    [8433, 8449, 3], [8450, 8450, 12], [8451, 8454, 3], [8455, 8455, 12],
    [8456, 8457, 3], [8458, 8458, 13], [8459, 8461, 12], [8462, 8463, 13],
    [8464, 8466, 12], [8467, 8467, 13], [8468, 8468, 3], [8469, 8469, 12],
    [8470, 8472, 3], [8473, 8477, 12], [8478, 8483, 3], [8484, 8484, 12],
    [8485, 8485, 3], [8486, 8486, 12], [8487, 8487, 3], [8488, 8488, 12],
    [8489, 8489, 3], [8490, 8493, 12], [8494, 8494, 3], [8495, 8495, 13],
    [8496, 8499, 12], [8500, 8500, 13], [8501, 8504, 16], [8505, 8505, 13],
    [8506, 8507, 3], [8508, 8509, 13], [8510, 8511, 12], [8512, 8516, 3],
    [8517, 8517, 12], [8518, 8521, 13], [8522, 8525, 3], [8526, 8526, 13],
    [8527, 8543, 3], [8544, 8559, 12], [8560, 8575, 13], [8576, 8578, 16],
    [8579, 8579, 12], [8580, 8580, 13], [8581, 8584, 16], [8585, 8967, 3],
    [8968, 8971, 8], [8972, 9000, 3], [9001, 9002, 8], [9003, 9397, 3],
    [9398, 9423, 12], [9424, 9449, 13], [9450, 10074, 3], [10075, 10080, 8],
    [10081, 10087, 3], [10088, 10101, 8], [10102, 10180, 3], [10181, 10182, 8],
    [10183, 10213, 3], [10214, 10223, 8], [10224, 10626, 3], [10627, 10648, 8],
    [10649, 10711, 3], [10712, 10715, 8], [10716, 10747, 3], [10748, 10749, 8],
    [10750, 11263, 3], [11264, 11310, 12], [11311, 11311, 3], [11312, 11358, 13],
    [11359, 11359, 3], [11360, 11360, 12], [11361, 11361, 13], [11362, 11364, 12],
    [11365, 11366, 13], [11367, 11367, 12], [11368, 11368, 13], [11369, 11369, 12],
    [11370, 11370, 13], [11371, 11371, 12], [11372, 11372, 13], [11373, 11376, 12],
    [11377, 11377, 13], [11378, 11378, 12], [11379, 11380, 13], [11381, 11381, 12],
    [11382, 11389, 13], [11390, 11392, 12], [11393, 11393, 13], [11394, 11394, 12],
    [11395, 11395, 13], [11396, 11396, 12], [11397, 11397, 13], [11398, 11398, 12],
    [11399, 11399, 13], [11400, 11400, 12], [11401, 11401, 13], [11402, 11402, 12],
    [11403, 11403, 13], [11404, 11404, 12], [11405, 11405, 13], [11406, 11406, 12],
    [11407, 11407, 13], [11408, 11408, 12], [11409, 11409, 13], [11410, 11410, 12],
    [11411, 11411, 13], [11412, 11412, 12], [11413, 11413, 13], [11414, 11414, 12],
    [11415, 11415, 13], [11416, 11416, 12], [11417, 11417, 13], [11418, 11418, 12],
    [11419, 11419, 13], [11420, 11420, 12], [11421, 11421, 13], [11422, 11422, 12],
    [11423, 11423, 13], [11424, 11424, 12], [11425, 11425, 13], [11426, 11426, 12],
    [11427, 11427, 13], [11428, 11428, 12], [11429, 11429, 13], [11430, 11430, 12],
    [11431, 11431, 13], [11432, 11432, 12], [11433, 11433, 13], [11434, 11434, 12],
    [11435, 11435, 13], [11436, 11436, 12], [11437, 11437, 13], [11438, 11438, 12],
    [11439, 11439, 13], [11440, 11440, 12], [11441, 11441, 13], [11442, 11442, 12],
    [11443, 11443, 13], [11444, 11444, 12], [11445, 11445, 13], [11446, 11446, 12],
    [11447, 11447, 13], [11448, 11448, 12], [11449, 11449, 13], [11450, 11450, 12],
    [11451, 11451, 13], [11452, 11452, 12], [11453, 11453, 13], [11454, 11454, 12],
    [11455, 11455, 13], [11456, 11456, 12], [11457, 11457, 13], [11458, 11458, 12],
    [11459, 11459, 13], [11460, 11460, 12], [11461, 11461, 13], [11462, 11462, 12],
    [11463, 11463, 13], [11464, 11464, 12], [11465, 11465, 13], [11466, 11466, 12],
    [11467, 11467, 13], [11468, 11468, 12], [11469, 11469, 13], [11470, 11470, 12],
    [11471, 11471, 13], [11472, 11472, 12], [11473, 11473, 13], [11474, 11474, 12],
    [11475, 11475, 13], [11476, 11476, 12], [11477, 11477, 13], [11478, 11478, 12],
    [11479, 11479, 13], [11480, 11480, 12], [11481, 11481, 13], [11482, 11482, 12],
    [11483, 11483, 13], [11484, 11484, 12], [11485, 11485, 13], [11486, 11486, 12],
    [11487, 11487, 13], [11488, 11488, 12], [11489, 11489, 13], [11490, 11490, 12],
    [11491, 11492, 13], [11493, 11498, 3], [11499, 11499, 12], [11500, 11500, 13],
    [11501, 11501, 12], [11502, 11502, 13], [11503, 11505, 15], [11506, 11506, 12],
    [11507, 11507, 13], [11508, 11519, 3], [11520, 11557, 13], [11558, 11558, 3],
    [11559, 11559, 13], [11560, 11564, 3], [11565, 11565, 13], [11566, 11567, 3],
    [11568, 11623, 16], [11624, 11630, 3], [11631, 11631, 16], [11632, 11646, 3],
    [11647, 11647, 15], [11648, 11670, 16], [11671, 11679, 3], [11680, 11686, 16],
    [11687, 11687, 3], [11688, 11694, 16], [11695, 11695, 3], [11696, 11702, 16],
    [11703, 11703, 3], [11704, 11710, 16], [11711, 11711, 3], [11712, 11718, 16],
    [11719, 11719, 3], [11720, 11726, 16], [11727, 11727, 3], [11728, 11734, 16],
    [11735, 11735, 3], [11736, 11742, 16], [11743, 11743, 3], [11744, 11775, 15],
    [11776, 11789, 8], [11790, 11803, 3], [11804, 11805, 8], [11806, 11807, 3],
    [11808, 11817, 8], [11818, 11821, 3], [11822, 11822, 7], [11823, 11823, 16],
    [11824, 11835, 3], [11836, 11836, 7], [11837, 11841, 3], [11842, 11842, 8],
    [11843, 12287, 3], [12288, 12288, 4], [12289, 12289, 9], [12290, 12290, 7],
    [12291, 12292, 3], [12293, 12295, 16], [12296, 12305, 8], [12306, 12307, 3],
    [12308, 12315, 8], [12316, 12316, 3], [12317, 12319, 8], [12320, 12320, 3],
    [12321, 12329, 16], [12330, 12335, 15], [12336, 12336, 3], [12337, 12341, 16],
    [12342, 12343, 3], [12344, 12348, 16], [12349, 12352, 3], [12353, 12438, 16],
    [12439, 12440, 3], [12441, 12442, 15], [12443, 12444, 3], [12445, 12447, 16],
    [12448, 12448, 3], [12449, 12538, 16], [12539, 12539, 3], [12540, 12543, 16],
    [12544, 12548, 3], [12549, 12591, 16], [12592, 12592, 3], [12593, 12686, 16],
    [12687, 12703, 3], [12704, 12735, 16], [12736, 12783, 3], [12784, 12799, 16],
    [12800, 13311, 3], [13312, 19903, 16], [19904, 19967, 3], [19968, 40956, 16],
    [40957, 40959, 3], [40960, 42124, 16], [42125, 42191, 3], [42192, 42237, 16],
    [42238, 42238, 3], [42239, 42239, 7], [42240, 42508, 16], [42509, 42509, 3],
    [42510, 42511, 7], [42512, 42527, 16], [42528, 42537, 11], [42538, 42539, 16],
    [42540, 42559, 3], [42560, 42560, 12], [42561, 42561, 13], [42562, 42562, 12],
    [42563, 42563, 13], [42564, 42564, 12], [42565, 42565, 13], [42566, 42566, 12],
    [42567, 42567, 13], [42568, 42568, 12], [42569, 42569, 13], [42570, 42570, 12],
    [42571, 42571, 13], [42572, 42572, 12], [42573, 42573, 13], [42574, 42574, 12],
    [42575, 42575, 13], [42576, 42576, 12], [42577, 42577, 13], [42578, 42578, 12],
    [42579, 42579, 13], [42580, 42580, 12], [42581, 42581, 13], [42582, 42582, 12],
    [42583, 42583, 13], [42584, 42584, 12], [42585, 42585, 13], [42586, 42586, 12],
    [42587, 42587, 13], [42588, 42588, 12], [42589, 42589, 13], [42590, 42590, 12],
    [42591, 42591, 13], [42592, 42592, 12], [42593, 42593, 13], [42594, 42594, 12],
    [42595, 42595, 13], [42596, 42596, 12], [42597, 42597, 13], [42598, 42598, 12],
    [42599, 42599, 13], [42600, 42600, 12], [42601, 42601, 13], [42602, 42602, 12],
    [42603, 42603, 13], [42604, 42604, 12], [42605, 42605, 13], [42606, 42606, 16],
    [42607, 42610, 15], [42611, 42611, 3], [42612, 42621, 15], [42622, 42622, 3],
    [42623, 42623, 16], [42624, 42624, 12], [42625, 42625, 13], [42626, 42626, 12],
    [42627, 42627, 13], [42628, 42628, 12], [42629, 42629, 13], [42630, 42630, 12],
    [42631, 42631, 13], [42632, 42632, 12], [42633, 42633, 13], [42634, 42634, 12],
    [42635, 42635, 13], [42636, 42636, 12], [42637, 42637, 13], [42638, 42638, 12],
    [42639, 42639, 13], [42640, 42640, 12], [42641, 42641, 13], [42642, 42642, 12],
    [42643, 42643, 13], [42644, 42644, 12], [42645, 42645, 13], [42646, 42646, 12],
    [42647, 42647, 13], [42648, 42648, 12], [42649, 42649, 13], [42650, 42650, 12],
    [42651, 42653, 13], [42654, 42655, 15], [42656, 42735, 16], [42736, 42737, 15],
    [42738, 42738, 3], [42739, 42739, 7], [42740, 42742, 3], [42743, 42743, 7],
    [42744, 42774, 3], [42775, 42783, 16], [42784, 42785, 3], [42786, 42786, 12],
    [42787, 42787, 13], [42788, 42788, 12], [42789, 42789, 13], [42790, 42790, 12],
    [42791, 42791, 13], [42792, 42792, 12], [42793, 42793, 13], [42794, 42794, 12],
    [42795, 42795, 13], [42796, 42796, 12], [42797, 42797, 13], [42798, 42798, 12],
    [42799, 42801, 13], [42802, 42802, 12], [42803, 42803, 13], [42804, 42804, 12],
    [42805, 42805, 13], [42806, 42806, 12], [42807, 42807, 13], [42808, 42808, 12],
    [42809, 42809, 13], [42810, 42810, 12], [42811, 42811, 13], [42812, 42812, 12],
    [42813, 42813, 13], [42814, 42814, 12], [42815, 42815, 13], [42816, 42816, 12],
    [42817, 42817, 13], [42818, 42818, 12], [42819, 42819, 13], [42820, 42820, 12],
    [42821, 42821, 13], [42822, 42822, 12], [42823, 42823, 13], [42824, 42824, 12],
    [42825, 42825, 13], [42826, 42826, 12], [42827, 42827, 13], [42828, 42828, 12],
    [42829, 42829, 13], [42830, 42830, 12], [42831, 42831, 13], [42832, 42832, 12],
    [42833, 42833, 13], [42834, 42834, 12], [42835, 42835, 13], [42836, 42836, 12],
    [42837, 42837, 13], [42838, 42838, 12], [42839, 42839, 13], [42840, 42840, 12],
    [42841, 42841, 13], [42842, 42842, 12], [42843, 42843, 13], [42844, 42844, 12],
    [42845, 42845, 13], [42846, 42846, 12], [42847, 42847, 13], [42848, 42848, 12],
    [42849, 42849, 13], [42850, 42850, 12], [42851, 42851, 13], [42852, 42852, 12],
    [42853, 42853, 13], [42854, 42854, 12], [42855, 42855, 13], [42856, 42856, 12],
    [42857, 42857, 13], [42858, 42858, 12], [42859, 42859, 13], [42860, 42860, 12],
    [42861, 42861, 13], [42862, 42862, 12], [42863, 42872, 13], [42873, 42873, 12],
    [42874, 42874, 13], [42875, 42875, 12], [42876, 42876, 13], [42877, 42878, 12],
    [42879, 42879, 13], [42880, 42880, 12], [42881, 42881, 13], [42882, 42882, 12],
    [42883, 42883, 13], [42884, 42884, 12], [42885, 42885, 13], [42886, 42886, 12],
    [42887, 42887, 13], [42888, 42888, 16], [42889, 42890, 3], [42891, 42891, 12],
    [42892, 42892, 13], [42893, 42893, 12], [42894, 42894, 13], [42895, 42895, 16],
    [42896, 42896, 12], [42897, 42897, 13], [42898, 42898, 12], [42899, 42901, 13],
    [42902, 42902, 12], [42903, 42903, 13], [42904, 42904, 12], [42905, 42905, 13],
    [42906, 42906, 12], [42907, 42907, 13], [42908, 42908, 12], [42909, 42909, 13],
    [42910, 42910, 12], [42911, 42911, 13], [42912, 42912, 12], [42913, 42913, 13],
    [42914, 42914, 12], [42915, 42915, 13], [42916, 42916, 12], [42917, 42917, 13],
    [42918, 42918, 12], [42919, 42919, 13], [42920, 42920, 12], [42921, 42921, 13],
    [42922, 42926, 12], [42927, 42927, 13], [42928, 42932, 12], [42933, 42933, 13],
    [42934, 42934, 12], [42935, 42935, 13], [42936, 42936, 12], [42937, 42937, 13],
    [42938, 42938, 12], [42939, 42939, 13], [42940, 42940, 12], [42941, 42941, 13],
    [42942, 42942, 12], [42943, 42943, 13], [42944, 42945, 3], [42946, 42946, 12],
    [42947, 42947, 13], [42948, 42951, 12], [42952, 42952, 13], [42953, 42953, 12],
    [42954, 42954, 13], [42955, 42996, 3], [42997, 42997, 12], [42998, 42998, 13],
    [42999, 42999, 16], [43000, 43002, 13], [43003, 43009, 16], [43010, 43010, 15],
    [43011, 43013, 16], [43014, 43014, 15], [43015, 43018, 16], [43019, 43019, 15],
    [43020, 43042, 16], [43043, 43047, 15], [43048, 43051, 3], [43052, 43052, 15],
    [43053, 43071, 3], [43072, 43123, 16], [43124, 43125, 3], [43126, 43127, 7],
    [43128, 43135, 3], [43136, 43137, 15], [43138, 43187, 16], [43188, 43205, 15],
    [43206, 43213, 3], [43214, 43215, 7], [43216, 43225, 11], [43226, 43231, 3],
    [43232, 43249, 15], [43250, 43255, 16], [43256, 43258, 3], [43259, 43259, 16],
    [43260, 43260, 3], [43261, 43262, 16], [43263, 43263, 15], [43264, 43273, 11],
    [43274, 43301, 16], [43302, 43309, 15], [43310, 43310, 3], [43311, 43311, 7],
    [43312, 43334, 16], [43335, 43347, 15], [43348, 43359, 3], [43360, 43388, 16],
    [43389, 43391, 3], [43392, 43395, 15], [43396, 43442, 16], [43443, 43456, 15],
    [43457, 43463, 3], [43464, 43465, 7], [43466, 43470, 3], [43471, 43471, 16],
    [43472, 43481, 11], [43482, 43487, 3], [43488, 43492, 16], [43493, 43493, 15],
    [43494, 43503, 16], [43504, 43513, 11], [43514, 43518, 16], [43519, 43519, 3],
    [43520, 43560, 16], [43561, 43574, 15], [43575, 43583, 3], [43584, 43586, 16],
    [43587, 43587, 15], [43588, 43595, 16], [43596, 43597, 15], [43598, 43599, 3],
    [43600, 43609, 11], [43610, 43612, 3], [43613, 43615, 7], [43616, 43638, 16],
    [43639, 43641, 3], [43642, 43642, 16], [43643, 43645, 15], [43646, 43695, 16],
    [43696, 43696, 15], [43697, 43697, 16], [43698, 43700, 15], [43701, 43702, 16],
    [43703, 43704, 15], [43705, 43709, 16], [43710, 43711, 15], [43712, 43712, 16],
    [43713, 43713, 15], [43714, 43714, 16], [43715, 43738, 3], [43739, 43741, 16],
    [43742, 43743, 3], [43744, 43754, 16], [43755, 43759, 15], [43760, 43761, 7],
    [43762, 43764, 16], [43765, 43766, 15], [43767, 43776, 3], [43777, 43782, 16],
    [43783, 43784, 3], [43785, 43790, 16], [43791, 43792, 3], [43793, 43798, 16],
    [43799, 43807, 3], [43808, 43814, 16], [43815, 43815, 3], [43816, 43822, 16],
    [43823, 43823, 3], [43824, 43866, 13], [43867, 43867, 3], [43868, 43880, 13],
    [43881, 43881, 16], [43882, 43887, 3], [43888, 43967, 13], [43968, 44002, 16],
    [44003, 44010, 15], [44011, 44011, 7], [44012, 44013, 15], [44014, 44015, 3],
    [44016, 44025, 11], [44026, 44031, 3], [44032, 55203, 16], [55204, 55215, 3],
    [55216, 55238, 16], [55239, 55242, 3], [55243, 55291, 16], [55292, 63743, 3],
    [63744, 64109, 16], [64110, 64111, 3], [64112, 64217, 16], [64218, 64255, 3],
    [64256, 64262, 13], [64263, 64274, 3], [64275, 64279, 13], [64280, 64284, 3],
    [64285, 64285, 16], [64286, 64286, 15], [64287, 64296, 16], [64297, 64297, 3],
    [64298, 64310, 16], [64311, 64311, 3], [64312, 64316, 16], [64317, 64317, 3],
    [64318, 64318, 16], [64319, 64319, 3], [64320, 64321, 16], [64322, 64322, 3],
    [64323, 64324, 16], [64325, 64325, 3], [64326, 64433, 16], [64434, 64466, 3],
    [64467, 64829, 16], [64830, 64831, 8], [64832, 64847, 3], [64848, 64911, 16],
    [64912, 64913, 3], [64914, 64967, 16], [64968, 65007, 3], [65008, 65019, 16],
    [65020, 65023, 3], [65024, 65039, 15], [65040, 65041, 9], [65042, 65042, 3],
    [65043, 65043, 9], [65044, 65046, 3], [65047, 65048, 8], [65049, 65055, 3],
    [65056, 65071, 15], [65072, 65072, 3], [65073, 65074, 9], [65075, 65076, 3],
    [65077, 65092, 8], [65093, 65094, 3], [65095, 65096, 8], [65097, 65103, 3],
    [65104, 65105, 9], [65106, 65106, 10], [65107, 65108, 3], [65109, 65109, 9],
    [65110, 65111, 7], [65112, 65112, 9], [65113, 65118, 8], [65119, 65122, 3],
    [65123, 65123, 9], [65124, 65135, 3], [65136, 65140, 16], [65141, 65141, 3],
    [65142, 65276, 16], [65277, 65278, 3], [65279, 65279, 15], [65280, 65280, 3],
    [65281, 65281, 7], [65282, 65287, 3], [65288, 65289, 8], [65290, 65291, 3],
    [65292, 65293, 9], [65294, 65294, 10], [65295, 65295, 3], [65296, 65305, 11],
    [65306, 65306, 9], [65307, 65310, 3], [65311, 65311, 7], [65312, 65312, 3],
    [65313, 65338, 12], [65339, 65339, 8], [65340, 65340, 3], [65341, 65341, 8],
    [65342, 65344, 3], [65345, 65370, 13], [65371, 65371, 8], [65372, 65372, 3],
    [65373, 65373, 8], [65374, 65374, 3], [65375, 65376, 8], [65377, 65377, 7],
    [65378, 65379, 8], [65380, 65380, 9], [65381, 65381, 3], [65382, 65437, 16],
    [65438, 65439, 15], [65440, 65470, 16], [65471, 65473, 3], [65474, 65479, 16],
    [65480, 65481, 3], [65482, 65487, 16], [65488, 65489, 3], [65490, 65495, 16],
    [65496, 65497, 3], [65498, 65500, 16], [65501, 65528, 3], [65529, 65531, 15],
    [65532, 65535, 3], [65536, 65547, 16], [65548, 65548, 3], [65549, 65574, 16],
    [65575, 65575, 3], [65576, 65594, 16], [65595, 65595, 3], [65596, 65597, 16],
    [65598, 65598, 3], [65599, 65613, 16], [65614, 65615, 3], [65616, 65629, 16],
    [65630, 65663, 3], [65664, 65786, 16], [65787, 65855, 3], [65856, 65908, 16],
    [65909, 66044, 3], [66045, 66045, 15], [66046, 66175, 3], [66176, 66204, 16],
    [66205, 66207, 3], [66208, 66256, 16], [66257, 66271, 3], [66272, 66272, 15],
    [66273, 66303, 3], [66304, 66335, 16], [66336, 66348, 3], [66349, 66378, 16],
    [66379, 66383, 3], [66384, 66421, 16], [66422, 66426, 15], [66427, 66431, 3],
    [66432, 66461, 16], [66462, 66463, 3], [66464, 66499, 16], [66500, 66503, 3],
    [66504, 66511, 16], [66512, 66512, 3], [66513, 66517, 16], [66518, 66559, 3],
    [66560, 66599, 12], [66600, 66639, 13], [66640, 66717, 16], [66718, 66719, 3],
    [66720, 66729, 11], [66730, 66735, 3], [66736, 66771, 12], [66772, 66775, 3],
    [66776, 66811, 13], [66812, 66815, 3], [66816, 66855, 16], [66856, 66863, 3],
    [66864, 66915, 16], [66916, 67071, 3], [67072, 67382, 16], [67383, 67391, 3],
    [67392, 67413, 16], [67414, 67423, 3], [67424, 67431, 16], [67432, 67583, 3],
    [67584, 67589, 16], [67590, 67591, 3], [67592, 67592, 16], [67593, 67593, 3],
    [67594, 67637, 16], [67638, 67638, 3], [67639, 67640, 16], [67641, 67643, 3],
    [67644, 67644, 16], [67645, 67646, 3], [67647, 67669, 16], [67670, 67679, 3],
    [67680, 67702, 16], [67703, 67711, 3], [67712, 67742, 16], [67743, 67807, 3],
    [67808, 67826, 16], [67827, 67827, 3], [67828, 67829, 16], [67830, 67839, 3],
    [67840, 67861, 16], [67862, 67871, 3], [67872, 67897, 16], [67898, 67967, 3],
    [67968, 68023, 16], [68024, 68029, 3], [68030, 68031, 16], [68032, 68095, 3],
    [68096, 68096, 16], [68097, 68099, 15], [68100, 68100, 3], [68101, 68102, 15],
    [68103, 68107, 3], [68108, 68111, 15], [68112, 68115, 16], [68116, 68116, 3],
    [68117, 68119, 16], [68120, 68120, 3], [68121, 68149, 16], [68150, 68151, 3],
    [68152, 68154, 15], [68155, 68158, 3], [68159, 68159, 15], [68160, 68181, 3],
    [68182, 68183, 7], [68184, 68191, 3], [68192, 68220, 16], [68221, 68223, 3],
    [68224, 68252, 16], [68253, 68287, 3], [68288, 68295, 16], [68296, 68296, 3],
    [68297, 68324, 16], [68325, 68326, 15], [68327, 68351, 3], [68352, 68405, 16],
    [68406, 68415, 3], [68416, 68437, 16], [68438, 68447, 3], [68448, 68466, 16],
    [68467, 68479, 3], [68480, 68497, 16], [68498, 68607, 3], [68608, 68680, 16],
    [68681, 68735, 3], [68736, 68786, 12], [68787, 68799, 3], [68800, 68850, 13],
    [68851, 68863, 3], [68864, 68899, 16], [68900, 68903, 15], [68904, 68911, 3],
    [68912, 68921, 11], [68922, 69247, 3], [69248, 69289, 16], [69290, 69290, 3],
    [69291, 69292, 15], [69293, 69295, 3], [69296, 69297, 16], [69298, 69375, 3],
    [69376, 69404, 16], [69405, 69414, 3], [69415, 69415, 16], [69416, 69423, 3],
    [69424, 69445, 16], [69446, 69456, 15], [69457, 69460, 3], [69461, 69465, 7],
    [69466, 69551, 3], [69552, 69572, 16], [69573, 69599, 3], [69600, 69622, 16],
    [69623, 69631, 3], [69632, 69634, 15], [69635, 69687, 16], [69688, 69702, 15],
    [69703, 69704, 7], [69705, 69733, 3], [69734, 69743, 11], [69744, 69758, 3],
    [69759, 69762, 15], [69763, 69807, 16], [69808, 69818, 15], [69819, 69820, 3],
    [69821, 69821, 15], [69822, 69825, 7], [69826, 69836, 3], [69837, 69837, 15],
    [69838, 69839, 3], [69840, 69864, 16], [69865, 69871, 3], [69872, 69881, 11],
    [69882, 69887, 3], [69888, 69890, 15], [69891, 69926, 16], [69927, 69940, 15],
    [69941, 69941, 3], [69942, 69951, 11], [69952, 69952, 3], [69953, 69955, 7],
    [69956, 69956, 16], [69957, 69958, 15], [69959, 69959, 16], [69960, 69967, 3],
    [69968, 70002, 16], [70003, 70003, 15], [70004, 70005, 3], [70006, 70006, 16],
    [70007, 70015, 3], [70016, 70018, 15], [70019, 70066, 16], [70067, 70080, 15],
    [70081, 70084, 16], [70085, 70086, 7], [70087, 70088, 3], [70089, 70092, 15],
    [70093, 70093, 7], [70094, 70095, 15], [70096, 70105, 11], [70106, 70106, 16],
    [70107, 70107, 3], [70108, 70108, 16], [70109, 70109, 3], [70110, 70111, 7],
    [70112, 70143, 3], [70144, 70161, 16], [70162, 70162, 3], [70163, 70187, 16],
    [70188, 70199, 15], [70200, 70201, 7], [70202, 70202, 3], [70203, 70204, 7],
    [70205, 70205, 3], [70206, 70206, 15], [70207, 70271, 3], [70272, 70278, 16],
    [70279, 70279, 3], [70280, 70280, 16], [70281, 70281, 3], [70282, 70285, 16],
    [70286, 70286, 3], [70287, 70301, 16], [70302, 70302, 3], [70303, 70312, 16],
    [70313, 70313, 7], [70314, 70319, 3], [70320, 70366, 16], [70367, 70378, 15],
    [70379, 70383, 3], [70384, 70393, 11], [70394, 70399, 3], [70400, 70403, 15],
    [70404, 70404, 3], [70405, 70412, 16], [70413, 70414, 3], [70415, 70416, 16],
    [70417, 70418, 3], [70419, 70440, 16], [70441, 70441, 3], [70442, 70448, 16],
    [70449, 70449, 3], [70450, 70451, 16], [70452, 70452, 3], [70453, 70457, 16],
    [70458, 70458, 3], [70459, 70460, 15], [70461, 70461, 16], [70462, 70468, 15],
    [70469, 70470, 3], [70471, 70472, 15], [70473, 70474, 3], [70475, 70477, 15],
    [70478, 70479, 3], [70480, 70480, 16], [70481, 70486, 3], [70487, 70487, 15],
    [70488, 70492, 3], [70493, 70497, 16], [70498, 70499, 15], [70500, 70501, 3],
    [70502, 70508, 15], [70509, 70511, 3], [70512, 70516, 15], [70517, 70655, 3],
    [70656, 70708, 16], [70709, 70726, 15], [70727, 70730, 16], [70731, 70732, 7],
    [70733, 70735, 3], [70736, 70745, 11], [70746, 70749, 3], [70750, 70750, 15],
    [70751, 70753, 16], [70754, 70783, 3], [70784, 70831, 16], [70832, 70851, 15],
    [70852, 70853, 16], [70854, 70854, 3], [70855, 70855, 16], [70856, 70863, 3],
    [70864, 70873, 11], [70874, 71039, 3], [71040, 71086, 16], [71087, 71093, 15],
    [71094, 71095, 3], [71096, 71104, 15], [71105, 71105, 3], [71106, 71107, 7],
    [71108, 71112, 3], [71113, 71127, 7], [71128, 71131, 16], [71132, 71133, 15],
    [71134, 71167, 3], [71168, 71215, 16], [71216, 71232, 15], [71233, 71234, 7],
    [71235, 71235, 3], [71236, 71236, 16], [71237, 71247, 3], [71248, 71257, 11],
    [71258, 71295, 3], [71296, 71338, 16], [71339, 71351, 15], [71352, 71352, 16],
    [71353, 71359, 3], [71360, 71369, 11], [71370, 71423, 3], [71424, 71450, 16],
    [71451, 71452, 3], [71453, 71467, 15], [71468, 71471, 3], [71472, 71481, 11],
    [71482, 71483, 3], [71484, 71486, 7], [71487, 71679, 3], [71680, 71723, 16],
    [71724, 71738, 15], [71739, 71839, 3], [71840, 71871, 12], [71872, 71903, 13],
    [71904, 71913, 11], [71914, 71934, 3], [71935, 71942, 16], [71943, 71944, 3],
    [71945, 71945, 16], [71946, 71947, 3], [71948, 71955, 16], [71956, 71956, 3],
    [71957, 71958, 16], [71959, 71959, 3], [71960, 71983, 16], [71984, 71989, 15],
    [71990, 71990, 3], [71991, 71992, 15], [71993, 71994, 3], [71995, 71998, 15],
    [71999, 71999, 16], [72000, 72000, 15], [72001, 72001, 16], [72002, 72003, 15],
    [72004, 72004, 7], [72005, 72005, 3], [72006, 72006, 7], [72007, 72015, 3],
    [72016, 72025, 11], [72026, 72095, 3], [72096, 72103, 16], [72104, 72105, 3],
    [72106, 72144, 16], [72145, 72151, 15], [72152, 72153, 3], [72154, 72160, 15],
    [72161, 72161, 16], [72162, 72162, 3], [72163, 72163, 16], [72164, 72164, 15],
    [72165, 72191, 3], [72192, 72192, 16], [72193, 72202, 15], [72203, 72242, 16],
    [72243, 72249, 15], [72250, 72250, 16], [72251, 72254, 15], [72255, 72257, 3],
    [72258, 72259, 7], [72260, 72262, 3], [72263, 72263, 15], [72264, 72271, 3],
    [72272, 72272, 16], [72273, 72283, 15], [72284, 72329, 16], [72330, 72345, 15],
    [72346, 72346, 3], [72347, 72348, 7], [72349, 72349, 16], [72350, 72383, 3],
    [72384, 72440, 16], [72441, 72703, 3], [72704, 72712, 16], [72713, 72713, 3],
    [72714, 72750, 16], [72751, 72758, 15], [72759, 72759, 3], [72760, 72767, 15],
    [72768, 72768, 16], [72769, 72770, 7], [72771, 72783, 3], [72784, 72793, 11],
    [72794, 72817, 3], [72818, 72847, 16], [72848, 72849, 3], [72850, 72871, 15],
    [72872, 72872, 3], [72873, 72886, 15], [72887, 72959, 3], [72960, 72966, 16],
    [72967, 72967, 3], [72968, 72969, 16], [72970, 72970, 3], [72971, 73008, 16],
    [73009, 73014, 15], [73015, 73017, 3], [73018, 73018, 15], [73019, 73019, 3],
    [73020, 73021, 15], [73022, 73022, 3], [73023, 73029, 15], [73030, 73030, 16],
    [73031, 73031, 15], [73032, 73039, 3], [73040, 73049, 11], [73050, 73055, 3],
    [73056, 73061, 16], [73062, 73062, 3], [73063, 73064, 16], [73065, 73065, 3],
    [73066, 73097, 16], [73098, 73102, 15], [73103, 73103, 3], [73104, 73105, 15],
    [73106, 73106, 3], [73107, 73111, 15], [73112, 73112, 16], [73113, 73119, 3],
    [73120, 73129, 11], [73130, 73439, 3], [73440, 73458, 16], [73459, 73462, 15],
    [73463, 73464, 7], [73465, 73647, 3], [73648, 73648, 16], [73649, 73727, 3],
    [73728, 74649, 16], [74650, 74751, 3], [74752, 74862, 16], [74863, 74879, 3],
    [74880, 75075, 16], [75076, 77823, 3], [77824, 78894, 16], [78895, 78895, 3],
    [78896, 78904, 15], [78905, 82943, 3], [82944, 83526, 16], [83527, 92159, 3],
    [92160, 92728, 16], [92729, 92735, 3], [92736, 92766, 16], [92767, 92767, 3],
    [92768, 92777, 11], [92778, 92781, 3], [92782, 92783, 7], [92784, 92879, 3],
    [92880, 92909, 16], [92910, 92911, 3], [92912, 92916, 15], [92917, 92917, 7],
    [92918, 92927, 3], [92928, 92975, 16], [92976, 92982, 15], [92983, 92984, 7],
    [92985, 92991, 3], [92992, 92995, 16], [92996, 92996, 7], [92997, 93007, 3],
    [93008, 93017, 11], [93018, 93026, 3], [93027, 93047, 16], [93048, 93052, 3],
    [93053, 93071, 16], [93072, 93759, 3], [93760, 93791, 12], [93792, 93823, 13],
    [93824, 93847, 3], [93848, 93848, 7], [93849, 93951, 3], [93952, 94026, 16],
    [94027, 94030, 3], [94031, 94031, 15], [94032, 94032, 16], [94033, 94087, 15],
    [94088, 94094, 3], [94095, 94098, 15], [94099, 94111, 16], [94112, 94175, 3],
    [94176, 94177, 16], [94178, 94178, 3], [94179, 94179, 16], [94180, 94180, 15],
    [94181, 94191, 3], [94192, 94193, 15], [94194, 94207, 3], [94208, 100343, 16],
    [100344, 100351, 3], [100352, 101589, 16], [101590, 101631, 3], [101632, 101640, 16],
    [101641, 110591, 3], [110592, 110878, 16], [110879, 110927, 3], [110928, 110930, 16],
    [110931, 110947, 3], [110948, 110951, 16], [110952, 110959, 3], [110960, 111355, 16],
    [111356, 113663, 3], [113664, 113770, 16], [113771, 113775, 3], [113776, 113788, 16],
    [113789, 113791, 3], [113792, 113800, 16], [113801, 113807, 3], [113808, 113817, 16],
    [113818, 113820, 3], [113821, 113822, 15], [113823, 113823, 7], [113824, 113827, 15],
    [113828, 119140, 3], [119141, 119145, 15], [119146, 119148, 3], [119149, 119170, 15],
    [119171, 119172, 3], [119173, 119179, 15], [119180, 119209, 3], [119210, 119213, 15],
    [119214, 119361, 3], [119362, 119364, 15], [119365, 119807, 3], [119808, 119833, 12],
    [119834, 119859, 13], [119860, 119885, 12], [119886, 119892, 13], [119893, 119893, 3],
    [119894, 119911, 13], [119912, 119937, 12], [119938, 119963, 13], [119964, 119964, 12],
    [119965, 119965, 3], [119966, 119967, 12], [119968, 119969, 3], [119970, 119970, 12],
    [119971, 119972, 3], [119973, 119974, 12], [119975, 119976, 3], [119977, 119980, 12],
    [119981, 119981, 3], [119982, 119989, 12], [119990, 119993, 13], [119994, 119994, 3],
    [119995, 119995, 13], [119996, 119996, 3], [119997, 120003, 13], [120004, 120004, 3],
    [120005, 120015, 13], [120016, 120041, 12], [120042, 120067, 13], [120068, 120069, 12],
    [120070, 120070, 3], [120071, 120074, 12], [120075, 120076, 3], [120077, 120084, 12],
    [120085, 120085, 3], [120086, 120092, 12], [120093, 120093, 3], [120094, 120119, 13],
    [120120, 120121, 12], [120122, 120122, 3], [120123, 120126, 12], [120127, 120127, 3],
    [120128, 120132, 12], [120133, 120133, 3], [120134, 120134, 12], [120135, 120137, 3],
    [120138, 120144, 12], [120145, 120145, 3], [120146, 120171, 13], [120172, 120197, 12],
    [120198, 120223, 13], [120224, 120249, 12], [120250, 120275, 13], [120276, 120301, 12],
    [120302, 120327, 13], [120328, 120353, 12], [120354, 120379, 13], [120380, 120405, 12],
    [120406, 120431, 13], [120432, 120457, 12], [120458, 120485, 13], [120486, 120487, 3],
    [120488, 120512, 12], [120513, 120513, 3], [120514, 120538, 13], [120539, 120539, 3],
    [120540, 120545, 13], [120546, 120570, 12], [120571, 120571, 3], [120572, 120596, 13],
    [120597, 120597, 3], [120598, 120603, 13], [120604, 120628, 12], [120629, 120629, 3],
    [120630, 120654, 13], [120655, 120655, 3], [120656, 120661, 13], [120662, 120686, 12],
    [120687, 120687, 3], [120688, 120712, 13], [120713, 120713, 3], [120714, 120719, 13],
    [120720, 120744, 12], [120745, 120745, 3], [120746, 120770, 13], [120771, 120771, 3],
    [120772, 120777, 13], [120778, 120778, 12], [120779, 120779, 13], [120780, 120781, 3],
    [120782, 120831, 11], [120832, 121343, 3], [121344, 121398, 15], [121399, 121402, 3],
    [121403, 121452, 15], [121453, 121460, 3], [121461, 121461, 15], [121462, 121475, 3],
    [121476, 121476, 15], [121477, 121479, 3], [121480, 121480, 7], [121481, 121498, 3],
    [121499, 121503, 15], [121504, 121504, 3], [121505, 121519, 15], [121520, 122879, 3],
    [122880, 122886, 15], [122887, 122887, 3], [122888, 122904, 15], [122905, 122906, 3],
    [122907, 122913, 15], [122914, 122914, 3], [122915, 122916, 15], [122917, 122917, 3],
    [122918, 122922, 15], [122923, 123135, 3], [123136, 123180, 16], [123181, 123183, 3],
    [123184, 123190, 15], [123191, 123197, 16], [123198, 123199, 3], [123200, 123209, 11],
    [123210, 123213, 3], [123214, 123214, 16], [123215, 123583, 3], [123584, 123627, 16],
    [123628, 123631, 15], [123632, 123641, 11], [123642, 124927, 3], [124928, 125124, 16],
    [125125, 125135, 3], [125136, 125142, 15], [125143, 125183, 3], [125184, 125217, 12],
    [125218, 125251, 13], [125252, 125258, 15], [125259, 125259, 16], [125260, 125263, 3],
    [125264, 125273, 11], [125274, 126463, 3], [126464, 126467, 16], [126468, 126468, 3],
    [126469, 126495, 16], [126496, 126496, 3], [126497, 126498, 16], [126499, 126499, 3],
    [126500, 126500, 16], [126501, 126502, 3], [126503, 126503, 16], [126504, 126504, 3],
    [126505, 126514, 16], [126515, 126515, 3], [126516, 126519, 16], [126520, 126520, 3],
    [126521, 126521, 16], [126522, 126522, 3], [126523, 126523, 16], [126524, 126529, 3],
    [126530, 126530, 16], [126531, 126534, 3], [126535, 126535, 16], [126536, 126536, 3],
    [126537, 126537, 16], [126538, 126538, 3], [126539, 126539, 16], [126540, 126540, 3],
    [126541, 126543, 16], [126544, 126544, 3], [126545, 126546, 16], [126547, 126547, 3],
    [126548, 126548, 16], [126549, 126550, 3], [126551, 126551, 16], [126552, 126552, 3],
    [126553, 126553, 16], [126554, 126554, 3], [126555, 126555, 16], [126556, 126556, 3],
    [126557, 126557, 16], [126558, 126558, 3], [126559, 126559, 16], [126560, 126560, 3],
    [126561, 126562, 16], [126563, 126563, 3], [126564, 126564, 16], [126565, 126566, 3],
    [126567, 126570, 16], [126571, 126571, 3], [126572, 126578, 16], [126579, 126579, 3],
    [126580, 126583, 16], [126584, 126584, 3], [126585, 126588, 16], [126589, 126589, 3],
    [126590, 126590, 16], [126591, 126591, 3], [126592, 126601, 16], [126602, 126602, 3],
    [126603, 126619, 16], [126620, 126624, 3], [126625, 126627, 16], [126628, 126628, 3],
    [126629, 126633, 16], [126634, 126634, 3], [126635, 126651, 16], [126652, 127279, 3],
    [127280, 127305, 12], [127306, 127311, 3], [127312, 127337, 12], [127338, 127343, 3],
    [127344, 127369, 12], [127370, 128629, 3], [128630, 128632, 8], [128633, 130031, 3],
    [130032, 130041, 11], [130042, 131071, 3], [131072, 173789, 16], [173790, 173823, 3],
    [173824, 177972, 16], [177973, 177983, 3], [177984, 178205, 16], [178206, 178207, 3],
    [178208, 183969, 16], [183970, 183983, 3], [183984, 191456, 16], [191457, 194559, 3],
    [194560, 195101, 16], [195102, 196607, 3], [196608, 201546, 16], [201547, 917504, 3],
    [917505, 917505, 15], [917506, 917535, 3], [917536, 917631, 15], [917632, 917759, 3],
    [917760, 917999, 15], [918000, 1114111, 3]
  ]`),

  metadata: {
    categoryCount: 17
  }
};
;RuleSet.word = {
  forwardTable: {
    flags: 0,
    table: JSON.parse(`[
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      2, 3, 3, 4, 5, 2, 2, 2, 2, 6, 2, 7, 8, 2, 9, 10, 11, 7, 12, 13,
      14, 15, 16, 17, 18, 19, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 2, 0, 20, 0, 0, 0, 0, 0, 0, 0, 13, 0, -1,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0,
      3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 0, 20, 0, 0, 0, 0, 0, 0, 0, 13, 0, -1, 0,
      2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 21, 21, 21, 6, 0, 7, 22, 0, 6,
      10, 23, 7, 0, 0, 0, 0, 0, 0, 24, 0, -1, 0, 4, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 25, 0, 25, 6, 25, 7, 26, 0, 7, 10, 27, 7, 0, 0, 0, 0,
      0, 0, 28, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      6, 0, 7, 26, 0, 8, 10, 29, 7, 0, 0, 0, 15, 0, 0, 30, 0, -1, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9, 0,
      11, 0, 0, 0, 0, 0, 0, 0, 18, 0, -1, 0, 4, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 31, 32, 0, 25, 6, 25, 7, 26, 0, 10, 10, 33, 7, 0, 0, 0, 0, 0,
      0, 34, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 2, 9, 0, 11, 7, 0, 0, 0, 0, 0, 0, 18, 0, -1, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 20,
      0, 35, 0, 14, 15, 16, 0, 13, 0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 13, 0, 36, 0, 0, 0, 0, 0, 0, 0,
      13, 0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 13, 0, 36, 0, 35, 0, 14, 15, 16, 0, 13, 0, -1, 0, 6, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 37, 0, 38, 0, 39, 0,
      35, 0, 14, 15, 16, 0, 40, 0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 41, 0, 42, 0, 35, 0, 14, 15, 16, 0, 43,
      0, -1, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 2, 0, 20, 0, 0, 0, 0, 0, 0, 17, 13, 0, -1, 0, 6, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 18, 0, 44, 0, 0,
      0, 0, 0, 0, 0, 18, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 19, 0, 45, 0, 0, 0, 0, 0, 0, 0, 46, 2,
      -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      2, 2, 0, 20, 7, 0, 0, 0, 0, 0, 0, 13, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 0, 0, 0, 0, 21, 0, 21, 0, 0, 0,
      0, 0, 0, 0, 21, 0, -1, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 6, 0, 7, 26, 0, 8, 10, 29, 7, 0, 0, 0, 15, 0, 0, 30, 0, -1,
      0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 21, 21, 21, 6, 0, 7, 22, 2,
      6, 10, 23, 7, 0, 0, 0, 0, 0, 0, 24, 0, -1, 0, 8, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 21, 21, 21, 6, 0, 7, 22, 0, 24, 10, 47, 7, 0, 0, 0,
      0, 0, 0, 24, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 7, 0, 0, 25, 10, 25, 7, 0, 0, 0, 0, 0, 0, 25, 0, -1, 0,
      4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 0, 7, 26, 0, 8,
      10, 29, 7, 0, 0, 0, 15, 0, 0, 30, 0, -1, 0, 4, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 25, 0, 25, 6, 25, 7, 26, 2, 7, 10, 27, 7, 0, 0, 0, 0,
      0, 0, 28, 0, -1, 0, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 25, 0, 25,
      6, 25, 7, 26, 0, 28, 10, 48, 7, 0, 0, 0, 0, 0, 0, 28, 0, -1, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 0, 7, 26, 2, 8, 10,
      29, 7, 0, 0, 0, 15, 0, 0, 30, 0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 6, 0, 7, 26, 0, 30, 10, 49, 7, 0, 0, 0, 15, 0,
      0, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 31, 10, 31, 0, 0, 0, 0, 0, 0, 0, 31, 0, -1, 0, 4, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 50, 10, 51,
      7, 0, 0, 0, 0, 0, 0, 52, 0, -1, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 31, 32, 0, 25, 6, 25, 7, 26, 2, 10, 10, 33, 7, 0, 0, 0, 0, 0, 0,
      34, 0, -1, 0, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 31, 32, 0, 25, 6, 25,
      7, 26, 0, 34, 10, 53, 7, 0, 0, 0, 0, 0, 0, 34, 0, -1, 0, 6, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 20, 0,
      35, 0, 14, 15, 16, 0, 13, 0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 2, 13, 0, 36, 7, 0, 0, 0, 0, 0, 0, 13,
      0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 0, 7,
      26, 0, 8, 10, 29, 7, 0, 0, 0, 15, 0, 0, 30, 0, -1, 0, 6, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 37, 0, 38, 0, 39, 0, 0,
      0, 0, 15, 0, 0, 40, 0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 37, 2, 38, 0, 39, 7, 0, 0, 0, 15, 0, 0, 40, 0,
      -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 37,
      0, 40, 0, 54, 0, 0, 0, 0, 15, 0, 0, 40, 0, -1, 0, 6, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 41, 0, 42, 0, 0, 0,
      0, 0, 0, 0, 43, 0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 41, 0, 42, 7, 0, 0, 0, 0, 0, 0, 43, 0, -1,
      0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      43, 0, 55, 0, 0, 0, 0, 0, 0, 0, 43, 0, -1, 0, 6, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 18, 0, 44, 7, 0, 0, 0,
      0, 0, 0, 18, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 2, 19, 0, 45, 7, 0, 0, 0, 0, 0, 0, 46, 2, -1, 0,
      6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 46,
      0, 56, 0, 0, 0, 0, 0, 0, 0, 46, 2, -1, 0, 8, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 21, 21, 21, 6, 0, 7, 22, 2, 24, 10, 47, 7, 0, 0, 0, 0,
      0, 0, 24, 0, -1, 0, 11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 25, 0, 25,
      6, 25, 7, 26, 2, 28, 10, 48, 7, 0, 0, 0, 0, 0, 0, 28, 0, -1, 0, 6,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 0, 7, 26, 2, 30, 10,
      49, 7, 0, 0, 0, 15, 0, 0, 30, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 50, 10, 51, 7, 0, 0, 0, 0, 0,
      0, 52, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 7, 0, 2, 50, 10, 51, 7, 0, 0, 0, 0, 0, 0, 52, 0, -1, 0, 6, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 52, 10, 57,
      7, 0, 0, 0, 0, 0, 0, 52, 0, -1, 0, 11, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 31, 32, 0, 25, 6, 25, 7, 26, 2, 34, 10, 53, 7, 0, 0, 0, 0, 0, 0,
      34, 0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 37, 2, 40, 0, 54, 7, 0, 0, 0, 15, 0, 0, 40, 0, -1, 0, 6, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 43, 0, 55, 7,
      0, 0, 0, 0, 0, 0, 43, 0, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 2, 46, 0, 56, 7, 0, 0, 0, 0, 0, 0, 46,
      2, -1, 0, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7,
      0, 2, 52, 10, 57, 7, 0, 0, 0, 0, 0, 0, 52, 0, 0, 0
    ]`)
  },

  categoryTable: JSON.parse(`[
    [0, 9, 3], [10, 10, 4], [11, 12, 5], [13, 13, 6],
    [14, 31, 3], [32, 32, 7], [33, 33, 3], [34, 34, 8],
    [35, 38, 3], [39, 39, 9], [40, 43, 3], [44, 44, 10],
    [45, 45, 3], [46, 46, 11], [47, 47, 3], [48, 57, 12],
    [58, 58, 13], [59, 59, 10], [60, 64, 3], [65, 90, 14],
    [91, 94, 3], [95, 95, 15], [96, 96, 3], [97, 122, 14],
    [123, 132, 3], [133, 133, 5], [134, 168, 3], [169, 169, 16],
    [170, 170, 14], [171, 172, 3], [173, 173, 17], [174, 174, 16],
    [175, 180, 3], [181, 181, 14], [182, 182, 3], [183, 183, 13],
    [184, 185, 3], [186, 186, 14], [187, 191, 3], [192, 214, 14],
    [215, 215, 3], [216, 246, 14], [247, 247, 3], [248, 727, 14],
    [728, 733, 3], [734, 767, 14], [768, 879, 17], [880, 884, 14],
    [885, 885, 3], [886, 887, 14], [888, 889, 3], [890, 893, 14],
    [894, 894, 10], [895, 895, 14], [896, 901, 3], [902, 902, 14],
    [903, 903, 13], [904, 906, 14], [907, 907, 3], [908, 908, 14],
    [909, 909, 3], [910, 929, 14], [930, 930, 3], [931, 1013, 14],
    [1014, 1014, 3], [1015, 1153, 14], [1154, 1154, 3], [1155, 1161, 17],
    [1162, 1327, 14], [1328, 1328, 3], [1329, 1366, 14], [1367, 1368, 3],
    [1369, 1372, 14], [1373, 1373, 3], [1374, 1374, 14], [1375, 1375, 13],
    [1376, 1416, 14], [1417, 1417, 10], [1418, 1418, 14], [1419, 1424, 3],
    [1425, 1469, 17], [1470, 1470, 3], [1471, 1471, 17], [1472, 1472, 3],
    [1473, 1474, 17], [1475, 1475, 3], [1476, 1477, 17], [1478, 1478, 3],
    [1479, 1479, 17], [1480, 1487, 3], [1488, 1514, 18], [1515, 1518, 3],
    [1519, 1522, 18], [1523, 1523, 14], [1524, 1524, 13], [1525, 1535, 3],
    [1536, 1541, 17], [1542, 1547, 3], [1548, 1549, 10], [1550, 1551, 3],
    [1552, 1562, 17], [1563, 1563, 3], [1564, 1564, 17], [1565, 1567, 3],
    [1568, 1610, 14], [1611, 1631, 17], [1632, 1641, 12], [1642, 1642, 3],
    [1643, 1643, 12], [1644, 1644, 10], [1645, 1645, 3], [1646, 1647, 14],
    [1648, 1648, 17], [1649, 1747, 14], [1748, 1748, 3], [1749, 1749, 14],
    [1750, 1757, 17], [1758, 1758, 3], [1759, 1764, 17], [1765, 1766, 14],
    [1767, 1768, 17], [1769, 1769, 3], [1770, 1773, 17], [1774, 1775, 14],
    [1776, 1785, 12], [1786, 1788, 14], [1789, 1790, 3], [1791, 1791, 14],
    [1792, 1806, 3], [1807, 1807, 17], [1808, 1808, 14], [1809, 1809, 17],
    [1810, 1839, 14], [1840, 1866, 17], [1867, 1868, 3], [1869, 1957, 14],
    [1958, 1968, 17], [1969, 1969, 14], [1970, 1983, 3], [1984, 1993, 12],
    [1994, 2026, 14], [2027, 2035, 17], [2036, 2037, 14], [2038, 2039, 3],
    [2040, 2040, 10], [2041, 2041, 3], [2042, 2042, 14], [2043, 2044, 3],
    [2045, 2045, 17], [2046, 2047, 3], [2048, 2069, 14], [2070, 2073, 17],
    [2074, 2074, 14], [2075, 2083, 17], [2084, 2084, 14], [2085, 2087, 17],
    [2088, 2088, 14], [2089, 2093, 17], [2094, 2111, 3], [2112, 2136, 14],
    [2137, 2139, 17], [2140, 2143, 3], [2144, 2154, 14], [2155, 2207, 3],
    [2208, 2228, 14], [2229, 2229, 3], [2230, 2247, 14], [2248, 2258, 3],
    [2259, 2307, 17], [2308, 2361, 14], [2362, 2364, 17], [2365, 2365, 14],
    [2366, 2383, 17], [2384, 2384, 14], [2385, 2391, 17], [2392, 2401, 14],
    [2402, 2403, 17], [2404, 2405, 3], [2406, 2415, 12], [2416, 2416, 3],
    [2417, 2432, 14], [2433, 2435, 17], [2436, 2436, 3], [2437, 2444, 14],
    [2445, 2446, 3], [2447, 2448, 14], [2449, 2450, 3], [2451, 2472, 14],
    [2473, 2473, 3], [2474, 2480, 14], [2481, 2481, 3], [2482, 2482, 14],
    [2483, 2485, 3], [2486, 2489, 14], [2490, 2491, 3], [2492, 2492, 17],
    [2493, 2493, 14], [2494, 2500, 17], [2501, 2502, 3], [2503, 2504, 17],
    [2505, 2506, 3], [2507, 2509, 17], [2510, 2510, 14], [2511, 2518, 3],
    [2519, 2519, 17], [2520, 2523, 3], [2524, 2525, 14], [2526, 2526, 3],
    [2527, 2529, 14], [2530, 2531, 17], [2532, 2533, 3], [2534, 2543, 12],
    [2544, 2545, 14], [2546, 2555, 3], [2556, 2556, 14], [2557, 2557, 3],
    [2558, 2558, 17], [2559, 2560, 3], [2561, 2563, 17], [2564, 2564, 3],
    [2565, 2570, 14], [2571, 2574, 3], [2575, 2576, 14], [2577, 2578, 3],
    [2579, 2600, 14], [2601, 2601, 3], [2602, 2608, 14], [2609, 2609, 3],
    [2610, 2611, 14], [2612, 2612, 3], [2613, 2614, 14], [2615, 2615, 3],
    [2616, 2617, 14], [2618, 2619, 3], [2620, 2620, 17], [2621, 2621, 3],
    [2622, 2626, 17], [2627, 2630, 3], [2631, 2632, 17], [2633, 2634, 3],
    [2635, 2637, 17], [2638, 2640, 3], [2641, 2641, 17], [2642, 2648, 3],
    [2649, 2652, 14], [2653, 2653, 3], [2654, 2654, 14], [2655, 2661, 3],
    [2662, 2671, 12], [2672, 2673, 17], [2674, 2676, 14], [2677, 2677, 17],
    [2678, 2688, 3], [2689, 2691, 17], [2692, 2692, 3], [2693, 2701, 14],
    [2702, 2702, 3], [2703, 2705, 14], [2706, 2706, 3], [2707, 2728, 14],
    [2729, 2729, 3], [2730, 2736, 14], [2737, 2737, 3], [2738, 2739, 14],
    [2740, 2740, 3], [2741, 2745, 14], [2746, 2747, 3], [2748, 2748, 17],
    [2749, 2749, 14], [2750, 2757, 17], [2758, 2758, 3], [2759, 2761, 17],
    [2762, 2762, 3], [2763, 2765, 17], [2766, 2767, 3], [2768, 2768, 14],
    [2769, 2783, 3], [2784, 2785, 14], [2786, 2787, 17], [2788, 2789, 3],
    [2790, 2799, 12], [2800, 2808, 3], [2809, 2809, 14], [2810, 2815, 17],
    [2816, 2816, 3], [2817, 2819, 17], [2820, 2820, 3], [2821, 2828, 14],
    [2829, 2830, 3], [2831, 2832, 14], [2833, 2834, 3], [2835, 2856, 14],
    [2857, 2857, 3], [2858, 2864, 14], [2865, 2865, 3], [2866, 2867, 14],
    [2868, 2868, 3], [2869, 2873, 14], [2874, 2875, 3], [2876, 2876, 17],
    [2877, 2877, 14], [2878, 2884, 17], [2885, 2886, 3], [2887, 2888, 17],
    [2889, 2890, 3], [2891, 2893, 17], [2894, 2900, 3], [2901, 2903, 17],
    [2904, 2907, 3], [2908, 2909, 14], [2910, 2910, 3], [2911, 2913, 14],
    [2914, 2915, 17], [2916, 2917, 3], [2918, 2927, 12], [2928, 2928, 3],
    [2929, 2929, 14], [2930, 2945, 3], [2946, 2946, 17], [2947, 2947, 14],
    [2948, 2948, 3], [2949, 2954, 14], [2955, 2957, 3], [2958, 2960, 14],
    [2961, 2961, 3], [2962, 2965, 14], [2966, 2968, 3], [2969, 2970, 14],
    [2971, 2971, 3], [2972, 2972, 14], [2973, 2973, 3], [2974, 2975, 14],
    [2976, 2978, 3], [2979, 2980, 14], [2981, 2983, 3], [2984, 2986, 14],
    [2987, 2989, 3], [2990, 3001, 14], [3002, 3005, 3], [3006, 3010, 17],
    [3011, 3013, 3], [3014, 3016, 17], [3017, 3017, 3], [3018, 3021, 17],
    [3022, 3023, 3], [3024, 3024, 14], [3025, 3030, 3], [3031, 3031, 17],
    [3032, 3045, 3], [3046, 3055, 12], [3056, 3071, 3], [3072, 3076, 17],
    [3077, 3084, 14], [3085, 3085, 3], [3086, 3088, 14], [3089, 3089, 3],
    [3090, 3112, 14], [3113, 3113, 3], [3114, 3129, 14], [3130, 3132, 3],
    [3133, 3133, 14], [3134, 3140, 17], [3141, 3141, 3], [3142, 3144, 17],
    [3145, 3145, 3], [3146, 3149, 17], [3150, 3156, 3], [3157, 3158, 17],
    [3159, 3159, 3], [3160, 3162, 14], [3163, 3167, 3], [3168, 3169, 14],
    [3170, 3171, 17], [3172, 3173, 3], [3174, 3183, 12], [3184, 3199, 3],
    [3200, 3200, 14], [3201, 3203, 17], [3204, 3204, 3], [3205, 3212, 14],
    [3213, 3213, 3], [3214, 3216, 14], [3217, 3217, 3], [3218, 3240, 14],
    [3241, 3241, 3], [3242, 3251, 14], [3252, 3252, 3], [3253, 3257, 14],
    [3258, 3259, 3], [3260, 3260, 17], [3261, 3261, 14], [3262, 3268, 17],
    [3269, 3269, 3], [3270, 3272, 17], [3273, 3273, 3], [3274, 3277, 17],
    [3278, 3284, 3], [3285, 3286, 17], [3287, 3293, 3], [3294, 3294, 14],
    [3295, 3295, 3], [3296, 3297, 14], [3298, 3299, 17], [3300, 3301, 3],
    [3302, 3311, 12], [3312, 3312, 3], [3313, 3314, 14], [3315, 3327, 3],
    [3328, 3331, 17], [3332, 3340, 14], [3341, 3341, 3], [3342, 3344, 14],
    [3345, 3345, 3], [3346, 3386, 14], [3387, 3388, 17], [3389, 3389, 14],
    [3390, 3396, 17], [3397, 3397, 3], [3398, 3400, 17], [3401, 3401, 3],
    [3402, 3405, 17], [3406, 3406, 14], [3407, 3411, 3], [3412, 3414, 14],
    [3415, 3415, 17], [3416, 3422, 3], [3423, 3425, 14], [3426, 3427, 17],
    [3428, 3429, 3], [3430, 3439, 12], [3440, 3449, 3], [3450, 3455, 14],
    [3456, 3456, 3], [3457, 3459, 17], [3460, 3460, 3], [3461, 3478, 14],
    [3479, 3481, 3], [3482, 3505, 14], [3506, 3506, 3], [3507, 3515, 14],
    [3516, 3516, 3], [3517, 3517, 14], [3518, 3519, 3], [3520, 3526, 14],
    [3527, 3529, 3], [3530, 3530, 17], [3531, 3534, 3], [3535, 3540, 17],
    [3541, 3541, 3], [3542, 3542, 17], [3543, 3543, 3], [3544, 3551, 17],
    [3552, 3557, 3], [3558, 3567, 12], [3568, 3569, 3], [3570, 3571, 17],
    [3572, 3584, 3], [3585, 3632, 16398], [3633, 3633, 16401], [3634, 3635, 16398],
    [3636, 3642, 16401], [3643, 3647, 3], [3648, 3654, 16398], [3655, 3662, 16401],
    [3663, 3663, 3], [3664, 3673, 12], [3674, 3712, 3], [3713, 3714, 16398],
    [3715, 3715, 3], [3716, 3716, 16398], [3717, 3717, 3], [3718, 3722, 16398],
    [3723, 3723, 3], [3724, 3747, 16398], [3748, 3748, 3], [3749, 3749, 16398],
    [3750, 3750, 3], [3751, 3760, 16398], [3761, 3761, 16401], [3762, 3763, 16398],
    [3764, 3772, 16401], [3773, 3773, 16398], [3774, 3775, 3], [3776, 3780, 16398],
    [3781, 3781, 3], [3782, 3782, 16398], [3783, 3783, 3], [3784, 3789, 16401],
    [3790, 3791, 3], [3792, 3801, 12], [3802, 3803, 3], [3804, 3807, 16398],
    [3808, 3839, 3], [3840, 3840, 14], [3841, 3863, 3], [3864, 3865, 17],
    [3866, 3871, 3], [3872, 3881, 12], [3882, 3892, 3], [3893, 3893, 17],
    [3894, 3894, 3], [3895, 3895, 17], [3896, 3896, 3], [3897, 3897, 17],
    [3898, 3901, 3], [3902, 3903, 17], [3904, 3911, 14], [3912, 3912, 3],
    [3913, 3948, 14], [3949, 3952, 3], [3953, 3972, 17], [3973, 3973, 3],
    [3974, 3975, 17], [3976, 3980, 14], [3981, 3991, 17], [3992, 3992, 3],
    [3993, 4028, 17], [4029, 4037, 3], [4038, 4038, 17], [4039, 4095, 3],
    [4096, 4138, 16398], [4139, 4158, 16401], [4159, 4159, 16398], [4160, 4169, 12],
    [4170, 4175, 3], [4176, 4181, 16398], [4182, 4185, 16401], [4186, 4189, 16398],
    [4190, 4192, 16401], [4193, 4193, 16398], [4194, 4196, 16401], [4197, 4198, 16398],
    [4199, 4205, 16401], [4206, 4208, 16398], [4209, 4212, 16401], [4213, 4225, 16398],
    [4226, 4237, 16401], [4238, 4238, 16398], [4239, 4239, 16401], [4240, 4249, 12],
    [4250, 4253, 16401], [4254, 4255, 16398], [4256, 4293, 14], [4294, 4294, 3],
    [4295, 4295, 14], [4296, 4300, 3], [4301, 4301, 14], [4302, 4303, 3],
    [4304, 4346, 14], [4347, 4347, 3], [4348, 4680, 14], [4681, 4681, 3],
    [4682, 4685, 14], [4686, 4687, 3], [4688, 4694, 14], [4695, 4695, 3],
    [4696, 4696, 14], [4697, 4697, 3], [4698, 4701, 14], [4702, 4703, 3],
    [4704, 4744, 14], [4745, 4745, 3], [4746, 4749, 14], [4750, 4751, 3],
    [4752, 4784, 14], [4785, 4785, 3], [4786, 4789, 14], [4790, 4791, 3],
    [4792, 4798, 14], [4799, 4799, 3], [4800, 4800, 14], [4801, 4801, 3],
    [4802, 4805, 14], [4806, 4807, 3], [4808, 4822, 14], [4823, 4823, 3],
    [4824, 4880, 14], [4881, 4881, 3], [4882, 4885, 14], [4886, 4887, 3],
    [4888, 4954, 14], [4955, 4956, 3], [4957, 4959, 17], [4960, 4991, 3],
    [4992, 5007, 14], [5008, 5023, 3], [5024, 5109, 14], [5110, 5111, 3],
    [5112, 5117, 14], [5118, 5120, 3], [5121, 5740, 14], [5741, 5742, 3],
    [5743, 5759, 14], [5760, 5760, 7], [5761, 5786, 14], [5787, 5791, 3],
    [5792, 5866, 14], [5867, 5869, 3], [5870, 5880, 14], [5881, 5887, 3],
    [5888, 5900, 14], [5901, 5901, 3], [5902, 5905, 14], [5906, 5908, 17],
    [5909, 5919, 3], [5920, 5937, 14], [5938, 5940, 17], [5941, 5951, 3],
    [5952, 5969, 14], [5970, 5971, 17], [5972, 5983, 3], [5984, 5996, 14],
    [5997, 5997, 3], [5998, 6000, 14], [6001, 6001, 3], [6002, 6003, 17],
    [6004, 6015, 3], [6016, 6067, 16398], [6068, 6099, 16401], [6100, 6102, 3],
    [6103, 6103, 16398], [6104, 6107, 3], [6108, 6108, 16398], [6109, 6109, 16401],
    [6110, 6111, 3], [6112, 6121, 12], [6122, 6154, 3], [6155, 6158, 17],
    [6159, 6159, 3], [6160, 6169, 12], [6170, 6175, 3], [6176, 6264, 14],
    [6265, 6271, 3], [6272, 6276, 14], [6277, 6278, 17], [6279, 6312, 14],
    [6313, 6313, 17], [6314, 6314, 14], [6315, 6319, 3], [6320, 6389, 14],
    [6390, 6399, 3], [6400, 6430, 14], [6431, 6431, 3], [6432, 6443, 17],
    [6444, 6447, 3], [6448, 6459, 17], [6460, 6469, 3], [6470, 6479, 12],
    [6480, 6509, 16398], [6510, 6511, 3], [6512, 6516, 16398], [6517, 6527, 3],
    [6528, 6571, 16398], [6572, 6575, 3], [6576, 6601, 16398], [6602, 6607, 3],
    [6608, 6617, 12], [6618, 6618, 16398], [6619, 6621, 3], [6622, 6623, 16398],
    [6624, 6655, 3], [6656, 6678, 14], [6679, 6683, 17], [6684, 6687, 3],
    [6688, 6740, 16398], [6741, 6750, 16401], [6751, 6751, 3], [6752, 6780, 16401],
    [6781, 6782, 3], [6783, 6783, 17], [6784, 6793, 12], [6794, 6799, 3],
    [6800, 6809, 12], [6810, 6815, 3], [6816, 6829, 16398], [6830, 6831, 3],
    [6832, 6848, 17], [6849, 6911, 3], [6912, 6916, 17], [6917, 6963, 14],
    [6964, 6980, 17], [6981, 6987, 14], [6988, 6991, 3], [6992, 7001, 12],
    [7002, 7018, 3], [7019, 7027, 17], [7028, 7039, 3], [7040, 7042, 17],
    [7043, 7072, 14], [7073, 7085, 17], [7086, 7087, 14], [7088, 7097, 12],
    [7098, 7141, 14], [7142, 7155, 17], [7156, 7167, 3], [7168, 7203, 14],
    [7204, 7223, 17], [7224, 7231, 3], [7232, 7241, 12], [7242, 7244, 3],
    [7245, 7247, 14], [7248, 7257, 12], [7258, 7293, 14], [7294, 7295, 3],
    [7296, 7304, 14], [7305, 7311, 3], [7312, 7354, 14], [7355, 7356, 3],
    [7357, 7359, 14], [7360, 7375, 3], [7376, 7378, 17], [7379, 7379, 3],
    [7380, 7400, 17], [7401, 7404, 14], [7405, 7405, 17], [7406, 7411, 14],
    [7412, 7412, 17], [7413, 7414, 14], [7415, 7417, 17], [7418, 7418, 14],
    [7419, 7423, 3], [7424, 7615, 14], [7616, 7673, 17], [7674, 7674, 3],
    [7675, 7679, 17], [7680, 7957, 14], [7958, 7959, 3], [7960, 7965, 14],
    [7966, 7967, 3], [7968, 8005, 14], [8006, 8007, 3], [8008, 8013, 14],
    [8014, 8015, 3], [8016, 8023, 14], [8024, 8024, 3], [8025, 8025, 14],
    [8026, 8026, 3], [8027, 8027, 14], [8028, 8028, 3], [8029, 8029, 14],
    [8030, 8030, 3], [8031, 8061, 14], [8062, 8063, 3], [8064, 8116, 14],
    [8117, 8117, 3], [8118, 8124, 14], [8125, 8125, 3], [8126, 8126, 14],
    [8127, 8129, 3], [8130, 8132, 14], [8133, 8133, 3], [8134, 8140, 14],
    [8141, 8143, 3], [8144, 8147, 14], [8148, 8149, 3], [8150, 8155, 14],
    [8156, 8159, 3], [8160, 8172, 14], [8173, 8177, 3], [8178, 8180, 14],
    [8181, 8181, 3], [8182, 8188, 14], [8189, 8191, 3], [8192, 8198, 7],
    [8199, 8199, 3], [8200, 8202, 7], [8203, 8203, 3], [8204, 8204, 17],
    [8205, 8205, 19], [8206, 8207, 17], [8208, 8215, 3], [8216, 8217, 11],
    [8218, 8227, 3], [8228, 8228, 11], [8229, 8230, 3], [8231, 8231, 13],
    [8232, 8233, 5], [8234, 8238, 17], [8239, 8239, 15], [8240, 8251, 3],
    [8252, 8252, 16], [8253, 8254, 3], [8255, 8256, 15], [8257, 8259, 3],
    [8260, 8260, 10], [8261, 8264, 3], [8265, 8265, 16], [8266, 8275, 3],
    [8276, 8276, 15], [8277, 8286, 3], [8287, 8287, 7], [8288, 8292, 17],
    [8293, 8293, 3], [8294, 8303, 17], [8304, 8304, 3], [8305, 8305, 14],
    [8306, 8318, 3], [8319, 8319, 14], [8320, 8335, 3], [8336, 8348, 14],
    [8349, 8399, 3], [8400, 8432, 17], [8433, 8449, 3], [8450, 8450, 14],
    [8451, 8454, 3], [8455, 8455, 14], [8456, 8457, 3], [8458, 8467, 14],
    [8468, 8468, 3], [8469, 8469, 14], [8470, 8472, 3], [8473, 8477, 14],
    [8478, 8481, 3], [8482, 8482, 16], [8483, 8483, 3], [8484, 8484, 14],
    [8485, 8485, 3], [8486, 8486, 14], [8487, 8487, 3], [8488, 8488, 14],
    [8489, 8489, 3], [8490, 8493, 14], [8494, 8494, 3], [8495, 8504, 14],
    [8505, 8505, 20], [8506, 8507, 3], [8508, 8511, 14], [8512, 8516, 3],
    [8517, 8521, 14], [8522, 8525, 3], [8526, 8526, 14], [8527, 8543, 3],
    [8544, 8584, 14], [8585, 8595, 3], [8596, 8601, 16], [8602, 8616, 3],
    [8617, 8618, 16], [8619, 8985, 3], [8986, 8987, 16], [8988, 8999, 3],
    [9000, 9000, 16], [9001, 9095, 3], [9096, 9096, 16], [9097, 9166, 3],
    [9167, 9167, 16], [9168, 9192, 3], [9193, 9203, 16], [9204, 9207, 3],
    [9208, 9210, 16], [9211, 9397, 3], [9398, 9409, 14], [9410, 9410, 20],
    [9411, 9449, 14], [9450, 9641, 3], [9642, 9643, 16], [9644, 9653, 3],
    [9654, 9654, 16], [9655, 9663, 3], [9664, 9664, 16], [9665, 9722, 3],
    [9723, 9726, 16], [9727, 9727, 3], [9728, 9733, 16], [9734, 9734, 3],
    [9735, 9746, 16], [9747, 9747, 3], [9748, 9861, 16], [9862, 9871, 3],
    [9872, 9989, 16], [9990, 9991, 3], [9992, 10002, 16], [10003, 10003, 3],
    [10004, 10004, 16], [10005, 10005, 3], [10006, 10006, 16], [10007, 10012, 3],
    [10013, 10013, 16], [10014, 10016, 3], [10017, 10017, 16], [10018, 10023, 3],
    [10024, 10024, 16], [10025, 10034, 3], [10035, 10036, 16], [10037, 10051, 3],
    [10052, 10052, 16], [10053, 10054, 3], [10055, 10055, 16], [10056, 10059, 3],
    [10060, 10060, 16], [10061, 10061, 3], [10062, 10062, 16], [10063, 10066, 3],
    [10067, 10069, 16], [10070, 10070, 3], [10071, 10071, 16], [10072, 10082, 3],
    [10083, 10087, 16], [10088, 10132, 3], [10133, 10135, 16], [10136, 10144, 3],
    [10145, 10145, 16], [10146, 10159, 3], [10160, 10160, 16], [10161, 10174, 3],
    [10175, 10175, 16], [10176, 10547, 3], [10548, 10549, 16], [10550, 11012, 3],
    [11013, 11015, 16], [11016, 11034, 3], [11035, 11036, 16], [11037, 11087, 3],
    [11088, 11088, 16], [11089, 11092, 3], [11093, 11093, 16], [11094, 11263, 3],
    [11264, 11310, 14], [11311, 11311, 3], [11312, 11358, 14], [11359, 11359, 3],
    [11360, 11492, 14], [11493, 11498, 3], [11499, 11502, 14], [11503, 11505, 17],
    [11506, 11507, 14], [11508, 11519, 3], [11520, 11557, 14], [11558, 11558, 3],
    [11559, 11559, 14], [11560, 11564, 3], [11565, 11565, 14], [11566, 11567, 3],
    [11568, 11623, 14], [11624, 11630, 3], [11631, 11631, 14], [11632, 11646, 3],
    [11647, 11647, 17], [11648, 11670, 14], [11671, 11679, 3], [11680, 11686, 14],
    [11687, 11687, 3], [11688, 11694, 14], [11695, 11695, 3], [11696, 11702, 14],
    [11703, 11703, 3], [11704, 11710, 14], [11711, 11711, 3], [11712, 11718, 14],
    [11719, 11719, 3], [11720, 11726, 14], [11727, 11727, 3], [11728, 11734, 14],
    [11735, 11735, 3], [11736, 11742, 14], [11743, 11743, 3], [11744, 11775, 17],
    [11776, 11822, 3], [11823, 11823, 14], [11824, 11903, 3], [11904, 11929, 16405],
    [11930, 11930, 3], [11931, 12019, 16405], [12020, 12031, 3], [12032, 12245, 16405],
    [12246, 12287, 3], [12288, 12288, 7], [12289, 12292, 3], [12293, 12293, 16405],
    [12294, 12294, 22], [12295, 12295, 16407], [12296, 12320, 3], [12321, 12329, 16407],
    [12330, 12335, 17], [12336, 12336, 16], [12337, 12341, 16408], [12342, 12343, 3],
    [12344, 12346, 16407], [12347, 12347, 16405], [12348, 12348, 14], [12349, 12349, 16],
    [12350, 12352, 3], [12353, 12438, 16409], [12439, 12440, 3], [12441, 12442, 17],
    [12443, 12444, 16408], [12445, 12447, 16409], [12448, 12538, 16408], [12539, 12539, 3],
    [12540, 12543, 16408], [12544, 12548, 3], [12549, 12591, 14], [12592, 12592, 3],
    [12593, 12686, 14], [12687, 12703, 3], [12704, 12735, 14], [12736, 12783, 3],
    [12784, 12799, 16408], [12800, 12950, 3], [12951, 12951, 16], [12952, 12952, 3],
    [12953, 12953, 16], [12954, 13007, 3], [13008, 13054, 16408], [13055, 13055, 3],
    [13056, 13143, 16408], [13144, 13311, 3], [13312, 19903, 16407], [19904, 19967, 3],
    [19968, 40956, 16407], [40957, 40959, 3], [40960, 42124, 14], [42125, 42191, 3],
    [42192, 42237, 14], [42238, 42239, 3], [42240, 42508, 14], [42509, 42511, 3],
    [42512, 42527, 14], [42528, 42537, 12], [42538, 42539, 14], [42540, 42559, 3],
    [42560, 42606, 14], [42607, 42610, 17], [42611, 42611, 3], [42612, 42621, 17],
    [42622, 42622, 3], [42623, 42653, 14], [42654, 42655, 17], [42656, 42735, 14],
    [42736, 42737, 17], [42738, 42759, 3], [42760, 42943, 14], [42944, 42945, 3],
    [42946, 42954, 14], [42955, 42996, 3], [42997, 43009, 14], [43010, 43010, 17],
    [43011, 43013, 14], [43014, 43014, 17], [43015, 43018, 14], [43019, 43019, 17],
    [43020, 43042, 14], [43043, 43047, 17], [43048, 43051, 3], [43052, 43052, 17],
    [43053, 43071, 3], [43072, 43123, 14], [43124, 43135, 3], [43136, 43137, 17],
    [43138, 43187, 14], [43188, 43205, 17], [43206, 43215, 3], [43216, 43225, 12],
    [43226, 43231, 3], [43232, 43249, 17], [43250, 43255, 14], [43256, 43258, 3],
    [43259, 43259, 14], [43260, 43260, 3], [43261, 43262, 14], [43263, 43263, 17],
    [43264, 43273, 12], [43274, 43301, 14], [43302, 43309, 17], [43310, 43311, 3],
    [43312, 43334, 14], [43335, 43347, 17], [43348, 43359, 3], [43360, 43388, 14],
    [43389, 43391, 3], [43392, 43395, 17], [43396, 43442, 14], [43443, 43456, 17],
    [43457, 43470, 3], [43471, 43471, 14], [43472, 43481, 12], [43482, 43487, 3],
    [43488, 43492, 16398], [43493, 43493, 16401], [43494, 43503, 16398], [43504, 43513, 12],
    [43514, 43518, 16398], [43519, 43519, 3], [43520, 43560, 14], [43561, 43574, 17],
    [43575, 43583, 3], [43584, 43586, 14], [43587, 43587, 17], [43588, 43595, 14],
    [43596, 43597, 17], [43598, 43599, 3], [43600, 43609, 12], [43610, 43615, 3],
    [43616, 43642, 16398], [43643, 43645, 16401], [43646, 43695, 16398], [43696, 43696, 16401],
    [43697, 43697, 16398], [43698, 43700, 16401], [43701, 43702, 16398], [43703, 43704, 16401],
    [43705, 43709, 16398], [43710, 43711, 16401], [43712, 43712, 16398], [43713, 43713, 16401],
    [43714, 43714, 16398], [43715, 43738, 3], [43739, 43743, 16398], [43744, 43754, 14],
    [43755, 43759, 17], [43760, 43761, 3], [43762, 43764, 14], [43765, 43766, 17],
    [43767, 43776, 3], [43777, 43782, 14], [43783, 43784, 3], [43785, 43790, 14],
    [43791, 43792, 3], [43793, 43798, 14], [43799, 43807, 3], [43808, 43814, 14],
    [43815, 43815, 3], [43816, 43822, 14], [43823, 43823, 3], [43824, 43881, 14],
    [43882, 43887, 3], [43888, 44002, 14], [44003, 44010, 17], [44011, 44011, 3],
    [44012, 44013, 17], [44014, 44015, 3], [44016, 44025, 12], [44026, 44031, 3],
    [44032, 55203, 16410], [55204, 55215, 3], [55216, 55238, 14], [55239, 55242, 3],
    [55243, 55291, 14], [55292, 63743, 3], [63744, 64109, 16407], [64110, 64111, 3],
    [64112, 64217, 16407], [64218, 64255, 3], [64256, 64262, 14], [64263, 64274, 3],
    [64275, 64279, 14], [64280, 64284, 3], [64285, 64285, 18], [64286, 64286, 17],
    [64287, 64296, 18], [64297, 64297, 3], [64298, 64310, 18], [64311, 64311, 3],
    [64312, 64316, 18], [64317, 64317, 3], [64318, 64318, 18], [64319, 64319, 3],
    [64320, 64321, 18], [64322, 64322, 3], [64323, 64324, 18], [64325, 64325, 3],
    [64326, 64335, 18], [64336, 64433, 14], [64434, 64466, 3], [64467, 64829, 14],
    [64830, 64847, 3], [64848, 64911, 14], [64912, 64913, 3], [64914, 64967, 14],
    [64968, 65007, 3], [65008, 65019, 14], [65020, 65023, 3], [65024, 65039, 17],
    [65040, 65040, 10], [65041, 65042, 3], [65043, 65043, 13], [65044, 65044, 10],
    [65045, 65055, 3], [65056, 65071, 17], [65072, 65074, 3], [65075, 65076, 15],
    [65077, 65100, 3], [65101, 65103, 15], [65104, 65104, 10], [65105, 65105, 3],
    [65106, 65106, 11], [65107, 65107, 3], [65108, 65108, 10], [65109, 65109, 13],
    [65110, 65135, 3], [65136, 65140, 14], [65141, 65141, 3], [65142, 65276, 14],
    [65277, 65278, 3], [65279, 65279, 17], [65280, 65286, 3], [65287, 65287, 11],
    [65288, 65291, 3], [65292, 65292, 10], [65293, 65293, 3], [65294, 65294, 11],
    [65295, 65295, 3], [65296, 65305, 12], [65306, 65306, 13], [65307, 65307, 10],
    [65308, 65312, 3], [65313, 65338, 14], [65339, 65342, 3], [65343, 65343, 15],
    [65344, 65344, 3], [65345, 65370, 14], [65371, 65381, 3], [65382, 65437, 16408],
    [65438, 65439, 17], [65440, 65470, 14], [65471, 65473, 3], [65474, 65479, 14],
    [65480, 65481, 3], [65482, 65487, 14], [65488, 65489, 3], [65490, 65495, 14],
    [65496, 65497, 3], [65498, 65500, 14], [65501, 65528, 3], [65529, 65531, 17],
    [65532, 65535, 3], [65536, 65547, 14], [65548, 65548, 3], [65549, 65574, 14],
    [65575, 65575, 3], [65576, 65594, 14], [65595, 65595, 3], [65596, 65597, 14],
    [65598, 65598, 3], [65599, 65613, 14], [65614, 65615, 3], [65616, 65629, 14],
    [65630, 65663, 3], [65664, 65786, 14], [65787, 65855, 3], [65856, 65908, 14],
    [65909, 66044, 3], [66045, 66045, 17], [66046, 66175, 3], [66176, 66204, 14],
    [66205, 66207, 3], [66208, 66256, 14], [66257, 66271, 3], [66272, 66272, 17],
    [66273, 66303, 3], [66304, 66335, 14], [66336, 66348, 3], [66349, 66378, 14],
    [66379, 66383, 3], [66384, 66421, 14], [66422, 66426, 17], [66427, 66431, 3],
    [66432, 66461, 14], [66462, 66463, 3], [66464, 66499, 14], [66500, 66503, 3],
    [66504, 66511, 14], [66512, 66512, 3], [66513, 66517, 14], [66518, 66559, 3],
    [66560, 66717, 14], [66718, 66719, 3], [66720, 66729, 12], [66730, 66735, 3],
    [66736, 66771, 14], [66772, 66775, 3], [66776, 66811, 14], [66812, 66815, 3],
    [66816, 66855, 14], [66856, 66863, 3], [66864, 66915, 14], [66916, 67071, 3],
    [67072, 67382, 14], [67383, 67391, 3], [67392, 67413, 14], [67414, 67423, 3],
    [67424, 67431, 14], [67432, 67583, 3], [67584, 67589, 14], [67590, 67591, 3],
    [67592, 67592, 14], [67593, 67593, 3], [67594, 67637, 14], [67638, 67638, 3],
    [67639, 67640, 14], [67641, 67643, 3], [67644, 67644, 14], [67645, 67646, 3],
    [67647, 67669, 14], [67670, 67679, 3], [67680, 67702, 14], [67703, 67711, 3],
    [67712, 67742, 14], [67743, 67807, 3], [67808, 67826, 14], [67827, 67827, 3],
    [67828, 67829, 14], [67830, 67839, 3], [67840, 67861, 14], [67862, 67871, 3],
    [67872, 67897, 14], [67898, 67967, 3], [67968, 68023, 14], [68024, 68029, 3],
    [68030, 68031, 14], [68032, 68095, 3], [68096, 68096, 14], [68097, 68099, 17],
    [68100, 68100, 3], [68101, 68102, 17], [68103, 68107, 3], [68108, 68111, 17],
    [68112, 68115, 14], [68116, 68116, 3], [68117, 68119, 14], [68120, 68120, 3],
    [68121, 68149, 14], [68150, 68151, 3], [68152, 68154, 17], [68155, 68158, 3],
    [68159, 68159, 17], [68160, 68191, 3], [68192, 68220, 14], [68221, 68223, 3],
    [68224, 68252, 14], [68253, 68287, 3], [68288, 68295, 14], [68296, 68296, 3],
    [68297, 68324, 14], [68325, 68326, 17], [68327, 68351, 3], [68352, 68405, 14],
    [68406, 68415, 3], [68416, 68437, 14], [68438, 68447, 3], [68448, 68466, 14],
    [68467, 68479, 3], [68480, 68497, 14], [68498, 68607, 3], [68608, 68680, 14],
    [68681, 68735, 3], [68736, 68786, 14], [68787, 68799, 3], [68800, 68850, 14],
    [68851, 68863, 3], [68864, 68899, 14], [68900, 68903, 17], [68904, 68911, 3],
    [68912, 68921, 12], [68922, 69247, 3], [69248, 69289, 14], [69290, 69290, 3],
    [69291, 69292, 17], [69293, 69295, 3], [69296, 69297, 14], [69298, 69375, 3],
    [69376, 69404, 14], [69405, 69414, 3], [69415, 69415, 14], [69416, 69423, 3],
    [69424, 69445, 14], [69446, 69456, 17], [69457, 69551, 3], [69552, 69572, 14],
    [69573, 69599, 3], [69600, 69622, 14], [69623, 69631, 3], [69632, 69634, 17],
    [69635, 69687, 14], [69688, 69702, 17], [69703, 69733, 3], [69734, 69743, 12],
    [69744, 69758, 3], [69759, 69762, 17], [69763, 69807, 14], [69808, 69818, 17],
    [69819, 69820, 3], [69821, 69821, 17], [69822, 69836, 3], [69837, 69837, 17],
    [69838, 69839, 3], [69840, 69864, 14], [69865, 69871, 3], [69872, 69881, 12],
    [69882, 69887, 3], [69888, 69890, 17], [69891, 69926, 14], [69927, 69940, 17],
    [69941, 69941, 3], [69942, 69951, 12], [69952, 69955, 3], [69956, 69956, 14],
    [69957, 69958, 17], [69959, 69959, 14], [69960, 69967, 3], [69968, 70002, 14],
    [70003, 70003, 17], [70004, 70005, 3], [70006, 70006, 14], [70007, 70015, 3],
    [70016, 70018, 17], [70019, 70066, 14], [70067, 70080, 17], [70081, 70084, 14],
    [70085, 70088, 3], [70089, 70092, 17], [70093, 70093, 3], [70094, 70095, 17],
    [70096, 70105, 12], [70106, 70106, 14], [70107, 70107, 3], [70108, 70108, 14],
    [70109, 70143, 3], [70144, 70161, 14], [70162, 70162, 3], [70163, 70187, 14],
    [70188, 70199, 17], [70200, 70205, 3], [70206, 70206, 17], [70207, 70271, 3],
    [70272, 70278, 14], [70279, 70279, 3], [70280, 70280, 14], [70281, 70281, 3],
    [70282, 70285, 14], [70286, 70286, 3], [70287, 70301, 14], [70302, 70302, 3],
    [70303, 70312, 14], [70313, 70319, 3], [70320, 70366, 14], [70367, 70378, 17],
    [70379, 70383, 3], [70384, 70393, 12], [70394, 70399, 3], [70400, 70403, 17],
    [70404, 70404, 3], [70405, 70412, 14], [70413, 70414, 3], [70415, 70416, 14],
    [70417, 70418, 3], [70419, 70440, 14], [70441, 70441, 3], [70442, 70448, 14],
    [70449, 70449, 3], [70450, 70451, 14], [70452, 70452, 3], [70453, 70457, 14],
    [70458, 70458, 3], [70459, 70460, 17], [70461, 70461, 14], [70462, 70468, 17],
    [70469, 70470, 3], [70471, 70472, 17], [70473, 70474, 3], [70475, 70477, 17],
    [70478, 70479, 3], [70480, 70480, 14], [70481, 70486, 3], [70487, 70487, 17],
    [70488, 70492, 3], [70493, 70497, 14], [70498, 70499, 17], [70500, 70501, 3],
    [70502, 70508, 17], [70509, 70511, 3], [70512, 70516, 17], [70517, 70655, 3],
    [70656, 70708, 14], [70709, 70726, 17], [70727, 70730, 14], [70731, 70735, 3],
    [70736, 70745, 12], [70746, 70749, 3], [70750, 70750, 17], [70751, 70753, 14],
    [70754, 70783, 3], [70784, 70831, 14], [70832, 70851, 17], [70852, 70853, 14],
    [70854, 70854, 3], [70855, 70855, 14], [70856, 70863, 3], [70864, 70873, 12],
    [70874, 71039, 3], [71040, 71086, 14], [71087, 71093, 17], [71094, 71095, 3],
    [71096, 71104, 17], [71105, 71127, 3], [71128, 71131, 14], [71132, 71133, 17],
    [71134, 71167, 3], [71168, 71215, 14], [71216, 71232, 17], [71233, 71235, 3],
    [71236, 71236, 14], [71237, 71247, 3], [71248, 71257, 12], [71258, 71295, 3],
    [71296, 71338, 14], [71339, 71351, 17], [71352, 71352, 14], [71353, 71359, 3],
    [71360, 71369, 12], [71370, 71423, 3], [71424, 71450, 16398], [71451, 71452, 3],
    [71453, 71467, 16401], [71468, 71471, 3], [71472, 71481, 12], [71482, 71483, 16398],
    [71484, 71486, 3], [71487, 71487, 16398], [71488, 71679, 3], [71680, 71723, 14],
    [71724, 71738, 17], [71739, 71839, 3], [71840, 71903, 14], [71904, 71913, 12],
    [71914, 71934, 3], [71935, 71942, 14], [71943, 71944, 3], [71945, 71945, 14],
    [71946, 71947, 3], [71948, 71955, 14], [71956, 71956, 3], [71957, 71958, 14],
    [71959, 71959, 3], [71960, 71983, 14], [71984, 71989, 17], [71990, 71990, 3],
    [71991, 71992, 17], [71993, 71994, 3], [71995, 71998, 17], [71999, 71999, 14],
    [72000, 72000, 17], [72001, 72001, 14], [72002, 72003, 17], [72004, 72015, 3],
    [72016, 72025, 12], [72026, 72095, 3], [72096, 72103, 14], [72104, 72105, 3],
    [72106, 72144, 14], [72145, 72151, 17], [72152, 72153, 3], [72154, 72160, 17],
    [72161, 72161, 14], [72162, 72162, 3], [72163, 72163, 14], [72164, 72164, 17],
    [72165, 72191, 3], [72192, 72192, 14], [72193, 72202, 17], [72203, 72242, 14],
    [72243, 72249, 17], [72250, 72250, 14], [72251, 72254, 17], [72255, 72262, 3],
    [72263, 72263, 17], [72264, 72271, 3], [72272, 72272, 14], [72273, 72283, 17],
    [72284, 72329, 14], [72330, 72345, 17], [72346, 72348, 3], [72349, 72349, 14],
    [72350, 72383, 3], [72384, 72440, 14], [72441, 72703, 3], [72704, 72712, 14],
    [72713, 72713, 3], [72714, 72750, 14], [72751, 72758, 17], [72759, 72759, 3],
    [72760, 72767, 17], [72768, 72768, 14], [72769, 72783, 3], [72784, 72793, 12],
    [72794, 72817, 3], [72818, 72847, 14], [72848, 72849, 3], [72850, 72871, 17],
    [72872, 72872, 3], [72873, 72886, 17], [72887, 72959, 3], [72960, 72966, 14],
    [72967, 72967, 3], [72968, 72969, 14], [72970, 72970, 3], [72971, 73008, 14],
    [73009, 73014, 17], [73015, 73017, 3], [73018, 73018, 17], [73019, 73019, 3],
    [73020, 73021, 17], [73022, 73022, 3], [73023, 73029, 17], [73030, 73030, 14],
    [73031, 73031, 17], [73032, 73039, 3], [73040, 73049, 12], [73050, 73055, 3],
    [73056, 73061, 14], [73062, 73062, 3], [73063, 73064, 14], [73065, 73065, 3],
    [73066, 73097, 14], [73098, 73102, 17], [73103, 73103, 3], [73104, 73105, 17],
    [73106, 73106, 3], [73107, 73111, 17], [73112, 73112, 14], [73113, 73119, 3],
    [73120, 73129, 12], [73130, 73439, 3], [73440, 73458, 14], [73459, 73462, 17],
    [73463, 73647, 3], [73648, 73648, 14], [73649, 73727, 3], [73728, 74649, 14],
    [74650, 74751, 3], [74752, 74862, 14], [74863, 74879, 3], [74880, 75075, 14],
    [75076, 77823, 3], [77824, 78894, 14], [78895, 78895, 3], [78896, 78904, 17],
    [78905, 82943, 3], [82944, 83526, 14], [83527, 92159, 3], [92160, 92728, 14],
    [92729, 92735, 3], [92736, 92766, 14], [92767, 92767, 3], [92768, 92777, 12],
    [92778, 92879, 3], [92880, 92909, 14], [92910, 92911, 3], [92912, 92916, 17],
    [92917, 92927, 3], [92928, 92975, 14], [92976, 92982, 17], [92983, 92991, 3],
    [92992, 92995, 14], [92996, 93007, 3], [93008, 93017, 12], [93018, 93026, 3],
    [93027, 93047, 14], [93048, 93052, 3], [93053, 93071, 14], [93072, 93759, 3],
    [93760, 93823, 14], [93824, 93951, 3], [93952, 94026, 14], [94027, 94030, 3],
    [94031, 94031, 17], [94032, 94032, 14], [94033, 94087, 17], [94088, 94094, 3],
    [94095, 94098, 17], [94099, 94111, 14], [94112, 94175, 3], [94176, 94177, 14],
    [94178, 94178, 3], [94179, 94179, 14], [94180, 94180, 27], [94181, 94191, 3],
    [94192, 94193, 16405], [94194, 94207, 3], [94208, 100343, 22], [100344, 100351, 3],
    [100352, 101589, 22], [101590, 101631, 3], [101632, 101640, 22], [101641, 110591, 3],
    [110592, 110592, 16408], [110593, 110878, 16409], [110879, 110927, 3], [110928, 110930, 16409],
    [110931, 110947, 3], [110948, 110951, 16408], [110952, 110959, 3], [110960, 111355, 22],
    [111356, 113663, 3], [113664, 113770, 14], [113771, 113775, 3], [113776, 113788, 14],
    [113789, 113791, 3], [113792, 113800, 14], [113801, 113807, 3], [113808, 113817, 14],
    [113818, 113820, 3], [113821, 113822, 17], [113823, 113823, 3], [113824, 113827, 17],
    [113828, 119140, 3], [119141, 119145, 17], [119146, 119148, 3], [119149, 119170, 17],
    [119171, 119172, 3], [119173, 119179, 17], [119180, 119209, 3], [119210, 119213, 17],
    [119214, 119361, 3], [119362, 119364, 17], [119365, 119807, 3], [119808, 119892, 14],
    [119893, 119893, 3], [119894, 119964, 14], [119965, 119965, 3], [119966, 119967, 14],
    [119968, 119969, 3], [119970, 119970, 14], [119971, 119972, 3], [119973, 119974, 14],
    [119975, 119976, 3], [119977, 119980, 14], [119981, 119981, 3], [119982, 119993, 14],
    [119994, 119994, 3], [119995, 119995, 14], [119996, 119996, 3], [119997, 120003, 14],
    [120004, 120004, 3], [120005, 120069, 14], [120070, 120070, 3], [120071, 120074, 14],
    [120075, 120076, 3], [120077, 120084, 14], [120085, 120085, 3], [120086, 120092, 14],
    [120093, 120093, 3], [120094, 120121, 14], [120122, 120122, 3], [120123, 120126, 14],
    [120127, 120127, 3], [120128, 120132, 14], [120133, 120133, 3], [120134, 120134, 14],
    [120135, 120137, 3], [120138, 120144, 14], [120145, 120145, 3], [120146, 120485, 14],
    [120486, 120487, 3], [120488, 120512, 14], [120513, 120513, 3], [120514, 120538, 14],
    [120539, 120539, 3], [120540, 120570, 14], [120571, 120571, 3], [120572, 120596, 14],
    [120597, 120597, 3], [120598, 120628, 14], [120629, 120629, 3], [120630, 120654, 14],
    [120655, 120655, 3], [120656, 120686, 14], [120687, 120687, 3], [120688, 120712, 14],
    [120713, 120713, 3], [120714, 120744, 14], [120745, 120745, 3], [120746, 120770, 14],
    [120771, 120771, 3], [120772, 120779, 14], [120780, 120781, 3], [120782, 120831, 12],
    [120832, 121343, 3], [121344, 121398, 17], [121399, 121402, 3], [121403, 121452, 17],
    [121453, 121460, 3], [121461, 121461, 17], [121462, 121475, 3], [121476, 121476, 17],
    [121477, 121498, 3], [121499, 121503, 17], [121504, 121504, 3], [121505, 121519, 17],
    [121520, 122879, 3], [122880, 122886, 17], [122887, 122887, 3], [122888, 122904, 17],
    [122905, 122906, 3], [122907, 122913, 17], [122914, 122914, 3], [122915, 122916, 17],
    [122917, 122917, 3], [122918, 122922, 17], [122923, 123135, 3], [123136, 123180, 14],
    [123181, 123183, 3], [123184, 123190, 17], [123191, 123197, 14], [123198, 123199, 3],
    [123200, 123209, 12], [123210, 123213, 3], [123214, 123214, 14], [123215, 123583, 3],
    [123584, 123627, 14], [123628, 123631, 17], [123632, 123641, 12], [123642, 124927, 3],
    [124928, 125124, 14], [125125, 125135, 3], [125136, 125142, 17], [125143, 125183, 3],
    [125184, 125251, 14], [125252, 125258, 17], [125259, 125259, 14], [125260, 125263, 3],
    [125264, 125273, 12], [125274, 126463, 3], [126464, 126467, 14], [126468, 126468, 3],
    [126469, 126495, 14], [126496, 126496, 3], [126497, 126498, 14], [126499, 126499, 3],
    [126500, 126500, 14], [126501, 126502, 3], [126503, 126503, 14], [126504, 126504, 3],
    [126505, 126514, 14], [126515, 126515, 3], [126516, 126519, 14], [126520, 126520, 3],
    [126521, 126521, 14], [126522, 126522, 3], [126523, 126523, 14], [126524, 126529, 3],
    [126530, 126530, 14], [126531, 126534, 3], [126535, 126535, 14], [126536, 126536, 3],
    [126537, 126537, 14], [126538, 126538, 3], [126539, 126539, 14], [126540, 126540, 3],
    [126541, 126543, 14], [126544, 126544, 3], [126545, 126546, 14], [126547, 126547, 3],
    [126548, 126548, 14], [126549, 126550, 3], [126551, 126551, 14], [126552, 126552, 3],
    [126553, 126553, 14], [126554, 126554, 3], [126555, 126555, 14], [126556, 126556, 3],
    [126557, 126557, 14], [126558, 126558, 3], [126559, 126559, 14], [126560, 126560, 3],
    [126561, 126562, 14], [126563, 126563, 3], [126564, 126564, 14], [126565, 126566, 3],
    [126567, 126570, 14], [126571, 126571, 3], [126572, 126578, 14], [126579, 126579, 3],
    [126580, 126583, 14], [126584, 126584, 3], [126585, 126588, 14], [126589, 126589, 3],
    [126590, 126590, 14], [126591, 126591, 3], [126592, 126601, 14], [126602, 126602, 3],
    [126603, 126619, 14], [126620, 126624, 3], [126625, 126627, 14], [126628, 126628, 3],
    [126629, 126633, 14], [126634, 126634, 3], [126635, 126651, 14], [126652, 126975, 3],
    [126976, 127231, 16], [127232, 127244, 3], [127245, 127247, 16], [127248, 127278, 3],
    [127279, 127279, 16], [127280, 127305, 14], [127306, 127311, 3], [127312, 127337, 14],
    [127338, 127339, 3], [127340, 127343, 16], [127344, 127345, 20], [127346, 127357, 14],
    [127358, 127359, 20], [127360, 127369, 14], [127370, 127373, 3], [127374, 127374, 16],
    [127375, 127376, 3], [127377, 127386, 16], [127387, 127404, 3], [127405, 127461, 16],
    [127462, 127487, 28], [127488, 127488, 16409], [127489, 127503, 16], [127504, 127513, 3],
    [127514, 127514, 16], [127515, 127534, 3], [127535, 127535, 16], [127536, 127537, 3],
    [127538, 127546, 16], [127547, 127547, 3], [127548, 127551, 16], [127552, 127560, 3],
    [127561, 127994, 16], [127995, 127999, 17], [128000, 128317, 16], [128318, 128325, 3],
    [128326, 128591, 16], [128592, 128639, 3], [128640, 128767, 16], [128768, 128883, 3],
    [128884, 128895, 16], [128896, 128980, 3], [128981, 129023, 16], [129024, 129035, 3],
    [129036, 129039, 16], [129040, 129095, 3], [129096, 129103, 16], [129104, 129113, 3],
    [129114, 129119, 16], [129120, 129159, 3], [129160, 129167, 16], [129168, 129197, 3],
    [129198, 129279, 16], [129280, 129291, 3], [129292, 129338, 16], [129339, 129339, 3],
    [129340, 129349, 16], [129350, 129350, 3], [129351, 129791, 16], [129792, 130031, 3],
    [130032, 130041, 12], [130042, 130047, 3], [130048, 131069, 16], [131070, 131071, 3],
    [131072, 173789, 16407], [173790, 173823, 3], [173824, 177972, 16407], [177973, 177983, 3],
    [177984, 178205, 16407], [178206, 178207, 3], [178208, 183969, 16407], [183970, 183983, 3],
    [183984, 191456, 16407], [191457, 194559, 3], [194560, 195101, 16407], [195102, 196607, 3],
    [196608, 201546, 16407], [201547, 917504, 3], [917505, 917505, 17], [917506, 917535, 3],
    [917536, 917631, 17], [917632, 917759, 3], [917760, 917999, 17], [918000, 1114111, 3]
  ]`),

  metadata: {
    categoryCount: 29
  }
};
;let split = (breakIter, funcName, str) => {
  let result = [];

  breakIter[funcName](str, (text, _start, _stop) => {
    result.push(text);
  });

  return result;
};

export const wordSplit = (str, suppressions) => {
  return split(new BreakIterator(suppressions), 'eachWord', str);
};

export const sentenceSplit = (str, suppressions) => {
  return split(new BreakIterator(suppressions), 'eachSentence', str);
};

export const graphemeSplit = (str, suppressions) => {
  return split(new BreakIterator(suppressions), 'eachGraphemeCluster', str);
};

export const lineSplit = (str, suppressions) => {
  return split(new BreakIterator(suppressions), 'eachLine', str);
};
;const START_STATE = 1;
const STOP_STATE = 0;
const NEXT_STATES = 4;
const ACCEPTING = 0;

class StateMachine {
  static getInstance(boundaryType) {
    let cache = StateMachine.getCache();

    if (cache[boundaryType] === undefined) {
      let rsrc = RuleSet[boundaryType];

      cache[boundaryType] = new StateMachine(
        boundaryType,
        new Metadata(rsrc.metadata),
        new StateTable(rsrc.forwardTable.table, rsrc.forwardTable.flags),
        new CategoryTable(rsrc.categoryTable)
      );
    }

    return cache[boundaryType];
  }

  static getCache() {
    if (this.cache === undefined) {
      this.cache = {};
    }

    return this.cache;
  }

  constructor(boundaryType, metadata, ftable, categoryTable) {
    this.boundaryType = boundaryType;
    this.metadata = metadata;
    this.ftable = ftable;
    this.categoryTable = categoryTable;
  }

  handleNext(cursor) {
    var initialPosition = cursor.actualPosition;
    var result = cursor.actualPosition;
    var state = START_STATE;
    var row = this.getRowIndex(state);
    var category = 3;
    var mode = 'run';

    if (this.ftable.isBofRequired()) {
      category = 2;
      mode = 'start';
    }

    while (state != STOP_STATE) {
      if (cursor.isEos()) {
        if (mode === 'stop') {
          break;
        }

        mode = 'stop';
        category = 1;
      } else if (mode === 'run') {
        category = this.categoryTable.get(cursor.getCodePoint());

        if ((category & 0x4000) != 0) {
          category &= ~0x4000;
        }

        cursor.advance();
      } else {
        mode = 'run';
      }

      state = this.ftable.get(row + NEXT_STATES + category);
      row = this.getRowIndex(state);

      if (this.ftable.get(row + ACCEPTING) == -1) {
        // match found
        result = cursor.actualPosition;
      }
    }

    while (cursor.actualPosition != result) {
      cursor.retreat();
    }

    // don't let cursor get stuck
    if (cursor.actualPosition === initialPosition) {
      cursor.advance();
    }

    return result;
  }

  // private

  getRowIndex(state) {
    return state * (this.metadata.getCategoryCount() + 4);
  }
}
;const BOF_REQUIRED_FLAG = 2;

class StateTable {
  constructor(values, flags) {
    this.values = values;
    this.flags = flags;
  }

  get(idx) {
    return this.values[idx];
  }

  isBofRequired() {
    return (this.flags & BOF_REQUIRED_FLAG) != 0;
  }
}
;class Node {
  constructor(value, children) {
    this.value = value;
    this.children = children || {};
  }

  setChild(key, child) {
    this.children[key] = child;
  }

  getChild(key) {
    return this.children[key];
  }

  copy() {
    let childrenCopy = {};

    for (const key in this.children) {
      childrenCopy[key] = this.children[key].copy();
    }

    return new Node(this.value, childrenCopy);
  }

  forEach(callback) {
    this._forEach(callback, []);
  }

  _forEach(callback, path) {
    if (this.value) {
      callback(path, this.value);
    }

    for (const key in this.children) {
      this.children[key]._forEach(callback, [...path, key]);
    }
  }
}

class Trie {
  constructor(rootNode) {
    this.root = rootNode || new Node();
    this.locked = false;
  }

  add(key, value) {
    if (this.locked) {
      throw 'Cannot add items to a locked trie'
    }

    let current = this.root;

    for (let i = 0; i < key.length; i ++) {
      let next = current.getChild(key[i]);

      if (next) {
        current = next;
      } else {
        let newNode = new Node();
        current.setChild(key[i], newNode);
        current = newNode;
      }
    }

    current.value = value;
  }

  lock() {
    this.locked = true;
  }

  copy() {
    return new Trie(this.root.copy());
  }

  forEach(callback) {
    this.root.forEach(callback);
  }

  merge(otherTrie) {
    let result = this.copy();

    otherTrie.forEach((key, value) => {
      result.add(key, value);
    });

    return result;
  }
}
;export const suppressions = {};

export class Suppressions {
  static create(list) {
    let forwardTrie = new Trie();
    let backwardTrie = new Trie();

    list.forEach((elem) => {
      let codePoints = utfstring.stringToCodePoints(elem);
      forwardTrie.add(codePoints, true);
      backwardTrie.add(codePoints.reverse(), true);
    });

    return new Suppressions(forwardTrie, backwardTrie, list);
  }

  constructor(forwardTrie, backwardTrie, list) {
    this.forwardTrie = forwardTrie;
    this.backwardTrie = backwardTrie;
    this.list = list;
  }

  lock() {
    this.forwardTrie.lock();
    this.backwardTrie.lock();
  }

  merge(otherSupp) {
    return new Suppressions(
      this.forwardTrie.merge(otherSupp.forwardTrie),
      this.backwardTrie.merge(otherSupp.backwardTrie),
      [...this.list, ...otherSupp.list]
    )
  }

  shouldBreak(cursor) {
    var idx = cursor.logicalPosition;

    // consider case when a space follows the '.'
    // (so we handle i.e. "Mr. Brown")
    if (cursor.getCodePoint(idx - 1) == 32) {
      idx -= 2;
    }

    var node = this.backwardTrie.root;
    var found;

    while (true) {
      if (idx < 0 || idx >= cursor.length) {
        found = false;
        break;
      }

      node = node.getChild(cursor.getCodePoint(idx));

      if (node == undefined) {
        found = false;
        break;
      }

      if (node.value) {
        found = true;
        break;
      }

      idx --;
    }

    if (idx != 0 && cursor.getCodePoint(idx - 1) != 32) {
      return true;
    }

    if (!found) {
      return true;
    }

    node = this.forwardTrie.root;

    while (true) {
      if (idx >= cursor.length) {
        return true;
      }

      node = node.getChild(cursor.getCodePoint(idx));

      if (node == undefined) {
        return true;
      }

      if (node.value) {
        return false;
      }

      idx ++;
    }
  }
}
;export const customSuppressions = {
  en: Suppressions.create(['Dr.'])
};
;suppressions['de'] = ( () => {
  let supp = Suppressions.create([
    'Port.',
    'Prot.',
    'Proz.',
    'Pfd.',
    'P.',
    'Phys.',
    'PIN.',
    'Alt.',
    'Allg.',
    'Ausg.',
    'Ausschl.',
    'Aug.',
    'Akt.',
    'Abs.',
    'Abt.',
    'Abw.',
    'A.',
    'Adr.',
    'Adj.',
    'App.',
    'Apr.',
    'Art.',
    'Di.',
    'Dipl.-Ing.',
    'Dipl.-Kfm.',
    'Dir.',
    'Dim.',
    'Dez.',
    'D.',
    'Dr.',
    'Dtzd.',
    'Dat.',
    'Do.',
    'Ges.',
    'Gesch.',
    'Gebr.',
    'Gem.',
    'Geograph.',
    'G.',
    'Ggf.',
    'frz.',
    'entspr.',
    'erw.',
    'evtl.',
    'ev.',
    'eigtl.',
    'einschl.',
    'e.h.',
    'e.Wz.',
    'e.V.',
    'exkl.',
    'ehem.',
    'Frl.',
    'Fr.',
    'Folg.',
    'Forts. f.',
    'Fa.',
    'Fam.',
    'Fn.',
    'Feb.',
    'F.',
    'Inh.',
    'Ing.',
    'Int.',
    'Inc.',
    'I.',
    'k.u.k.',
    'k. u. k.',
    'kgl.',
    'kath.',
    'kfm.',
    'Ca.',
    'Chr.',
    'Chin.',
    'C.',
    'Co.',
    'J.',
    'Jahrh.',
    'Jan.',
    'Jr.',
    'So.',
    'Sept.',
    'Sep.',
    'Sek.',
    'Sa.',
    'Spezif.',
    'St.',
    'Std.',
    'Str.',
    'StR.',
    'S.A.',
    'Schr.',
    'i.B.',
    'i.R.',
    'i.J.',
    'i.A.',
    'i.H.',
    'i.V.',
    'inkl.',
    's.',
    'schwäb.',
    'schweiz.',
    'schles.',
    'sog.',
    'südd.',
    'o.B.',
    'o.A.',
    'o.g.',
    'od.',
    'am.',
    'amtl.',
    'a.a.O.',
    'a.a.S.',
    'a.Rh.',
    'a.M.',
    'a.D.',
    'u.',
    'z.',
    'zzgl.',
    'Kl.',
    'Kap.',
    'Kfm.',
    'Konv.',
    'Kop.',
    'Obj.',
    'Op.',
    'O.',
    'Okt.',
    'Msp.',
    'Mo.',
    'Mod.',
    'Mrd.',
    'M.',
    'Max.',
    'Mind.',
    'Min.',
    'Mi.',
    'Mio.',
    'U.',
    'Urspr.',
    'Ursprüngl.',
    'R.',
    'Rd.',
    'Rel.',
    'Reg.',
    'Rep.',
    'Einh.',
    'Erf.',
    'Evtl.',
    'Vgl.',
    'Verf.',
    'W.',
    'Wg.',
    'B.',
    'Bibl.',
    'Bed.',
    'Best.',
    'Ben.',
    'Ber.',
    'n.u.Z.',
    'n. Chr.',
    'näml.',
    'Hr.',
    'Hrn.',
    'Hrsg.',
    'Hbf.',
    'Hptst.',
    'Ztr.',
    'Z.',
    'd.Ä.',
    'd.J.',
    'b.',
    'beil.',
    'v.R.w.',
    'v.T.',
    'v. Chr.',
    'v. u. Z.',
    'v. H.',
    'v.u.Z.',
    'v.Chr.',
    'v.H.',
    'verh.',
    'verw.',
    'vgl.',
    'möbl.',
    'm.W.',
    'm.E.',
    'mtl.',
    'tägl.',
    'gest.',
    'gesch.',
    'gek.',
    'gedr.',
    'geb.',
    'ggf.',
    'ggfs.',
    'Nov.',
    'N.',
    'Nr.',
    'p.Adr.',
    'r.',
    'röm.',
    'jew.',
    'jhrl.',
    'Qu.',
    'hpts.',
    'Ltd.',
    'L.',
    'led.',
    'Test.',
    'Temp.',
    'Tel.',
    'Tägl.',
    'Trans.',
    'T.',
    'Y.',
    'österr.'
  ]);

  if (customSuppressions['de']) {
    supp = supp.merge(customSuppressions['de']);
  }

  supp.lock();
  return supp;
})();;suppressions['en'] = ( () => {
  let supp = Suppressions.create([
    'L.',
    'Long.',
    'Link.',
    'Lib.',
    'Lt.',
    'Lev.',
    'Alt.',
    'All.',
    'Approx.',
    'Act.',
    'Aug.',
    'Abs.',
    'A.',
    'Adj.',
    'Adv.',
    'AD.',
    'AB.',
    'AA.',
    'As.',
    'E.',
    'Ex.',
    'Exec.',
    'Est.',
    'Ed.',
    'Etc.',
    'O.',
    'Ok.',
    'Org.',
    'Or.',
    'OK.',
    'Op.',
    'On.',
    'Maj.',
    'Mart.',
    'Mar.',
    'Misc.',
    'Min.',
    'MR.',
    'M.',
    'Mrs.',
    'Mr.',
    'Md.',
    'Mt.',
    'Mgr.',
    'Ms.',
    'Mb.',
    'P.O.',
    'P.M.',
    'P.V.',
    'Prof.',
    'Pro.',
    'Ph.D.',
    'Phys.',
    'PC.',
    'Pvt.',
    'PP.',
    'J.D.',
    'J.K.',
    'J.B.',
    'Jam.',
    'Jan.',
    'Job.',
    'Joe.',
    'Jun.',
    'Card.',
    'Cap.',
    'Capt.',
    'Cont.',
    'Conn.',
    'Col.',
    'Comm.',
    'C.O.D.',
    'C.F.',
    'Dec.',
    'Def.',
    'Dept.',
    'DC.',
    'D.',
    'Do.',
    'Diff.',
    'Sept.',
    'Sep.',
    'S.',
    'Sgt.',
    'Sq.',
    'Hat.',
    'Hz.',
    'Hon.B.A.',
    'G.',
    'Gb.',
    'Go.',
    'By.',
    'B.',
    'Var.',
    'VS.',
    'N.V.',
    'N.Y.',
    'Num.',
    'Nov.',
    'Nr.',
    'Up.',
    'U.',
    'Fn.',
    'Feb.',
    'Fri.',
    'F.',
    'Z.',
    'Kb.',
    'K.',
    'I.',
    'In.',
    'Id.',
    'Is.',
    'exec.',
    'X.',
    'vs.',
    'R.L.',
    'R.T.',
    'Rev.',
    'Rep.',
    'Yr.',
    'pp.',
    'To.',
    'T.',
    'Q.',
    'a.m.'
  ]);

  if (customSuppressions['en']) {
    supp = supp.merge(customSuppressions['en']);
  }

  supp.lock();
  return supp;
})();;suppressions['es'] = ( () => {
  let supp = Suppressions.create([
    'Rdos.',
    'Rdo.',
    'RR.HH.',
    'Rvdos.',
    'Rvdo.',
    'Rvdmos.',
    'Rvdmo.',
    'RAM.',
    'Rol.',
    'R.D.',
    'R.U.',
    'Rev.',
    'Reg.',
    'JJ.OO.',
    'Sres.',
    'Srtas.',
    'Srta.',
    'Sra.',
    'Sras.',
    'Sr.',
    'SA.',
    'SS.MM.',
    'SS.AA.',
    'Sol.',
    'Seg.',
    'Sta.',
    'Sto.',
    'Sdad.',
    'fig.',
    'figs.',
    'feb.',
    'fund.',
    'may.',
    'mar.',
    'mié.',
    'mss.',
    'ms.',
    'mtro.',
    'oct.',
    'cap.',
    'col.',
    'cf.',
    'cfr.',
    'c/c.',
    'doc.',
    'dom.',
    'dic.',
    'dtor.',
    'dptos.',
    'dpto.',
    'depto.',
    'deptos.',
    'd. C.',
    'Excmo.',
    'Excmos.',
    'Excma.',
    'Excmas.',
    'Exc.',
    'Exmo.',
    'Exmos.',
    'Exma.',
    'Exmas.',
    'Emm.',
    'Em.',
    'Trab.',
    'Kit.',
    'Korn.',
    'K.',
    'Inc.',
    'Ilmas.',
    'Ilma.',
    'Ilmos.',
    'Ilmo.',
    'Id.',
    'FF.CC.',
    'Fabric.',
    'Fr.',
    'DC.',
    'Da.',
    'Dr.',
    'Drs.',
    'Dra.',
    'Desv.',
    'Desc.',
    'D.',
    'Dña.',
    'Dª.',
    'Dto.',
    'ago.',
    'abr.',
    'a. C.',
    'a. e. c.',
    'afma.',
    'afmas.',
    'afmo.',
    'afmos.',
    'trad.',
    'ed.',
    'ene.',
    'ej.',
    'e. c.',
    'e.g.',
    'jul.',
    'jue.',
    'jun.',
    'Comm.',
    'Col.',
    'Corp.',
    'C.',
    'Cía.',
    'CA.',
    'vid.',
    'vie.',
    'v.gr.',
    'vs.',
    'ss.',
    'sáb.',
    'sras.',
    'sres.',
    'srs.',
    'sept.',
    'ntra.',
    'ntro.',
    'nov.',
    'prov.',
    'prof.',
    'p.ej.',
    'licdo.',
    'licda.',
    'lun.',
    'bol.',
    'bco.',
    'Vda.',
    'Var.',
    'O.M.',
    'Ldo.',
    'Lda.',
    'Ltda.',
    'Ltd.',
    'Ltdo.',
    'Lcda.',
    'Lcdo.',
    'Lic.',
    'L.',
    'U.S.',
    'hnos.',
    'MM.',
    'Mons.',
    'M.',
    'Mr.',
    'Mrs.',
    'A.C.',
    'Av.',
    'Avda.',
    'Ant.',
    'All.',
    'AA.',
    'H.P.',
    'PP.',
    'Bien.'
  ]);

  if (customSuppressions['es']) {
    supp = supp.merge(customSuppressions['es']);
  }

  supp.lock();
  return supp;
})();;suppressions['fr'] = ( () => {
  let supp = Suppressions.create([
    'aux.',
    'adr.',
    'anc.',
    'anon.',
    'ann.',
    'acoust.',
    'avr.',
    'av. J.-C.',
    'append.',
    'ap. J.-C.',
    'config.',
    'collab.',
    'coll.',
    'cam.',
    'categ.',
    'M.',
    'MM.',
    'Mart.',
    'dim.',
    'dir.',
    'dict.',
    'doc.',
    'dest.',
    'déc.',
    'imprim.',
    'imm.',
    'indus.',
    'illustr.',
    'ill.',
    'oct.',
    'syst.',
    'symb.',
    'synth.',
    'sept.',
    'sam.',
    'bull.',
    'broch.',
    'P.',
    'Prof.',
    'hôp.',
    'juil.',
    'jeu.',
    'janv.',
    'C.',
    'Comm.',
    'voit.',
    'ven.',
    'Jr.',
    'graph.',
    'gouv.',
    'éd.',
    'édit.',
    'éval.',
    'équiv.',
    'fig.',
    'févr.',
    'niv.',
    'nov.',
    'quart.',
    'réf.',
    'DC.',
    'Desc.',
    'D.',
    'L.',
    'All.',
    'U.',
    'S.M.A.R.T.',
    'S.A.',
    'encycl.',
    'exempl.',
    'mer.',
    'mar.',
    'lun.',
    'Inc.',
    'Var.',
    'W.',
    'Op.',
    'trav. publ.'
  ]);

  if (customSuppressions['fr']) {
    supp = supp.merge(customSuppressions['fr']);
  }

  supp.lock();
  return supp;
})();;suppressions['it'] = ( () => {
  let supp = Suppressions.create([
    'N.B.',
    'N.d.A.',
    'N.d.T.',
    'N.d.E.',
    'div.',
    'd.p.R.',
    'd.C.',
    'dott.',
    'dr.',
    'a.C.',
    'arch.',
    'ag.',
    'avv.',
    'all.',
    'fig.',
    'c.c.p.',
    'Cfr.',
    'C.P.',
    'vol.',
    'ver.',
    'Geom.',
    'O.d.G.',
    'S.p.A.',
    'S.M.A.R.T.',
    'S.A.R.',
    'Sig.',
    'rag.',
    'Mod.',
    'pag.',
    'p.',
    'tav.',
    'tab.',
    'DC.',
    'D.',
    'mitt.',
    'Ing.',
    'int.',
    'on.',
    'L.',
    'Ltd.',
    'Liv.',
    'U.S.',
    'sez.'
  ]);

  if (customSuppressions['it']) {
    supp = supp.merge(customSuppressions['it']);
  }

  supp.lock();
  return supp;
})();;suppressions['pt'] = ( () => {
  let supp = Suppressions.create([
    'psicol.',
    'port.',
    'pol.',
    'p.',
    'pág.',
    'pal.',
    'profa.',
    'prof.',
    'pron.',
    'prod.',
    'próx.',
    'pq.',
    'pp.',
    'fig.',
    'fisiol.',
    'filos.',
    'fil.',
    'ff.',
    'fev.',
    'fem.',
    'fot.',
    'fís.',
    'fasc.',
    'fac.',
    'fl.',
    'fr.',
    'f.',
    'compl.',
    'comp.',
    'com.',
    'col.',
    'cont.',
    'contab.',
    'cap.',
    'caps.',
    'cat.',
    'cf.',
    'créd.',
    'cel.',
    'cx.',
    'círc.',
    'cód.',
    'rep.',
    'res.',
    'relat.',
    'rel.',
    'ref.',
    'rementente',
    'r.s.v.p.',
    'rod.',
    'doc.',
    'dipl.',
    'dir.',
    'div.',
    'dez.',
    'des.',
    'desc.',
    'déb.',
    'd.C.',
    'astron.',
    'ago.',
    'agric.',
    'arquit.',
    'arqueol.',
    'arit.',
    'apt.',
    'ap.',
    'apart.',
    'abr.',
    'abrev.',
    'adm.',
    'anat.',
    'aux.',
    'autom.',
    'aer.',
    'a.C.',
    'a.m.',
    'eletrôn.',
    'eletr.',
    'elem.',
    'ed.',
    'educ.',
    'end.',
    'eng.',
    'esp.',
    'ex.',
    'geom.',
    'gên.',
    'gram.',
    'mov.',
    'matem.',
    'mat.',
    'mai.',
    'mar.',
    'méd.',
    'mús.',
    'máq.',
    'trad.',
    'transp.',
    'tecnol.',
    'tec.',
    'tel.',
    'Exmo.',
    'Exma.',
    'E.',
    'Est.',
    'univ.',
    'R.',
    'D.C',
    'Dir.',
    'Drs.',
    'Dra.',
    'Dras.',
    'Dr.',
    'l.',
    'liter.',
    'lit.',
    'lin.',
    'lat.',
    'long.',
    'P.M.',
    'Pe.',
    'Ph.D.',
    'jur.',
    'jul.',
    'jun.',
    'jan.',
    'neol.',
    'nov.',
    'náut.',
    'índ.',
    'Ltda.',
    'Ltd.',
    'ingl.',
    'ind.',
    'Mar.',
    'símb.',
    'sup.',
    'séc.',
    'set.',
    'sociol.',
    'V.T.',
    'vs.',
    'vol.',
    'S.',
    'Sta.',
    'Sto.',
    'N.Sra.',
    'N.T.',
    'out.',
    'odontol.',
    'odont.',
    'org.',
    'organiz.',
    'obs.',
    'A.C.',
    'A.M',
    'Av.',
    'Alm.',
    'álg.',
    'hist.',
    'Fr.',
    'Ilmo.',
    'Ilma.',
    'quím.',
    'W.C.',
    'bras.',
    'biogr.',
    'Jr.'
  ]);

  if (customSuppressions['pt']) {
    supp = supp.merge(customSuppressions['pt']);
  }

  supp.lock();
  return supp;
})();;suppressions['ru'] = ( () => {
  let supp = Suppressions.create([
    'руб.',
    'янв.',
    'до н. э.',
    'дек.',
    'сент.',
    'тел.',
    'тыс.',
    'февр.',
    'нояб.',
    'н. э.',
    'н.э.',
    'апр.',
    'авг.',
    'окт.',
    'отд.',
    'проф.',
    'кв.',
    'ул.'
  ]);

  if (customSuppressions['ru']) {
    supp = supp.merge(customSuppressions['ru']);
  }

  supp.lock();
  return supp;
})();;suppressions['tr'] = ( () => {
  let supp = Suppressions.create([
    'age.',
    'agm.',
    'agy.',
    'Alb.',
    'Alm.',
    'anat.',
    'ant.',
    'Apt.',
    'Ar.',
    'ark.',
    'Arş.',
    'As.',
    'Asb.',
    'astr.',
    'astrol.',
    'Atğm.',
    'atm.',
    'Av.',
    'b.',
    'bağ.',
    'Bçvş.',
    'bit.',
    'biy.',
    'bk.',
    'bl.',
    'Bl.',
    'Bnb.',
    'bot.',
    'Böl.',
    'bs.',
    'Bşk.',
    'Bul.',
    'Bulg.',
    'çev.',
    'Çvş.',
    'db.',
    'dk.',
    'Doç.',
    'doğ.',
    'Dr.',
    'drl.',
    'Dz.',
    'dzl.',
    'Ecz.',
    'ed.',
    'ekon.',
    'Ens.',
    'f.',
    'Fak.',
    'fel.',
    'fiz.',
    'fizy.',
    'Gen.',
    'geom.',
    'gn.',
    'Gnkur.',
    'Gön.',
    'gr.',
    'hay.',
    'haz.',
    'hek.',
    'hlk.',
    'Hs.',
    'huk.',
    'Hv.',
    'Hz.',
    'İng.',
    'is.',
    'İsp.',
    'işl.',
    'İt.',
    'İz.',
    'Jap.',
    'jeol.',
    'K.',
    'kim.',
    'koor.',
    'Kor.',
    'Kora.',
    'Korg.',
    'kr.',
    'krş.',
    'Kuv.',
    'Lat.',
    'Mac.',
    'Mah.',
    'man.',
    'mat.',
    'mec.',
    'Mim.',
    'mim.',
    'min.',
    'Müh.',
    'Mür.',
    'müz.',
    'No.',
    'Nö.',
    'Okt.',
    'Onb.',
    'Or.',
    'Ora.',
    'Ord.',
    'Org.',
    'Ort.',
    'Osm.',
    'öl.',
    'ör.',
    'öz.',
    'ped.',
    'Port.',
    'Prof.',
    'psikol.',
    'Rum.',
    'Rus.',
    's.',
    'sa.',
    'Sb.',
    'sf.',
    'Sl.',
    'Sn.',
    'sn.',
    'snt.',
    'Sok.',
    'sos.',
    'sp.',
    'Srp.',
    'Şb.',
    'T.',
    'T.C.',
    'tar.',
    'Tb.',
    'tek.',
    'tel.',
    'telg.',
    'Tğm.',
    'tiy.',
    'tlks.',
    'tls.',
    'Top.',
    'Tug.',
    'Tuğa.',
    'Tuğg.',
    'Tüm.',
    'Tüma.',
    'Tümg.',
    'Uzm.',
    'Üçvş.',
    'ünl.',
    'Ütğm.',
    'vb.',
    'vd.',
    'Vet.',
    'vs.',
    'Y.',
    'Yay.',
    'Yb.',
    'Yd.',
    'Yrd.',
    'Yun.',
    'yy.',
    'Yzb.',
    'zf.',
    'zm.',
    'zool.'
  ]);

  if (customSuppressions['tr']) {
    supp = supp.merge(customSuppressions['tr']);
  }

  supp.lock();
  return supp;
})();
;suppressions['all'] = Suppressions.create(
  ( () => {
    var list = [];

    for (let locale in suppressions) {
      list = list.concat(suppressions[locale].list);
    }

    return list;
  })()
)
